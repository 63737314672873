#ui-datepicker-div {
    z-index: 999!important;
}

.ui-datepicker {
    width: 280px;
    height: auto;
    padding: 0px 8px;
    background-color: #fff;
    margin-top: -24px;
    border: 2px solid #000;
    z-index: 10 !important;
    display: none;
}

.ui-datepicker-header {
    height: 38px;
    background-color: #fff;
    font-family: 'AtlasG Black';
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
    position: absolute;
    top: 6px;
    width: 20px;
    height: 24px;
    text-align: center;
    cursor:pointer;
    &:before{
        font: normal normal normal 14px/1 FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
    }
}

.ui-datepicker .ui-datepicker-prev {
    left: 8px;
    &:before{content: "\f053"}
}

.ui-datepicker .ui-datepicker-next {
    right: 8px;
    &:before{content: "\f054"}
}

.ui-datepicker-title {
    color: #000000;
    padding-top: 6px;
    text-align: center
}

.ui-datepicker-title .ui-datepicker-month {}

.ui-datepicker-title .ui-datepicker-year {}

.ui-datepicker-calendar {
    width: 100%;
    height: 160px;
    text-align: center;
    margin-bottom: 6px;
}

.ui-datepicker-calendar thead {
    color: #000000;
    font-size: 16px;
    font-family: 'AtlasG Regular', sans-serif;
    font-weight:bold;
}

.ui-datepicker-calendar thead th {
    text-align: center
}

.ui-datepicker-calendar tbody a {
    color: #000000;
    font-size: 16px;
    font-family: 'AtlasG Regular', sans-serif;
}

.ui-state-default:hover{
    color: $colorActive;
}

.ui-datepicker-calendar .ui-state-active {
    color: $colorActive;
    font-weight:bold;
}

.ui-timepicker-div{
    padding:6px 0px;
    border-top:2px solid #000;
    border-bottom:2px solid #000;
    dt{
        font-weight:bold;
    }
}

.ui_tpicker_time_input{
    border:none !important;
}

.ui-timepicker-select{
    width:100%;
}

.ui-datepicker-buttonpane{
    .ui-datepicker-current{
        float:left;
        font-size: 20px;
        padding:6px;
    }
    .ui-datepicker-close{
        float:right;
        font-size: 20px;
        padding:6px;
    }
}

.ui-icon {
    display: block;
    text-indent: -99999px;
    overflow: hidden;
    background-repeat: no-repeat;
}