
/*/////////////////////////////////////////////////////////////////////////////////////////// SLIDER new*/
.flickity-enabled{position:relative; height:100%; overflow:hidden;
    .flickity-slider{position:absolute; width:100%; height:100%;}
    .slider-cell{width:100%; height:100%;}
    .flickity-prev-next-button{position:absolute; bottom:10px; margin:0px 10px; color:#fff; font-size:40px; line-height:40px; cursor:pointer;
        &.previous{right:40px;
            &:before{content:'\f104'; font-family:'FontAwesome';}
        }
        &.next{right:10px;
            &:before{content:'\f105'; font-family:'FontAwesome';}
        }
        &:hover{
            &:before{color:$colorActive;}
        }
        svg{display:none;}
    }
    .flickity-conter{position:absolute; bottom:10px; right:90px; font-family:'AtlasT Regular'; font-size:30px;
        span{display:inline-block !important; margin:0px !important; font-size:20px; vertical-align:top;}
        span:last-child{vertical-align:bottom;}
    }
    
}

#slider-detail{float:left; position:relative; width:100%; height:646px; margin-bottom:10px; padding-bottom:65px; box-sizing:content-box;
    .slider{padding-bottom:65px; box-sizing:content-box;
        .slider-cell{height:calc(100% - 65px);}
    }
    .descri{position:absolute; display:table; width:100%; height:65px; bottom:-65px; z-index:1;
        &:before{content:''; position:absolute; width:100%; height:100%; z-index:1;}
        .center{position:relative; display:table-cell; vertical-align:middle; z-index:2;}
        p{padding:10px 180px 10px 20px; margin:0px; color:$colorDefault; font-size:11px; line-height:15px; text-align:left;}
    }
    .flickity-prev-next-button{color:$colorDefault;}
}

#slider-work, #slider-studies{float:left; position:relative; width:100%; height:646px; margin-bottom:10px; padding-bottom:65px; box-sizing:content-box;
    .slider{padding-bottom:65px; box-sizing:content-box;
        .slider-cell{height:calc(100% - 65px); background:$bgColor; width:33.33%;}
        .descri{position:absolute; top:20px; left:10px; width:90%; text-align:left; z-index:2;
            .tipodata{float:left; width:100%; font-family:'AtlasT Regular'; font-size:14px; margin-bottom:6px;}
            .titulo{float:left; width:100%; font-family:'AtlasG Medium'; font-size:26px; line-height:28px;}
        }
    }
    .flickity-prev-next-button{color:$colorDefault;}
}

#slider-work{height:450px;}

@media (max-width:$screen-md-max) {
    #slider-detail{height:444px;}
    #slider-studies{height:534px;}
}

@media (max-width:$screen-sm-max) {
    #slider-detail{height:399px;}
    #slider-studies, #slider-work {height:405px;
        .slider{
            .slider-cell{width:100%;}
        }
    }
}

@media (max-width:$screen-xs-max) {
    #slider-detail, #slider-work, #slider-studies{
        .descri{
            p{padding:10px 80px 10px 10px;}
        }
    }
    #slider-detail{height:54.7vw;}
    #slider-studies, #slider-work{height:400px;}
}
