
@font-face {
 font-family:'icon';
 src: url('../../fonts/fontello.eot?21845912');
 src: url('../../fonts/fontello.eot?21845912#iefix') format('embedded-opentype'),
       url('../../fonts/fontello.woff?21845912') format('woff'),
       url('../../fonts/fontello.ttf?21845912') format('truetype'),
       url('../../fonts/fontello.svg?21845912#fontello') format('svg');
 font-weight: normal;
 font-style: normal;
}

.icon {
 &.btn{
   &:before{display:inline-block; vertical-align:sub; line-height:0px; font-size:24px;}
 }

 &:before {
   font-family:'icon';
   speak: none;
   margin: 0px 5px;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   font-style: normal;
   font-weight: normal;
 }

    &.attention:before { content: '\e800'; } /* '' */
    &.staff:before { content: '\e801'; } /* '' */
    &.structure:before { content: '\e802'; } /* '' */
    &.info:before { content: '\e803'; } /* '' */
    &.params:before { content: '\e804'; } /* '' */
    &.pencil:before { content: '\e805'; } /* '' */

}
