@import "variables";

@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/variables";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/component-animations";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/modals";

.eminem-modal {

    .modal-content {
        border-radius: 0px;

        .modal-header {
            padding-bottom: 5px;
            border-bottom: none;
            color: $colorDefault;
            text-transform: lowercase;

            .close {
                float: right;
                font-family: 'FontAwesome';
                text-rendering: auto;
                -webkit-font-smoothing: antialiased;
                font-size: 36px;
                vertical-align: middle;

                span {
                    display: none;
                }

                &:before {
                    content: "\f057";
                }
            }

            .modal-title {
                font-family: 'AtlasG Medium';
                font-size: 16px;
                margin: 6px 0px;
            }
        }

        .modal-body {
            padding-top: 0;

            .nav-pills {
                display: block;
                margin: 0px 0px 20px;
                font-size: 36px;
                text-align: center;

                li {
                    display: inline-block;
                    margin: 0px 30px;

                    &.active {
                        a {
                            color: $colorActive;
                            font-weight: 700;
                        }
                    }
                }
            }

            .tab-content {
                .tab-pane {
                    display: none;

                    &.active {
                        display: block;
                    }

                    .drag-and-drop {
                        position: relative;
                        color: $colorDefault;
                        text-align: center;

                        .drag-text {
                            position: relative;
                            width: 100%;
                            height: 180px;
                            padding: 20px 0px;
                            background-color: $bgColor;
                            border: 2px solid #000000;
                            z-index: 2;
                        }

                        .drop-text {
                            position: absolute;
                            top: 0;
                            width: 100%;
                            height: 180px;
                            border: 2px dashed $colorActive;
                            background-color: #fff;
                            z-index: 1;

                            .drag-title {
                                font-size: 18px;
                                line-height: 180px;
                                color: $colorActive;
                            }
                        }

                        .drag-title {
                            color: #000000;
                            font-family: 'AtlasG Regular', sans-serif;
                            font-size: 100%;
                        }

                        .separator {
                            display: block;
                            position: relative;
                            width: 80px;
                            height: 24px;
                            margin: 20px auto 20px;
                            font-family: 'AtlasG Regular';
                            font-size: 16px;
                            font-weight: bold;
                            text-align: center;
                        }

                        @media (max-width:$screen-xs-min - 1) {
                            .btn-default{font-size:24px;}
                        }

                        &.dragover {
                            .drag-text {
                                z-index: 0;
                            }
                        }
                    }

                    .media-item {
                        display: inline-table;
                        width: 33%;
                        padding: 5px;

                        img {
                            width: 100%;
                        }

                        .btn-danger {
                            float: left;
                            width: 100%;
                            padding: 14px 30px;
                            margin-right: 10px;
                            margin-bottom: 10px;
                            border: 2px solid #000000;
                            font-size: 14px;
                            line-height: 12px;
                            transition: 250ms ease-in-out;

                            &:after {
                                content: "\f00d";
                                display: inline-block;
                                margin-left: 5px;
                                font-family: FontAwesome;
                            }
                        }
                    }
                }
            }
        }
    }

    .current-media {

        .actions {

            .details-heading,
            .details-value {
                display: none;
            }

            .details-value-filename {
                display: block;
                text-decoration: none;
                margin-bottom: 8px;
                font-size: 14px;
                word-break: break-word;
            }
        }
    }
}
