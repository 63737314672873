@import "bower_components/gray/css/gray";

// Control grayscale fading from parent elements using .grayscale-fade in parent
// and not on the image itself

.grayscale {
  -webkit-filter: grayscale(1);
  filter:         grayscale(1);
}

.grayscale-fade:hover .grayscale {
  -webkit-filter: grayscale(0);
  filter:         grayscale(0);
}

.grayscale-fade:hover .grayscale > svg {
  opacity: 0;
}

#billhead .grayscale-fade{color:#fff;}
.slider a{color:#fff;}

// IE 9
@media screen and (min-width:0) and (min-resolution: .001dpcm) {
    .grayscale {
        filter: gray;
    }
}
