.page {
    float: right;
    position: relative;
    height: 40px;

    a,
    span {
        float: left;
        margin: 0px 10px;
        color: $colorDefault;
        font-size: 40px;
        line-height: 40px;

        &.prev {

            &:before {
                content: '\f104';
                font-family: 'FontAwesome';
            }
        }

        &.next {

            &:before {
                content: '\f105';
                font-family: 'FontAwesome';
            }
        }
    }

    a {
        cursor: pointer;

        &:hover {

            &:before {
                color: $colorActive;
            }
        }
    }

    span {
        @include opacity(0.25);
    }

    .page-count {
        position: absolute;
        left: -80px;
        top: 10px;
        width: 80px;
        font-family: 'AtlasT Regular';
        font-size: 20px;
        color: $colorDefault;
        text-align: right;

        span {
            vertical-align: top;
        }

        span:last-child {
            vertical-align: bottom;
        }
    }
}
