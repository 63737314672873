.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  position: relative;
  width: 600px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border: 2px solid #000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;

  .header {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .content {
    width: 100%;
    max-height: 300px;
    overflow: scroll;
  }

  .footer {
      position: relative;
      height: 60px;
      width: 100%;
      display: table;
      .dismiss-button {
          display: table-cell;
          vertical-align: middle;
         /* position: absolute;
          margin: auto;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;*/
          button {
            font-size: 16px;
          }
      }
  }
}


.modal-default-button {
  float: right;
}

/*
 * the following styles are auto-applied to elements with
 * v-transition="modal" when their visiblity is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter, .modal-leave {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}