#services {
    .section-title {
        font-size: 30px;
        margin-bottom: 30px;
    }
    .services {
        .multi {
            .tab {
                .tab-title {
                    text-align: left;
                    .total {
                        float: right;
                    }
                }
                .wrapper {
                    .select {
                        height: 45px;
                        select {
                            height: initial;
                        }
                    }
                    .form-group.select:after {
                        top: 7px;
                        padding-top: 0;
                    }
                    .input {
                        height: 45px;
                        padding: 0;
                        input {
                            height: 100%;
                            margin-left: 20px;
                        }
                    }
                    .remove-service {
                        height: 45px;
                        position: relative;
                        .remove {
                            cursor: pointer;
                            position: absolute;
                            top: 0;
                            bottom: 0;
                            margin: auto;
                            display: block;
                            right: 20px;
                            font-size: 26px;
                        }
                    }
                    .add-new{
                        margin-top: 30px;
                        a {
                            color: #000;
                        }
                        a:hover {
                            color: $colorActive;
                        }
                        a:after {
                            content: '';
                        }
                    }
                    .other-values {
                        height: 45px;
                        padding: 0;
                        input {
                            height: 45px;
                            margin-left: 20px;
                        }
                    }
                }
            }
        }
        .budget-total {
            float: right;
            padding: 10px 50px;
            font-size: 19px;
        }
    }

}

.padder {
    margin-top: 30px;
}