.filter {
    margin: 15px 0 25px;
    overflow: hidden;
    display: none;

    .form-group {

        .checkbox {
            border: 2px solid;
        }
    }

    button {
        float: right;
        margin-top: 15px;
    }
}

.form-group {

    .indicator {
        position: absolute;
        top: 27px;
        right: 37px;
        z-index: 3;
        width: 40px;
        height: 40px;
        font-size: 30px;
        line-height: 34px;
        text-align: center;

        &:hover {
            color: $colorActive;
            cursor: pointer;
        }
    }
}

.showcase-search {

    #billhead {
        position: relative;
        height: 540px;

        .container {
            display: table;
            position: relative;
            height: 100%;
            text-align: center;
            z-index: 2;

            .center {
                display: table-cell;
                vertical-align: middle;

                h2 {
                    font-size:48px; font-weight: bold; color:#fff;
                }

                h3 {
                    font-family: 'AtlasG Regular';
                    font-size: 24px;
                    color:#fff;
                }
            }
        }
    }

    #pesquisa {

        .search-type-toggles {
            padding-top: 30px;
            font-size: 20px;

            label {
                margin-right: 30px;
            }

            input {
                position: relative;
                top: -2px;
                margin-right: 3px;
            }
        }

        p {
            font-size: 20px;
            margin: 20px 0 30px;
        }

        .vertudo {
            float: left;
            width: 100%;
            margin: 80px auto 40px;
            font-size: 36px;
            text-align: center;
        }
    }

    .filter {
        display: block;
        margin-top: 0;
    }

    @media (max-width:$screen-sm-max) {

        #pesquisa {

            p {
                margin: 20px 0px 40px;
            }

            .vertudo {
                margin: 20px auto 40px;
            }
        }
    }
}

.showcase,
.showcase-projects {

    .container {
        margin: 50px auto 70px;

        h2 {
            float: left;
            width: 100%;
            font-size: 30px;

            span {
                font-family: 'AtlasG Medium';
            }
        }

        .showcase-list-actions {

            a {
                float: left;
                font-size: 16px;

                &:before {
                    content: '|';
                    margin: 0 10px;
                }

                &:first-child {
                    &:before {
                        content: '';
                        margin: 0;
                    }
                }

                &.active {
                    font-weight: bold;
                    color: $colorActive;
                }
            }
        }

        .list-style {
            float: left;
            width: 100%;
            margin: 20px 0px;

            .type {
                float: left;

                label {
                    float: left;
                    padding: 5px 20px 5px 0px;
                    font-family: 'AtlasG Medium';
                    font-size: 16px;
                }

                .form-group {

                    label {
                        padding: 0px;
                        font-family: 'AtlasG Regular';
                    }
                }
            }

            .display {
                float: right;

                icon {
                    margin: 0px 6px;
                    color: #b9b9b9;
                    font-size: 24px;
                    cursor: pointer;

                    &.active {
                        color: $colorActive;
                    }
                }
            }
        }
    }

    .thumbnail{
        margin: 0 -15px;

        .thumb {
            position: relative;
            cursor: pointer;
            padding: 0px 15px 40px;

            a {
                display: block;
            }
        }

        .localnum {
            float: left;
            width: 100%;
            font-family: 'AtlasT Regular';
            font-size: 12px;
        }

        .nome {
            float: left;
            font-family: 'AtlasG Medium';
            font-size: 19px;
        }

        .noimag {
            display: none;
        }

    }

    .info-box{display:none;}
    .page{float:right; position:relative; height:40px;
        a, span{float:left; margin:0px 10px; color:$colorDefault; font-size:40px; line-height:40px;
            &.prev{
                &:before{content:'\f104'; font-family:'FontAwesome';}
            }
            &.next{
                &:before{content:'\f105'; font-family:'FontAwesome';}
            }
        }
        a {cursor:pointer;
            &:hover{
                &:before{color:$colorActive;}
            }
        }
        span {
            @include opacity(0.25);
        }
        .conter{position:absolute; left:-80px; top:10px; width:80px; font-family:'AtlasT Regular'; font-size:20px; color:$colorDefault; text-align:right;
            span{vertical-align:top;}
            span:last-child{vertical-align:bottom;}
        }
    }
    @media (max-width:$screen-sm-max) {
        .container{
            h2{font-size:24px;}
        }
        .popover{display:none !important;}
    }
    @media (max-width:$screen-xs-max) {
        .container{
            .filter{
                .type{
                    label{display:block; float:none;}
                }
            }
        }
    }
    @media (max-width:$screen-xs-min - 1) {
        .container{
            .filter{
                .display{float:left; margin-top:10px;}
            }
        }
        .thumbnail{
            .thumb{width:50%; padding:0px 10px 40px;}
        }
    }
}

.showcase-detail,
.showcase-project-detail {

    #billhead {
        background: $bgColor;
        text-align: center;

        .imag {
            display: block;
            width: 190px;
            height: 190px;
            border-radius: 100%;
            overflow: hidden;
            margin: 0 auto 15px;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
        }

        .local {
            display: block;
            font-size: 24px;
            margin-top: 10px;
        }

        .nome {
            display: block;
            font-size: 40px;
            font-weight: bold;
        }

        p {
            display: block;
            width: 800px;
            padding-top: 30px;
            font-size: 20px;
            font-family: 'AtlasG Medium';
            margin: auto;
        }

        .info {
            height: auto;
            padding: 5px 0px;
            margin: 50px 0px;

            .imag {
                float: left;
                width: 80px;
                height: 80px;
                margin: 0 10px 0px 0px;
                border-radius: 100%;
                overflow: hidden;
                @extend .backgrd;
            }

            .local {
                font-size: 16px;
                font-weight: bold;
                margin: 8px 0px -8px;
            }

            .nome {
                width: auto;
                font-size: 28px;
                font-weight: bold;
            }

            .middle {
                display: table;
                margin: auto;
                text-align: left;
            }

            icon {
                display: table-cell;
                vertical-align: middle;
                width: 50px;
                text-align: center;
                font-size: 36px;
            }

            span {
                display: block;
                font-size: 12px;
                white-space: nowrap;

                &:nth-child(even) {
                    font-family: 'AtlasG Medium';
                }
            }
        }
    }

    #info{
        .container{position:relative; margin:50px auto 70px; padding:0px 0px 0px 0px;}
        h2{margin-bottom:20px; font-size:30px; font-family:'AtlasG Medium';}
        h4{font-family:'AtlasG Medium';}
        span, p{display:block; margin-bottom:20px;}
        @media (max-width:$screen-md-max) {
            .container{padding:0px 80px 50px;}
        }
    }
    /*#work{height:450px;
        #slider-work{position:relative; width:100%; height:100%;
            .slider-cell{height:calc(100% - 65px); background:$bgColor;  width:33.33%;
                img{@include transition($animatDefult);}
                .descri{position:absolute; top:20px; left:10px; width:90%; text-align:left; z-index:2;
                    .tipodata{float:left; width:100%; font-family:'AtlasT Regular'; font-size:14px; margin-bottom:6px;}
                    .titulo{float:left; width:100%; font-family:'AtlasG Medium'; font-size:26px; line-height:28px;}
                }
            }
        }
    }*/
    .nav{position:absolute; top:0; right:8px;
        a{display:block; margin-bottom:60px; font-size:36px;}
    }
    @media (max-width:$screen-sm-max) {
        #billhead{
            .container{
                p{width:auto;}
                .allinfo{display:none;}
            }
        }
        #info{
            .container{padding:0px 10px;}
        }
    }
}

.popover {
    position: absolute;
    padding: 20px;
    background: $bgColor;
    border: 2px solid $colorDefault;
    z-index: 100;

    .popover-content {
        float: left;
        position: relative;
        width: 440px;
    }

    .close {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 24px;
        cursor: pointer;
        z-index: 100;
        @include transition($animatDefult);

        &:hover {
            color: $colorActive;
        }
    }

    .link {
        float: left;
        width: 100%;
        margin-top: 10px;
        font-size: 16px;
        text-align: right;
    }

    .info {
        height: auto;
        padding: 5px 0px;
        margin-bottom: 15px;

        .imag {
            float: left;
            width: 80px;
            height: 80px;
            margin: 0 10px 0px 0px;
            border-radius: 100%;
            overflow: hidden;
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: cover;
        }

        .local {
            font-size: 16px;
            font-weight: bold;
            margin: 8px 0px -2px;
        }

        .nome {
            width: auto;
            font-size: 28px;
            font-weight: bold;
        }

        icon {
            float: left;
            width: 50px;
            text-align: center;
            font-size: 36px;
        }

        span {
            display: block;
            font-size: 12px;

            &:nth-child(even) {
                font-family: 'AtlasG Medium';
            }
        }
    }
    &.right {

        &:after,
        &:before {
            content: '';
            position: absolute;
            left: -20px;
            top: 85px;
            height: 0;
            width: 0;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
        }

        &:before {
            border-right: 22px solid $colorDefault;
            border-top: 22px solid transparent;
            border-bottom: 22px solid transparent;
            margin: -2px 0px 0px -2px;
            z-index: 100;
        }

        &:after {
            border-right: 20px solid $bgColor;
            z-index: 200;
        }
    }

    &.left {

        &:after,
        &:before {
            content: '';
            position: absolute;
            right: -20px;
            top: 85px;
            height: 0;
            width: 0;
            border-top: 20px solid transparent;
            border-bottom: 20px solid transparent;
        }

        &:before {
            border-left: 22px solid $colorDefault;
            border-top: 22px solid transparent;
            border-bottom: 22px solid transparent;
            margin: -2px -2px 0px 2px;
            z-index: 100;
        }

        &:after {
            border-left: 20px solid $bgColor;
            z-index: 200;
        }
    }
}
