@charset "UTF-8";
.clearfix:before, .clearfix:after {
  display: table;
  content: " ";
}

.clearfix:after {
  clear: both;
}

.center-block {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.invisible {
  visibility: hidden;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.hidden {
  display: none !important;
}

.affix {
  position: fixed;
}

/*/////////////////////////////////////////////////////////////////////////////////////////// FONTS */
@font-face {
  font-family: 'AtlasG Black';
  src: url("../../fonts/atlasgrotblack.eot");
  src: url("../../fonts/atlasgrotblack.eot?#iefix") format("embedded-opentype"), url("../../fonts/atlasgrotblack.woff") format("woff"), url("../../fonts/atlasgrotblack.ttf") format("truetype"), url("../../fonts/atlasgrotblack.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AtlasG Medium';
  src: url("../../fonts/atlasgrotmedium.eot");
  src: url("../../fonts/atlasgrotmedium.eot?#iefix") format("embedded-opentype"), url("../../fonts/atlasgrotmedium.woff") format("woff"), url("../../fonts/atlasgrotmedium.ttf") format("truetype"), url("../../fonts/atlasgrotmedium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AtlasG Regular';
  src: url("../../fonts/atlasgrotregular.eot");
  src: url("../../fonts/atlasgrotregular.eot?#iefix") format("embedded-opentype"), url("../../fonts/atlasgrotregular.woff") format("woff"), url("../../fonts/atlasgrotregular.ttf") format("truetype"), url("../../fonts/atlasgrotregular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AtlasT Medium';
  src: url("../../fonts/atlastypemedium.eot");
  src: url("../../fonts/atlastypemedium.eot?#iefix") format("embedded-opentype"), url("../../fonts/atlastypemedium.woff") format("woff"), url("../../fonts/atlastypemedium.ttf") format("truetype"), url("../../fonts/atlastypemedium.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AtlasT Regular';
  src: url("../../fonts/atlastyperegular.eot");
  src: url("../../fonts/atlastyperegular.eot?#iefix") format("embedded-opentype"), url("../../fonts/atlastyperegular.woff") format("woff"), url("../../fonts/atlastyperegular.ttf") format("truetype"), url("../../fonts/atlastyperegular.svg") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'icon';
  src: url("../../fonts/fontello.eot?21845912");
  src: url("../../fonts/fontello.eot?21845912#iefix") format("embedded-opentype"), url("../../fonts/fontello.woff?21845912") format("woff"), url("../../fonts/fontello.ttf?21845912") format("truetype"), url("../../fonts/fontello.svg?21845912#fontello") format("svg");
  font-weight: normal;
  font-style: normal;
}

.icon {
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
  /* '' */
}

.icon.btn:before {
  display: inline-block;
  vertical-align: sub;
  line-height: 0px;
  font-size: 24px;
}

.icon:before {
  font-family: 'icon';
  speak: none;
  margin: 0px 5px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-weight: normal;
}

.icon.attention:before {
  content: '\e800';
}

.icon.staff:before {
  content: '\e801';
}

.icon.structure:before {
  content: '\e802';
}

.icon.info:before {
  content: '\e803';
}

.icon.params:before {
  content: '\e804';
}

.icon.pencil:before {
  content: '\e805';
}

.container {
  padding-right: 5px;
  padding-left: 5px;
  margin-right: auto;
  margin-left: auto;
}

.container:before, .container:after {
  display: table;
  content: " ";
}

.container:after {
  clear: both;
}

@media (min-width: 768px) {
  .container {
    width: 730px;
  }
}

@media (min-width: 992px) {
  .container {
    width: 950px;
  }
}

@media (min-width: 1200px) {
  .container {
    width: 1150px;
  }
}

.container-fluid {
  padding-right: 5px;
  padding-left: 5px;
  margin-right: auto;
  margin-left: auto;
}

.container-fluid:before, .container-fluid:after {
  display: table;
  content: " ";
}

.container-fluid:after {
  clear: both;
}

.row {
  margin-right: -5px;
  margin-left: -5px;
}

.row:before, .row:after {
  display: table;
  content: " ";
}

.row:after {
  clear: both;
}

.row-no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.row-no-gutters [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-xs-1, .col-sm-1, .col-md-1, .col-lg-1, .col-xs-2, .col-sm-2, .col-md-2, .col-lg-2, .col-xs-3, .col-sm-3, .col-md-3, .col-lg-3, .col-xs-4, .col-sm-4, .col-md-4, .col-lg-4, .col-xs-5, .col-sm-5, .col-md-5, .col-lg-5, .col-xs-6, .col-sm-6, .col-md-6, .col-lg-6, .col-xs-7, .col-sm-7, .col-md-7, .col-lg-7, .col-xs-8, .col-sm-8, .col-md-8, .col-lg-8, .col-xs-9, .col-sm-9, .col-md-9, .col-lg-9, .col-xs-10, .col-sm-10, .col-md-10, .col-lg-10, .col-xs-11, .col-sm-11, .col-md-11, .col-lg-11, .col-xs-12, .col-sm-12, .col-md-12, .col-lg-12, .col-xs-13, .col-sm-13, .col-md-13, .col-lg-13, .col-xs-14, .col-sm-14, .col-md-14, .col-lg-14, .col-xs-15, .col-sm-15, .col-md-15, .col-lg-15, .col-xs-16, .col-sm-16, .col-md-16, .col-lg-16, .col-xs-17, .col-sm-17, .col-md-17, .col-lg-17, .col-xs-18, .col-sm-18, .col-md-18, .col-lg-18, .col-xs-19, .col-sm-19, .col-md-19, .col-lg-19, .col-xs-20, .col-sm-20, .col-md-20, .col-lg-20, .col-xs-21, .col-sm-21, .col-md-21, .col-lg-21, .col-xs-22, .col-sm-22, .col-md-22, .col-lg-22, .col-xs-23, .col-sm-23, .col-md-23, .col-lg-23, .col-xs-24, .col-sm-24, .col-md-24, .col-lg-24 {
  position: relative;
  min-height: 1px;
  padding-right: 5px;
  padding-left: 5px;
}

.col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-13, .col-xs-14, .col-xs-15, .col-xs-16, .col-xs-17, .col-xs-18, .col-xs-19, .col-xs-20, .col-xs-21, .col-xs-22, .col-xs-23, .col-xs-24 {
  float: left;
}

.col-xs-1 {
  width: 4.1666666667%;
}

.col-xs-2 {
  width: 8.3333333333%;
}

.col-xs-3 {
  width: 12.5%;
}

.col-xs-4 {
  width: 16.6666666667%;
}

.col-xs-5 {
  width: 20.8333333333%;
}

.col-xs-6 {
  width: 25%;
}

.col-xs-7 {
  width: 29.1666666667%;
}

.col-xs-8 {
  width: 33.3333333333%;
}

.col-xs-9 {
  width: 37.5%;
}

.col-xs-10 {
  width: 41.6666666667%;
}

.col-xs-11 {
  width: 45.8333333333%;
}

.col-xs-12 {
  width: 50%;
}

.col-xs-13 {
  width: 54.1666666667%;
}

.col-xs-14 {
  width: 58.3333333333%;
}

.col-xs-15 {
  width: 62.5%;
}

.col-xs-16 {
  width: 66.6666666667%;
}

.col-xs-17 {
  width: 70.8333333333%;
}

.col-xs-18 {
  width: 75%;
}

.col-xs-19 {
  width: 79.1666666667%;
}

.col-xs-20 {
  width: 83.3333333333%;
}

.col-xs-21 {
  width: 87.5%;
}

.col-xs-22 {
  width: 91.6666666667%;
}

.col-xs-23 {
  width: 95.8333333333%;
}

.col-xs-24 {
  width: 100%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-pull-1 {
  right: 4.1666666667%;
}

.col-xs-pull-2 {
  right: 8.3333333333%;
}

.col-xs-pull-3 {
  right: 12.5%;
}

.col-xs-pull-4 {
  right: 16.6666666667%;
}

.col-xs-pull-5 {
  right: 20.8333333333%;
}

.col-xs-pull-6 {
  right: 25%;
}

.col-xs-pull-7 {
  right: 29.1666666667%;
}

.col-xs-pull-8 {
  right: 33.3333333333%;
}

.col-xs-pull-9 {
  right: 37.5%;
}

.col-xs-pull-10 {
  right: 41.6666666667%;
}

.col-xs-pull-11 {
  right: 45.8333333333%;
}

.col-xs-pull-12 {
  right: 50%;
}

.col-xs-pull-13 {
  right: 54.1666666667%;
}

.col-xs-pull-14 {
  right: 58.3333333333%;
}

.col-xs-pull-15 {
  right: 62.5%;
}

.col-xs-pull-16 {
  right: 66.6666666667%;
}

.col-xs-pull-17 {
  right: 70.8333333333%;
}

.col-xs-pull-18 {
  right: 75%;
}

.col-xs-pull-19 {
  right: 79.1666666667%;
}

.col-xs-pull-20 {
  right: 83.3333333333%;
}

.col-xs-pull-21 {
  right: 87.5%;
}

.col-xs-pull-22 {
  right: 91.6666666667%;
}

.col-xs-pull-23 {
  right: 95.8333333333%;
}

.col-xs-pull-24 {
  right: 100%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-push-1 {
  left: 4.1666666667%;
}

.col-xs-push-2 {
  left: 8.3333333333%;
}

.col-xs-push-3 {
  left: 12.5%;
}

.col-xs-push-4 {
  left: 16.6666666667%;
}

.col-xs-push-5 {
  left: 20.8333333333%;
}

.col-xs-push-6 {
  left: 25%;
}

.col-xs-push-7 {
  left: 29.1666666667%;
}

.col-xs-push-8 {
  left: 33.3333333333%;
}

.col-xs-push-9 {
  left: 37.5%;
}

.col-xs-push-10 {
  left: 41.6666666667%;
}

.col-xs-push-11 {
  left: 45.8333333333%;
}

.col-xs-push-12 {
  left: 50%;
}

.col-xs-push-13 {
  left: 54.1666666667%;
}

.col-xs-push-14 {
  left: 58.3333333333%;
}

.col-xs-push-15 {
  left: 62.5%;
}

.col-xs-push-16 {
  left: 66.6666666667%;
}

.col-xs-push-17 {
  left: 70.8333333333%;
}

.col-xs-push-18 {
  left: 75%;
}

.col-xs-push-19 {
  left: 79.1666666667%;
}

.col-xs-push-20 {
  left: 83.3333333333%;
}

.col-xs-push-21 {
  left: 87.5%;
}

.col-xs-push-22 {
  left: 91.6666666667%;
}

.col-xs-push-23 {
  left: 95.8333333333%;
}

.col-xs-push-24 {
  left: 100%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

.col-xs-offset-1 {
  margin-left: 4.1666666667%;
}

.col-xs-offset-2 {
  margin-left: 8.3333333333%;
}

.col-xs-offset-3 {
  margin-left: 12.5%;
}

.col-xs-offset-4 {
  margin-left: 16.6666666667%;
}

.col-xs-offset-5 {
  margin-left: 20.8333333333%;
}

.col-xs-offset-6 {
  margin-left: 25%;
}

.col-xs-offset-7 {
  margin-left: 29.1666666667%;
}

.col-xs-offset-8 {
  margin-left: 33.3333333333%;
}

.col-xs-offset-9 {
  margin-left: 37.5%;
}

.col-xs-offset-10 {
  margin-left: 41.6666666667%;
}

.col-xs-offset-11 {
  margin-left: 45.8333333333%;
}

.col-xs-offset-12 {
  margin-left: 50%;
}

.col-xs-offset-13 {
  margin-left: 54.1666666667%;
}

.col-xs-offset-14 {
  margin-left: 58.3333333333%;
}

.col-xs-offset-15 {
  margin-left: 62.5%;
}

.col-xs-offset-16 {
  margin-left: 66.6666666667%;
}

.col-xs-offset-17 {
  margin-left: 70.8333333333%;
}

.col-xs-offset-18 {
  margin-left: 75%;
}

.col-xs-offset-19 {
  margin-left: 79.1666666667%;
}

.col-xs-offset-20 {
  margin-left: 83.3333333333%;
}

.col-xs-offset-21 {
  margin-left: 87.5%;
}

.col-xs-offset-22 {
  margin-left: 91.6666666667%;
}

.col-xs-offset-23 {
  margin-left: 95.8333333333%;
}

.col-xs-offset-24 {
  margin-left: 100%;
}

@media (min-width: 768px) {
  .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-13, .col-sm-14, .col-sm-15, .col-sm-16, .col-sm-17, .col-sm-18, .col-sm-19, .col-sm-20, .col-sm-21, .col-sm-22, .col-sm-23, .col-sm-24 {
    float: left;
  }
  .col-sm-1 {
    width: 4.1666666667%;
  }
  .col-sm-2 {
    width: 8.3333333333%;
  }
  .col-sm-3 {
    width: 12.5%;
  }
  .col-sm-4 {
    width: 16.6666666667%;
  }
  .col-sm-5 {
    width: 20.8333333333%;
  }
  .col-sm-6 {
    width: 25%;
  }
  .col-sm-7 {
    width: 29.1666666667%;
  }
  .col-sm-8 {
    width: 33.3333333333%;
  }
  .col-sm-9 {
    width: 37.5%;
  }
  .col-sm-10 {
    width: 41.6666666667%;
  }
  .col-sm-11 {
    width: 45.8333333333%;
  }
  .col-sm-12 {
    width: 50%;
  }
  .col-sm-13 {
    width: 54.1666666667%;
  }
  .col-sm-14 {
    width: 58.3333333333%;
  }
  .col-sm-15 {
    width: 62.5%;
  }
  .col-sm-16 {
    width: 66.6666666667%;
  }
  .col-sm-17 {
    width: 70.8333333333%;
  }
  .col-sm-18 {
    width: 75%;
  }
  .col-sm-19 {
    width: 79.1666666667%;
  }
  .col-sm-20 {
    width: 83.3333333333%;
  }
  .col-sm-21 {
    width: 87.5%;
  }
  .col-sm-22 {
    width: 91.6666666667%;
  }
  .col-sm-23 {
    width: 95.8333333333%;
  }
  .col-sm-24 {
    width: 100%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-pull-1 {
    right: 4.1666666667%;
  }
  .col-sm-pull-2 {
    right: 8.3333333333%;
  }
  .col-sm-pull-3 {
    right: 12.5%;
  }
  .col-sm-pull-4 {
    right: 16.6666666667%;
  }
  .col-sm-pull-5 {
    right: 20.8333333333%;
  }
  .col-sm-pull-6 {
    right: 25%;
  }
  .col-sm-pull-7 {
    right: 29.1666666667%;
  }
  .col-sm-pull-8 {
    right: 33.3333333333%;
  }
  .col-sm-pull-9 {
    right: 37.5%;
  }
  .col-sm-pull-10 {
    right: 41.6666666667%;
  }
  .col-sm-pull-11 {
    right: 45.8333333333%;
  }
  .col-sm-pull-12 {
    right: 50%;
  }
  .col-sm-pull-13 {
    right: 54.1666666667%;
  }
  .col-sm-pull-14 {
    right: 58.3333333333%;
  }
  .col-sm-pull-15 {
    right: 62.5%;
  }
  .col-sm-pull-16 {
    right: 66.6666666667%;
  }
  .col-sm-pull-17 {
    right: 70.8333333333%;
  }
  .col-sm-pull-18 {
    right: 75%;
  }
  .col-sm-pull-19 {
    right: 79.1666666667%;
  }
  .col-sm-pull-20 {
    right: 83.3333333333%;
  }
  .col-sm-pull-21 {
    right: 87.5%;
  }
  .col-sm-pull-22 {
    right: 91.6666666667%;
  }
  .col-sm-pull-23 {
    right: 95.8333333333%;
  }
  .col-sm-pull-24 {
    right: 100%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-push-1 {
    left: 4.1666666667%;
  }
  .col-sm-push-2 {
    left: 8.3333333333%;
  }
  .col-sm-push-3 {
    left: 12.5%;
  }
  .col-sm-push-4 {
    left: 16.6666666667%;
  }
  .col-sm-push-5 {
    left: 20.8333333333%;
  }
  .col-sm-push-6 {
    left: 25%;
  }
  .col-sm-push-7 {
    left: 29.1666666667%;
  }
  .col-sm-push-8 {
    left: 33.3333333333%;
  }
  .col-sm-push-9 {
    left: 37.5%;
  }
  .col-sm-push-10 {
    left: 41.6666666667%;
  }
  .col-sm-push-11 {
    left: 45.8333333333%;
  }
  .col-sm-push-12 {
    left: 50%;
  }
  .col-sm-push-13 {
    left: 54.1666666667%;
  }
  .col-sm-push-14 {
    left: 58.3333333333%;
  }
  .col-sm-push-15 {
    left: 62.5%;
  }
  .col-sm-push-16 {
    left: 66.6666666667%;
  }
  .col-sm-push-17 {
    left: 70.8333333333%;
  }
  .col-sm-push-18 {
    left: 75%;
  }
  .col-sm-push-19 {
    left: 79.1666666667%;
  }
  .col-sm-push-20 {
    left: 83.3333333333%;
  }
  .col-sm-push-21 {
    left: 87.5%;
  }
  .col-sm-push-22 {
    left: 91.6666666667%;
  }
  .col-sm-push-23 {
    left: 95.8333333333%;
  }
  .col-sm-push-24 {
    left: 100%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
  .col-sm-offset-1 {
    margin-left: 4.1666666667%;
  }
  .col-sm-offset-2 {
    margin-left: 8.3333333333%;
  }
  .col-sm-offset-3 {
    margin-left: 12.5%;
  }
  .col-sm-offset-4 {
    margin-left: 16.6666666667%;
  }
  .col-sm-offset-5 {
    margin-left: 20.8333333333%;
  }
  .col-sm-offset-6 {
    margin-left: 25%;
  }
  .col-sm-offset-7 {
    margin-left: 29.1666666667%;
  }
  .col-sm-offset-8 {
    margin-left: 33.3333333333%;
  }
  .col-sm-offset-9 {
    margin-left: 37.5%;
  }
  .col-sm-offset-10 {
    margin-left: 41.6666666667%;
  }
  .col-sm-offset-11 {
    margin-left: 45.8333333333%;
  }
  .col-sm-offset-12 {
    margin-left: 50%;
  }
  .col-sm-offset-13 {
    margin-left: 54.1666666667%;
  }
  .col-sm-offset-14 {
    margin-left: 58.3333333333%;
  }
  .col-sm-offset-15 {
    margin-left: 62.5%;
  }
  .col-sm-offset-16 {
    margin-left: 66.6666666667%;
  }
  .col-sm-offset-17 {
    margin-left: 70.8333333333%;
  }
  .col-sm-offset-18 {
    margin-left: 75%;
  }
  .col-sm-offset-19 {
    margin-left: 79.1666666667%;
  }
  .col-sm-offset-20 {
    margin-left: 83.3333333333%;
  }
  .col-sm-offset-21 {
    margin-left: 87.5%;
  }
  .col-sm-offset-22 {
    margin-left: 91.6666666667%;
  }
  .col-sm-offset-23 {
    margin-left: 95.8333333333%;
  }
  .col-sm-offset-24 {
    margin-left: 100%;
  }
}

@media (min-width: 992px) {
  .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-13, .col-md-14, .col-md-15, .col-md-16, .col-md-17, .col-md-18, .col-md-19, .col-md-20, .col-md-21, .col-md-22, .col-md-23, .col-md-24 {
    float: left;
  }
  .col-md-1 {
    width: 4.1666666667%;
  }
  .col-md-2 {
    width: 8.3333333333%;
  }
  .col-md-3 {
    width: 12.5%;
  }
  .col-md-4 {
    width: 16.6666666667%;
  }
  .col-md-5 {
    width: 20.8333333333%;
  }
  .col-md-6 {
    width: 25%;
  }
  .col-md-7 {
    width: 29.1666666667%;
  }
  .col-md-8 {
    width: 33.3333333333%;
  }
  .col-md-9 {
    width: 37.5%;
  }
  .col-md-10 {
    width: 41.6666666667%;
  }
  .col-md-11 {
    width: 45.8333333333%;
  }
  .col-md-12 {
    width: 50%;
  }
  .col-md-13 {
    width: 54.1666666667%;
  }
  .col-md-14 {
    width: 58.3333333333%;
  }
  .col-md-15 {
    width: 62.5%;
  }
  .col-md-16 {
    width: 66.6666666667%;
  }
  .col-md-17 {
    width: 70.8333333333%;
  }
  .col-md-18 {
    width: 75%;
  }
  .col-md-19 {
    width: 79.1666666667%;
  }
  .col-md-20 {
    width: 83.3333333333%;
  }
  .col-md-21 {
    width: 87.5%;
  }
  .col-md-22 {
    width: 91.6666666667%;
  }
  .col-md-23 {
    width: 95.8333333333%;
  }
  .col-md-24 {
    width: 100%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-pull-1 {
    right: 4.1666666667%;
  }
  .col-md-pull-2 {
    right: 8.3333333333%;
  }
  .col-md-pull-3 {
    right: 12.5%;
  }
  .col-md-pull-4 {
    right: 16.6666666667%;
  }
  .col-md-pull-5 {
    right: 20.8333333333%;
  }
  .col-md-pull-6 {
    right: 25%;
  }
  .col-md-pull-7 {
    right: 29.1666666667%;
  }
  .col-md-pull-8 {
    right: 33.3333333333%;
  }
  .col-md-pull-9 {
    right: 37.5%;
  }
  .col-md-pull-10 {
    right: 41.6666666667%;
  }
  .col-md-pull-11 {
    right: 45.8333333333%;
  }
  .col-md-pull-12 {
    right: 50%;
  }
  .col-md-pull-13 {
    right: 54.1666666667%;
  }
  .col-md-pull-14 {
    right: 58.3333333333%;
  }
  .col-md-pull-15 {
    right: 62.5%;
  }
  .col-md-pull-16 {
    right: 66.6666666667%;
  }
  .col-md-pull-17 {
    right: 70.8333333333%;
  }
  .col-md-pull-18 {
    right: 75%;
  }
  .col-md-pull-19 {
    right: 79.1666666667%;
  }
  .col-md-pull-20 {
    right: 83.3333333333%;
  }
  .col-md-pull-21 {
    right: 87.5%;
  }
  .col-md-pull-22 {
    right: 91.6666666667%;
  }
  .col-md-pull-23 {
    right: 95.8333333333%;
  }
  .col-md-pull-24 {
    right: 100%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-push-1 {
    left: 4.1666666667%;
  }
  .col-md-push-2 {
    left: 8.3333333333%;
  }
  .col-md-push-3 {
    left: 12.5%;
  }
  .col-md-push-4 {
    left: 16.6666666667%;
  }
  .col-md-push-5 {
    left: 20.8333333333%;
  }
  .col-md-push-6 {
    left: 25%;
  }
  .col-md-push-7 {
    left: 29.1666666667%;
  }
  .col-md-push-8 {
    left: 33.3333333333%;
  }
  .col-md-push-9 {
    left: 37.5%;
  }
  .col-md-push-10 {
    left: 41.6666666667%;
  }
  .col-md-push-11 {
    left: 45.8333333333%;
  }
  .col-md-push-12 {
    left: 50%;
  }
  .col-md-push-13 {
    left: 54.1666666667%;
  }
  .col-md-push-14 {
    left: 58.3333333333%;
  }
  .col-md-push-15 {
    left: 62.5%;
  }
  .col-md-push-16 {
    left: 66.6666666667%;
  }
  .col-md-push-17 {
    left: 70.8333333333%;
  }
  .col-md-push-18 {
    left: 75%;
  }
  .col-md-push-19 {
    left: 79.1666666667%;
  }
  .col-md-push-20 {
    left: 83.3333333333%;
  }
  .col-md-push-21 {
    left: 87.5%;
  }
  .col-md-push-22 {
    left: 91.6666666667%;
  }
  .col-md-push-23 {
    left: 95.8333333333%;
  }
  .col-md-push-24 {
    left: 100%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
  .col-md-offset-1 {
    margin-left: 4.1666666667%;
  }
  .col-md-offset-2 {
    margin-left: 8.3333333333%;
  }
  .col-md-offset-3 {
    margin-left: 12.5%;
  }
  .col-md-offset-4 {
    margin-left: 16.6666666667%;
  }
  .col-md-offset-5 {
    margin-left: 20.8333333333%;
  }
  .col-md-offset-6 {
    margin-left: 25%;
  }
  .col-md-offset-7 {
    margin-left: 29.1666666667%;
  }
  .col-md-offset-8 {
    margin-left: 33.3333333333%;
  }
  .col-md-offset-9 {
    margin-left: 37.5%;
  }
  .col-md-offset-10 {
    margin-left: 41.6666666667%;
  }
  .col-md-offset-11 {
    margin-left: 45.8333333333%;
  }
  .col-md-offset-12 {
    margin-left: 50%;
  }
  .col-md-offset-13 {
    margin-left: 54.1666666667%;
  }
  .col-md-offset-14 {
    margin-left: 58.3333333333%;
  }
  .col-md-offset-15 {
    margin-left: 62.5%;
  }
  .col-md-offset-16 {
    margin-left: 66.6666666667%;
  }
  .col-md-offset-17 {
    margin-left: 70.8333333333%;
  }
  .col-md-offset-18 {
    margin-left: 75%;
  }
  .col-md-offset-19 {
    margin-left: 79.1666666667%;
  }
  .col-md-offset-20 {
    margin-left: 83.3333333333%;
  }
  .col-md-offset-21 {
    margin-left: 87.5%;
  }
  .col-md-offset-22 {
    margin-left: 91.6666666667%;
  }
  .col-md-offset-23 {
    margin-left: 95.8333333333%;
  }
  .col-md-offset-24 {
    margin-left: 100%;
  }
}

@media (min-width: 1200px) {
  .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-13, .col-lg-14, .col-lg-15, .col-lg-16, .col-lg-17, .col-lg-18, .col-lg-19, .col-lg-20, .col-lg-21, .col-lg-22, .col-lg-23, .col-lg-24 {
    float: left;
  }
  .col-lg-1 {
    width: 4.1666666667%;
  }
  .col-lg-2 {
    width: 8.3333333333%;
  }
  .col-lg-3 {
    width: 12.5%;
  }
  .col-lg-4 {
    width: 16.6666666667%;
  }
  .col-lg-5 {
    width: 20.8333333333%;
  }
  .col-lg-6 {
    width: 25%;
  }
  .col-lg-7 {
    width: 29.1666666667%;
  }
  .col-lg-8 {
    width: 33.3333333333%;
  }
  .col-lg-9 {
    width: 37.5%;
  }
  .col-lg-10 {
    width: 41.6666666667%;
  }
  .col-lg-11 {
    width: 45.8333333333%;
  }
  .col-lg-12 {
    width: 50%;
  }
  .col-lg-13 {
    width: 54.1666666667%;
  }
  .col-lg-14 {
    width: 58.3333333333%;
  }
  .col-lg-15 {
    width: 62.5%;
  }
  .col-lg-16 {
    width: 66.6666666667%;
  }
  .col-lg-17 {
    width: 70.8333333333%;
  }
  .col-lg-18 {
    width: 75%;
  }
  .col-lg-19 {
    width: 79.1666666667%;
  }
  .col-lg-20 {
    width: 83.3333333333%;
  }
  .col-lg-21 {
    width: 87.5%;
  }
  .col-lg-22 {
    width: 91.6666666667%;
  }
  .col-lg-23 {
    width: 95.8333333333%;
  }
  .col-lg-24 {
    width: 100%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-pull-1 {
    right: 4.1666666667%;
  }
  .col-lg-pull-2 {
    right: 8.3333333333%;
  }
  .col-lg-pull-3 {
    right: 12.5%;
  }
  .col-lg-pull-4 {
    right: 16.6666666667%;
  }
  .col-lg-pull-5 {
    right: 20.8333333333%;
  }
  .col-lg-pull-6 {
    right: 25%;
  }
  .col-lg-pull-7 {
    right: 29.1666666667%;
  }
  .col-lg-pull-8 {
    right: 33.3333333333%;
  }
  .col-lg-pull-9 {
    right: 37.5%;
  }
  .col-lg-pull-10 {
    right: 41.6666666667%;
  }
  .col-lg-pull-11 {
    right: 45.8333333333%;
  }
  .col-lg-pull-12 {
    right: 50%;
  }
  .col-lg-pull-13 {
    right: 54.1666666667%;
  }
  .col-lg-pull-14 {
    right: 58.3333333333%;
  }
  .col-lg-pull-15 {
    right: 62.5%;
  }
  .col-lg-pull-16 {
    right: 66.6666666667%;
  }
  .col-lg-pull-17 {
    right: 70.8333333333%;
  }
  .col-lg-pull-18 {
    right: 75%;
  }
  .col-lg-pull-19 {
    right: 79.1666666667%;
  }
  .col-lg-pull-20 {
    right: 83.3333333333%;
  }
  .col-lg-pull-21 {
    right: 87.5%;
  }
  .col-lg-pull-22 {
    right: 91.6666666667%;
  }
  .col-lg-pull-23 {
    right: 95.8333333333%;
  }
  .col-lg-pull-24 {
    right: 100%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-push-1 {
    left: 4.1666666667%;
  }
  .col-lg-push-2 {
    left: 8.3333333333%;
  }
  .col-lg-push-3 {
    left: 12.5%;
  }
  .col-lg-push-4 {
    left: 16.6666666667%;
  }
  .col-lg-push-5 {
    left: 20.8333333333%;
  }
  .col-lg-push-6 {
    left: 25%;
  }
  .col-lg-push-7 {
    left: 29.1666666667%;
  }
  .col-lg-push-8 {
    left: 33.3333333333%;
  }
  .col-lg-push-9 {
    left: 37.5%;
  }
  .col-lg-push-10 {
    left: 41.6666666667%;
  }
  .col-lg-push-11 {
    left: 45.8333333333%;
  }
  .col-lg-push-12 {
    left: 50%;
  }
  .col-lg-push-13 {
    left: 54.1666666667%;
  }
  .col-lg-push-14 {
    left: 58.3333333333%;
  }
  .col-lg-push-15 {
    left: 62.5%;
  }
  .col-lg-push-16 {
    left: 66.6666666667%;
  }
  .col-lg-push-17 {
    left: 70.8333333333%;
  }
  .col-lg-push-18 {
    left: 75%;
  }
  .col-lg-push-19 {
    left: 79.1666666667%;
  }
  .col-lg-push-20 {
    left: 83.3333333333%;
  }
  .col-lg-push-21 {
    left: 87.5%;
  }
  .col-lg-push-22 {
    left: 91.6666666667%;
  }
  .col-lg-push-23 {
    left: 95.8333333333%;
  }
  .col-lg-push-24 {
    left: 100%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
  .col-lg-offset-1 {
    margin-left: 4.1666666667%;
  }
  .col-lg-offset-2 {
    margin-left: 8.3333333333%;
  }
  .col-lg-offset-3 {
    margin-left: 12.5%;
  }
  .col-lg-offset-4 {
    margin-left: 16.6666666667%;
  }
  .col-lg-offset-5 {
    margin-left: 20.8333333333%;
  }
  .col-lg-offset-6 {
    margin-left: 25%;
  }
  .col-lg-offset-7 {
    margin-left: 29.1666666667%;
  }
  .col-lg-offset-8 {
    margin-left: 33.3333333333%;
  }
  .col-lg-offset-9 {
    margin-left: 37.5%;
  }
  .col-lg-offset-10 {
    margin-left: 41.6666666667%;
  }
  .col-lg-offset-11 {
    margin-left: 45.8333333333%;
  }
  .col-lg-offset-12 {
    margin-left: 50%;
  }
  .col-lg-offset-13 {
    margin-left: 54.1666666667%;
  }
  .col-lg-offset-14 {
    margin-left: 58.3333333333%;
  }
  .col-lg-offset-15 {
    margin-left: 62.5%;
  }
  .col-lg-offset-16 {
    margin-left: 66.6666666667%;
  }
  .col-lg-offset-17 {
    margin-left: 70.8333333333%;
  }
  .col-lg-offset-18 {
    margin-left: 75%;
  }
  .col-lg-offset-19 {
    margin-left: 79.1666666667%;
  }
  .col-lg-offset-20 {
    margin-left: 83.3333333333%;
  }
  .col-lg-offset-21 {
    margin-left: 87.5%;
  }
  .col-lg-offset-22 {
    margin-left: 91.6666666667%;
  }
  .col-lg-offset-23 {
    margin-left: 95.8333333333%;
  }
  .col-lg-offset-24 {
    margin-left: 100%;
  }
}

@-ms-viewport {
  width: device-width;
}

.visible-xs {
  display: none !important;
}

.visible-sm {
  display: none !important;
}

.visible-md {
  display: none !important;
}

.visible-lg {
  display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table !important;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table !important;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-block {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table !important;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-block {
    display: block !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline {
    display: inline !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table !important;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-block {
    display: block !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1200px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1200px) {
  .hidden-lg {
    display: none !important;
  }
}

.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table !important;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}

.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}

/*! Gray v1.7.0 (https://github.com/karlhorky/gray) | MIT */
.grayscale {
  /* Firefox 10-34 */
  filter: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='saturate' values='0'/></filter></svg>#grayscale");
  /*
    Chrome 19+,
    Safari 6+,
    Safari 6+ iOS,
    Opera 15+
  */
  -webkit-filter: grayscale(1);
  /* Firefox 35+ */
  filter: grayscale(1);
  /* IE 6-9 */
  filter: gray;
}

.grayscale.grayscale-fade {
  transition: filter .5s;
}

/* Webkit hack until filter is unprefixed */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .grayscale.grayscale-fade {
    -webkit-transition: -webkit-filter .5s;
    transition: -webkit-filter .5s;
  }
}

.grayscale.grayscale-off,
.grayscale.grayscale-fade:hover {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

/* Background element */
.grayscale.grayscale-replaced {
  -webkit-filter: none;
  filter: none;
}

.grayscale.grayscale-replaced > svg {
  -webkit-transition: opacity .5s ease;
  transition: opacity .5s ease;
  opacity: 1;
}

.grayscale.grayscale-replaced.grayscale-off > svg,
.grayscale.grayscale-replaced.grayscale-fade:hover > svg {
  opacity: 0;
}

.grayscale {
  -webkit-filter: grayscale(1);
  filter: grayscale(1);
}

.grayscale-fade:hover .grayscale {
  -webkit-filter: grayscale(0);
  filter: grayscale(0);
}

.grayscale-fade:hover .grayscale > svg {
  opacity: 0;
}

#billhead .grayscale-fade {
  color: #fff;
}

.slider a {
  color: #fff;
}

@media screen and (min-width: 0) and (min-resolution: 0.001dpcm) {
  .grayscale {
    filter: gray;
  }
}

/*/////////////////////////////////////////////////////////////////////////////////////////// ANIMATE */
/* HAMBURGER */
@-webkit-keyframes open_burg_samp {
  0% {
    height: 5px;
    margin: 5px 0px 0px;
  }
  50% {
    height: 0px;
    margin: 1px 0px 0px;
  }
  100% {
    height: 0px;
    margin: 1px 0px 0px;
  }
}

@-moz-keyframes open_burg_samp {
  0% {
    height: 5px;
    margin: 5px 0px 0px;
  }
  50% {
    height: 0px;
    margin: 1px 0px 0px;
  }
  100% {
    height: 0px;
    margin: 1px 0px 0px;
  }
}

@-o-keyframes open_burg_samp {
  0% {
    height: 5px;
    margin: 5px 0px 0px;
  }
  50% {
    height: 0px;
    margin: 1px 0px 0px;
  }
  100% {
    height: 0px;
    margin: 1px 0px 0px;
  }
}

@keyframes open_burg_samp {
  0% {
    height: 5px;
    margin: 5px 0px 0px;
  }
  50% {
    height: 0px;
    margin: 1px 0px 0px;
  }
  100% {
    height: 0px;
    margin: 1px 0px 0px;
  }
}

@-webkit-keyframes close_burg_samp {
  0% {
    height: 0px;
    margin: 0px 0px;
  }
  50% {
    height: 0px;
    margin: 0px 0px;
  }
  100% {
    height: 5px;
    margin: 5px 0px;
  }
}

@-moz-keyframes close_burg_samp {
  0% {
    height: 0px;
    margin: 0px 0px;
  }
  50% {
    height: 0px;
    margin: 0px 0px;
  }
  100% {
    height: 5px;
    margin: 5px 0px;
  }
}

@-o-keyframes close_burg_samp {
  0% {
    height: 0px;
    margin: 0px 0px;
  }
  50% {
    height: 0px;
    margin: 0px 0px;
  }
  100% {
    height: 5px;
    margin: 5px 0px;
  }
}

@keyframes close_burg_samp {
  0% {
    height: 0px;
    margin: 0px 0px;
  }
  50% {
    height: 0px;
    margin: 0px 0px;
  }
  100% {
    height: 5px;
    margin: 5px 0px;
  }
}

@-webkit-keyframes open_burg_before {
  0% {
    transform: rotate(0deg);
    margin-top: 0px;
  }
  50% {
    transform: rotate(0deg);
    margin-top: 5px;
  }
  100% {
    transform: rotate(45deg);
    margin-top: 10px;
  }
}

@-moz-keyframes open_burg_before {
  0% {
    transform: rotate(0deg);
    margin-top: 0px;
  }
  50% {
    transform: rotate(0deg);
    margin-top: 5px;
  }
  100% {
    transform: rotate(45deg);
    margin-top: 10px;
  }
}

@-o-keyframes open_burg_before {
  0% {
    transform: rotate(0deg);
    margin-top: 0px;
  }
  50% {
    transform: rotate(0deg);
    margin-top: 5px;
  }
  100% {
    transform: rotate(45deg);
    margin-top: 10px;
  }
}

@keyframes open_burg_before {
  0% {
    transform: rotate(0deg);
    margin-top: 0px;
  }
  50% {
    transform: rotate(0deg);
    margin-top: 5px;
  }
  100% {
    transform: rotate(45deg);
    margin-top: 10px;
  }
}

@-webkit-keyframes close_burg_before {
  0% {
    transform: rotate(45deg);
    margin-top: 10px;
  }
  50% {
    transform: rotate(0deg);
    margin-top: 5px;
  }
  100% {
    ransform: rotate(0deg);
    margin-top: 0px;
  }
}

@-moz-keyframes close_burg_before {
  0% {
    transform: rotate(45deg);
    margin-top: 10px;
  }
  50% {
    transform: rotate(0deg);
    margin-top: 5px;
  }
  100% {
    ransform: rotate(0deg);
    margin-top: 0px;
  }
}

@-o-keyframes close_burg_before {
  0% {
    transform: rotate(45deg);
    margin-top: 10px;
  }
  50% {
    transform: rotate(0deg);
    margin-top: 5px;
  }
  100% {
    ransform: rotate(0deg);
    margin-top: 0px;
  }
}

@keyframes close_burg_before {
  0% {
    transform: rotate(45deg);
    margin-top: 10px;
  }
  50% {
    transform: rotate(0deg);
    margin-top: 5px;
  }
  100% {
    ransform: rotate(0deg);
    margin-top: 0px;
  }
}

@-webkit-keyframes open_burg_after {
  0% {
    transform: rotate(0deg);
    margin-top: 0px;
  }
  50% {
    transform: rotate(0deg);
    margin-top: -6px;
  }
  100% {
    transform: rotate(-45deg);
    margin-top: -6px;
  }
}

@-moz-keyframes open_burg_after {
  0% {
    transform: rotate(0deg);
    margin-top: 0px;
  }
  50% {
    transform: rotate(0deg);
    margin-top: -6px;
  }
  100% {
    transform: rotate(-45deg);
    margin-top: -6px;
  }
}

@-o-keyframes open_burg_after {
  0% {
    transform: rotate(0deg);
    margin-top: 0px;
  }
  50% {
    transform: rotate(0deg);
    margin-top: -6px;
  }
  100% {
    transform: rotate(-45deg);
    margin-top: -6px;
  }
}

@keyframes open_burg_after {
  0% {
    transform: rotate(0deg);
    margin-top: 0px;
  }
  50% {
    transform: rotate(0deg);
    margin-top: -6px;
  }
  100% {
    transform: rotate(-45deg);
    margin-top: -6px;
  }
}

@-webkit-keyframes close_burg_after {
  0% {
    transform: rotate(-45deg);
    margin-top: -6px;
  }
  50% {
    transform: rotate(0deg);
    margin-top: -6px;
  }
  100% {
    transform: rotate(0deg);
    margin-top: 0px;
  }
}

@-moz-keyframes close_burg_after {
  0% {
    transform: rotate(-45deg);
    margin-top: -6px;
  }
  50% {
    transform: rotate(0deg);
    margin-top: -6px;
  }
  100% {
    transform: rotate(0deg);
    margin-top: 0px;
  }
}

@-o-keyframes close_burg_after {
  0% {
    transform: rotate(-45deg);
    margin-top: -6px;
  }
  50% {
    transform: rotate(0deg);
    margin-top: -6px;
  }
  100% {
    transform: rotate(0deg);
    margin-top: 0px;
  }
}

@keyframes close_burg_after {
  0% {
    transform: rotate(-45deg);
    margin-top: -6px;
  }
  50% {
    transform: rotate(0deg);
    margin-top: -6px;
  }
  100% {
    transform: rotate(0deg);
    margin-top: 0px;
  }
}

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

.fade.in {
  opacity: 1;
}

.collapse {
  display: none;
}

.collapse.in {
  display: block;
}

tr.collapse.in {
  display: table-row;
}

tbody.collapse.in {
  display: table-row-group;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  -webkit-transition-property: height, visibility;
  transition-property: height, visibility;
  -webkit-transition-duration: 0.35s;
  transition-duration: 0.35s;
  -webkit-transition-timing-function: ease;
  transition-timing-function: ease;
}

.modal-open {
  overflow: hidden;
}

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  outline: 0;
}

.modal.fade .modal-dialog {
  -webkit-transform: translate(0, -25%);
  -ms-transform: translate(0, -25%);
  -o-transform: translate(0, -25%);
  transform: translate(0, -25%);
  -webkit-transition: -webkit-transform 0.3s ease-out;
  -moz-transition: -moz-transform 0.3s ease-out;
  -o-transition: -o-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
}

.modal.in .modal-dialog {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 10px;
}

.modal-content {
  position: relative;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #999;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}

.modal-backdrop.fade {
  filter: alpha(opacity=0);
  opacity: 0;
}

.modal-backdrop.in {
  filter: alpha(opacity=50);
  opacity: 0.5;
}

.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}

.modal-header:before, .modal-header:after {
  display: table;
  content: " ";
}

.modal-header:after {
  clear: both;
}

.modal-header .close {
  margin-top: -2px;
}

.modal-title {
  margin: 0;
  line-height: 1.428571429;
}

.modal-body {
  position: relative;
  padding: 15px;
}

.modal-footer {
  padding: 15px;
  text-align: right;
  border-top: 1px solid #e5e5e5;
}

.modal-footer:before, .modal-footer:after {
  display: table;
  content: " ";
}

.modal-footer:after {
  clear: both;
}

.modal-footer .btn + .btn {
  margin-bottom: 0;
  margin-left: 5px;
}

.modal-footer .btn-group .btn + .btn {
  margin-left: -1px;
}

.modal-footer .btn-block + .btn-block {
  margin-left: 0;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 768px) {
  .modal-dialog {
    width: 600px;
    margin: 30px auto;
  }
  .modal-content {
    -webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  }
  .modal-sm {
    width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg {
    width: 900px;
  }
}

.eminem-modal .modal-content {
  border-radius: 0px;
}

.eminem-modal .modal-content .modal-header {
  padding-bottom: 5px;
  border-bottom: none;
  color: #000000;
  text-transform: lowercase;
}

.eminem-modal .modal-content .modal-header .close {
  float: right;
  font-family: 'FontAwesome';
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  font-size: 36px;
  vertical-align: middle;
}

.eminem-modal .modal-content .modal-header .close span {
  display: none;
}

.eminem-modal .modal-content .modal-header .close:before {
  content: "\f057";
}

.eminem-modal .modal-content .modal-header .modal-title {
  font-family: 'AtlasG Medium';
  font-size: 16px;
  margin: 6px 0px;
}

.eminem-modal .modal-content .modal-body {
  padding-top: 0;
}

.eminem-modal .modal-content .modal-body .nav-pills {
  display: block;
  margin: 0px 0px 20px;
  font-size: 36px;
  text-align: center;
}

.eminem-modal .modal-content .modal-body .nav-pills li {
  display: inline-block;
  margin: 0px 30px;
}

.eminem-modal .modal-content .modal-body .nav-pills li.active a {
  color: #7300fa;
  font-weight: 700;
}

.eminem-modal .modal-content .modal-body .tab-content .tab-pane {
  display: none;
}

.eminem-modal .modal-content .modal-body .tab-content .tab-pane.active {
  display: block;
}

.eminem-modal .modal-content .modal-body .tab-content .tab-pane .drag-and-drop {
  position: relative;
  color: #000000;
  text-align: center;
}

.eminem-modal .modal-content .modal-body .tab-content .tab-pane .drag-and-drop .drag-text {
  position: relative;
  width: 100%;
  height: 180px;
  padding: 20px 0px;
  background-color: #ffffff;
  border: 2px solid #000000;
  z-index: 2;
}

.eminem-modal .modal-content .modal-body .tab-content .tab-pane .drag-and-drop .drop-text {
  position: absolute;
  top: 0;
  width: 100%;
  height: 180px;
  border: 2px dashed #7300fa;
  background-color: #fff;
  z-index: 1;
}

.eminem-modal .modal-content .modal-body .tab-content .tab-pane .drag-and-drop .drop-text .drag-title {
  font-size: 18px;
  line-height: 180px;
  color: #7300fa;
}

.eminem-modal .modal-content .modal-body .tab-content .tab-pane .drag-and-drop .drag-title {
  color: #000000;
  font-family: 'AtlasG Regular', sans-serif;
  font-size: 100%;
}

.eminem-modal .modal-content .modal-body .tab-content .tab-pane .drag-and-drop .separator {
  display: block;
  position: relative;
  width: 80px;
  height: 24px;
  margin: 20px auto 20px;
  font-family: 'AtlasG Regular';
  font-size: 16px;
  font-weight: bold;
  text-align: center;
}

@media (max-width: 479px) {
  .eminem-modal .modal-content .modal-body .tab-content .tab-pane .drag-and-drop .btn-default {
    font-size: 24px;
  }
}

.eminem-modal .modal-content .modal-body .tab-content .tab-pane .drag-and-drop.dragover .drag-text {
  z-index: 0;
}

.eminem-modal .modal-content .modal-body .tab-content .tab-pane .media-item {
  display: inline-table;
  width: 33%;
  padding: 5px;
}

.eminem-modal .modal-content .modal-body .tab-content .tab-pane .media-item img {
  width: 100%;
}

.eminem-modal .modal-content .modal-body .tab-content .tab-pane .media-item .btn-danger {
  float: left;
  width: 100%;
  padding: 14px 30px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 2px solid #000000;
  font-size: 14px;
  line-height: 12px;
  transition: 250ms ease-in-out;
}

.eminem-modal .modal-content .modal-body .tab-content .tab-pane .media-item .btn-danger:after {
  content: "\f00d";
  display: inline-block;
  margin-left: 5px;
  font-family: FontAwesome;
}

.eminem-modal .current-media .actions .details-heading,
.eminem-modal .current-media .actions .details-value {
  display: none;
}

.eminem-modal .current-media .actions .details-value-filename {
  display: block;
  text-decoration: none;
  margin-bottom: 8px;
  font-size: 14px;
  word-break: break-word;
}

.notitication-item, header .box-alerta .notification li, .notification-list li {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  padding: 20px 40px 20px 40px;
  border-bottom: 2px solid #e5e5e5;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  text-align: left;
}

.notitication-item .name, header .box-alerta .notification li .name, .notification-list li .name {
  display: block;
  font-family: 'AtlasG Medium';
  font-size: 16px;
}

.notitication-item .msg, header .box-alerta .notification li .msg, .notification-list li .msg {
  display: block;
  font-size: 12px;
}

.notitication-item .type, header .box-alerta .notification li .type, .notification-list li .type {
  display: block;
  font-family: 'AtlasG Medium';
  font-size: 12px;
}

.notitication-item.read a, header .box-alerta .notification li.read a, .notification-list li.read a,
.notitication-item.read p,
header .box-alerta .notification li.read p,
.notification-list li.read p,
.notitication-item.read span,
header .box-alerta .notification li.read span,
.notification-list li.read span {
  color: #7f7f7f;
}

header .box-alerta section {
  height: 100%;
}

header .box-alerta h2 {
  width: 100%;
  padding-top: 26px;
  font-size: 40px;
  text-align: center;
}

header .box-alerta .notification {
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: -webkit-calc(100% - 110px);
  height: calc(100% - 110px);
  overflow: auto;
}

header.fixed .box-alerta h2 {
  padding-top: 14px;
}

.toggle-notification-status {
  width: 10px;
  height: 10px;
  background-color: #000000;
  border: 2px solid #000;
  border-radius: 10px;
  display: block;
  position: absolute;
  top: 28px;
  left: 15px;
  cursor: pointer;
}

.toggle-notification-status:hover {
  background-color: #7300fa;
  border-color: #7300fa;
}

.read .toggle-notification-status {
  background-color: transparent;
  border-color: #7f7f7f;
}

.notification-list {
  margin-bottom: 30px;
}

/*/////////////////////////////////////////////////////////////////////////////////////////// SLIDER new*/
.flickity-enabled {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.flickity-enabled .flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

.flickity-enabled .slider-cell {
  width: 100%;
  height: 100%;
}

.flickity-enabled .flickity-prev-next-button {
  position: absolute;
  bottom: 10px;
  margin: 0px 10px;
  color: #fff;
  font-size: 40px;
  line-height: 40px;
  cursor: pointer;
}

.flickity-enabled .flickity-prev-next-button.previous {
  right: 40px;
}

.flickity-enabled .flickity-prev-next-button.previous:before {
  content: '\f104';
  font-family: 'FontAwesome';
}

.flickity-enabled .flickity-prev-next-button.next {
  right: 10px;
}

.flickity-enabled .flickity-prev-next-button.next:before {
  content: '\f105';
  font-family: 'FontAwesome';
}

.flickity-enabled .flickity-prev-next-button:hover:before {
  color: #7300fa;
}

.flickity-enabled .flickity-prev-next-button svg {
  display: none;
}

.flickity-enabled .flickity-conter {
  position: absolute;
  bottom: 10px;
  right: 90px;
  font-family: 'AtlasT Regular';
  font-size: 30px;
}

.flickity-enabled .flickity-conter span {
  display: inline-block !important;
  margin: 0px !important;
  font-size: 20px;
  vertical-align: top;
}

.flickity-enabled .flickity-conter span:last-child {
  vertical-align: bottom;
}

#slider-detail {
  float: left;
  position: relative;
  width: 100%;
  height: 646px;
  margin-bottom: 10px;
  padding-bottom: 65px;
  box-sizing: content-box;
}

#slider-detail .slider {
  padding-bottom: 65px;
  box-sizing: content-box;
}

#slider-detail .slider .slider-cell {
  height: calc(100% - 65px);
}

#slider-detail .descri {
  position: absolute;
  display: table;
  width: 100%;
  height: 65px;
  bottom: -65px;
  z-index: 1;
}

#slider-detail .descri:before {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
}

#slider-detail .descri .center {
  position: relative;
  display: table-cell;
  vertical-align: middle;
  z-index: 2;
}

#slider-detail .descri p {
  padding: 10px 180px 10px 20px;
  margin: 0px;
  color: #000000;
  font-size: 11px;
  line-height: 15px;
  text-align: left;
}

#slider-detail .flickity-prev-next-button {
  color: #000000;
}

#slider-work, #slider-studies {
  float: left;
  position: relative;
  width: 100%;
  height: 646px;
  margin-bottom: 10px;
  padding-bottom: 65px;
  box-sizing: content-box;
}

#slider-work .slider, #slider-studies .slider {
  padding-bottom: 65px;
  box-sizing: content-box;
}

#slider-work .slider .slider-cell, #slider-studies .slider .slider-cell {
  height: calc(100% - 65px);
  background: #ffffff;
  width: 33.33%;
}

#slider-work .slider .descri, #slider-studies .slider .descri {
  position: absolute;
  top: 20px;
  left: 10px;
  width: 90%;
  text-align: left;
  z-index: 2;
}

#slider-work .slider .descri .tipodata, #slider-studies .slider .descri .tipodata {
  float: left;
  width: 100%;
  font-family: 'AtlasT Regular';
  font-size: 14px;
  margin-bottom: 6px;
}

#slider-work .slider .descri .titulo, #slider-studies .slider .descri .titulo {
  float: left;
  width: 100%;
  font-family: 'AtlasG Medium';
  font-size: 26px;
  line-height: 28px;
}

#slider-work .flickity-prev-next-button, #slider-studies .flickity-prev-next-button {
  color: #000000;
}

#slider-work {
  height: 450px;
}

@media (max-width: 1199px) {
  #slider-detail {
    height: 444px;
  }
  #slider-studies {
    height: 534px;
  }
}

@media (max-width: 991px) {
  #slider-detail {
    height: 399px;
  }
  #slider-studies, #slider-work {
    height: 405px;
  }
  #slider-studies .slider .slider-cell, #slider-work .slider .slider-cell {
    width: 100%;
  }
}

@media (max-width: 767px) {
  #slider-detail .descri p, #slider-work .descri p, #slider-studies .descri p {
    padding: 10px 80px 10px 10px;
  }
  #slider-detail {
    height: 54.7vw;
  }
  #slider-studies, #slider-work {
    height: 400px;
  }
}

.page {
  float: right;
  position: relative;
  height: 40px;
}

.page a,
.page span {
  float: left;
  margin: 0px 10px;
  color: #000000;
  font-size: 40px;
  line-height: 40px;
}

.page a.prev:before,
.page span.prev:before {
  content: '\f104';
  font-family: 'FontAwesome';
}

.page a.next:before,
.page span.next:before {
  content: '\f105';
  font-family: 'FontAwesome';
}

.page a {
  cursor: pointer;
}

.page a:hover:before {
  color: #7300fa;
}

.page span {
  filter: alpha(opacity=25);
  opacity: 0.25;
}

.page .page-count {
  position: absolute;
  left: -80px;
  top: 10px;
  width: 80px;
  font-family: 'AtlasT Regular';
  font-size: 20px;
  color: #000000;
  text-align: right;
}

.page .page-count span {
  vertical-align: top;
}

.page .page-count span:last-child {
  vertical-align: bottom;
}

.filter {
  margin: 15px 0 25px;
  overflow: hidden;
  display: none;
}

.filter .form-group .checkbox {
  border: 2px solid;
}

.filter button {
  float: right;
  margin-top: 15px;
}

.form-group .indicator {
  position: absolute;
  top: 27px;
  right: 37px;
  z-index: 3;
  width: 40px;
  height: 40px;
  font-size: 30px;
  line-height: 34px;
  text-align: center;
}

.form-group .indicator:hover {
  color: #7300fa;
  cursor: pointer;
}

.showcase-search #billhead {
  position: relative;
  height: 540px;
}

.showcase-search #billhead .container {
  display: table;
  position: relative;
  height: 100%;
  text-align: center;
  z-index: 2;
}

.showcase-search #billhead .container .center {
  display: table-cell;
  vertical-align: middle;
}

.showcase-search #billhead .container .center h2 {
  font-size: 48px;
  font-weight: bold;
  color: #fff;
}

.showcase-search #billhead .container .center h3 {
  font-family: 'AtlasG Regular';
  font-size: 24px;
  color: #fff;
}

.showcase-search #pesquisa .search-type-toggles {
  padding-top: 30px;
  font-size: 20px;
}

.showcase-search #pesquisa .search-type-toggles label {
  margin-right: 30px;
}

.showcase-search #pesquisa .search-type-toggles input {
  position: relative;
  top: -2px;
  margin-right: 3px;
}

.showcase-search #pesquisa p {
  font-size: 20px;
  margin: 20px 0 30px;
}

.showcase-search #pesquisa .vertudo {
  float: left;
  width: 100%;
  margin: 80px auto 40px;
  font-size: 36px;
  text-align: center;
}

.showcase-search .filter {
  display: block;
  margin-top: 0;
}

@media (max-width: 991px) {
  .showcase-search #pesquisa p {
    margin: 20px 0px 40px;
  }
  .showcase-search #pesquisa .vertudo {
    margin: 20px auto 40px;
  }
}

.showcase .container,
.showcase-projects .container {
  margin: 50px auto 70px;
}

.showcase .container h2,
.showcase-projects .container h2 {
  float: left;
  width: 100%;
  font-size: 30px;
}

.showcase .container h2 span,
.showcase-projects .container h2 span {
  font-family: 'AtlasG Medium';
}

.showcase .container .showcase-list-actions a,
.showcase-projects .container .showcase-list-actions a {
  float: left;
  font-size: 16px;
}

.showcase .container .showcase-list-actions a:before,
.showcase-projects .container .showcase-list-actions a:before {
  content: '|';
  margin: 0 10px;
}

.showcase .container .showcase-list-actions a:first-child:before,
.showcase-projects .container .showcase-list-actions a:first-child:before {
  content: '';
  margin: 0;
}

.showcase .container .showcase-list-actions a.active,
.showcase-projects .container .showcase-list-actions a.active {
  font-weight: bold;
  color: #7300fa;
}

.showcase .container .list-style,
.showcase-projects .container .list-style {
  float: left;
  width: 100%;
  margin: 20px 0px;
}

.showcase .container .list-style .type,
.showcase-projects .container .list-style .type {
  float: left;
}

.showcase .container .list-style .type label,
.showcase-projects .container .list-style .type label {
  float: left;
  padding: 5px 20px 5px 0px;
  font-family: 'AtlasG Medium';
  font-size: 16px;
}

.showcase .container .list-style .type .form-group label,
.showcase-projects .container .list-style .type .form-group label {
  padding: 0px;
  font-family: 'AtlasG Regular';
}

.showcase .container .list-style .display,
.showcase-projects .container .list-style .display {
  float: right;
}

.showcase .container .list-style .display icon,
.showcase-projects .container .list-style .display icon {
  margin: 0px 6px;
  color: #b9b9b9;
  font-size: 24px;
  cursor: pointer;
}

.showcase .container .list-style .display icon.active,
.showcase-projects .container .list-style .display icon.active {
  color: #7300fa;
}

.showcase .thumbnail,
.showcase-projects .thumbnail {
  margin: 0 -15px;
}

.showcase .thumbnail .thumb,
.showcase-projects .thumbnail .thumb {
  position: relative;
  cursor: pointer;
  padding: 0px 15px 40px;
}

.showcase .thumbnail .thumb a,
.showcase-projects .thumbnail .thumb a {
  display: block;
}

.showcase .thumbnail .localnum,
.showcase-projects .thumbnail .localnum {
  float: left;
  width: 100%;
  font-family: 'AtlasT Regular';
  font-size: 12px;
}

.showcase .thumbnail .nome,
.showcase-projects .thumbnail .nome {
  float: left;
  font-family: 'AtlasG Medium';
  font-size: 19px;
}

.showcase .thumbnail .noimag,
.showcase-projects .thumbnail .noimag {
  display: none;
}

.showcase .info-box,
.showcase-projects .info-box {
  display: none;
}

.showcase .page,
.showcase-projects .page {
  float: right;
  position: relative;
  height: 40px;
}

.showcase .page a, .showcase .page span,
.showcase-projects .page a,
.showcase-projects .page span {
  float: left;
  margin: 0px 10px;
  color: #000000;
  font-size: 40px;
  line-height: 40px;
}

.showcase .page a.prev:before, .showcase .page span.prev:before,
.showcase-projects .page a.prev:before,
.showcase-projects .page span.prev:before {
  content: '\f104';
  font-family: 'FontAwesome';
}

.showcase .page a.next:before, .showcase .page span.next:before,
.showcase-projects .page a.next:before,
.showcase-projects .page span.next:before {
  content: '\f105';
  font-family: 'FontAwesome';
}

.showcase .page a,
.showcase-projects .page a {
  cursor: pointer;
}

.showcase .page a:hover:before,
.showcase-projects .page a:hover:before {
  color: #7300fa;
}

.showcase .page span,
.showcase-projects .page span {
  filter: alpha(opacity=25);
  opacity: 0.25;
}

.showcase .page .conter,
.showcase-projects .page .conter {
  position: absolute;
  left: -80px;
  top: 10px;
  width: 80px;
  font-family: 'AtlasT Regular';
  font-size: 20px;
  color: #000000;
  text-align: right;
}

.showcase .page .conter span,
.showcase-projects .page .conter span {
  vertical-align: top;
}

.showcase .page .conter span:last-child,
.showcase-projects .page .conter span:last-child {
  vertical-align: bottom;
}

@media (max-width: 991px) {
  .showcase .container h2,
  .showcase-projects .container h2 {
    font-size: 24px;
  }
  .showcase .popover,
  .showcase-projects .popover {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .showcase .container .filter .type label,
  .showcase-projects .container .filter .type label {
    display: block;
    float: none;
  }
}

@media (max-width: 479px) {
  .showcase .container .filter .display,
  .showcase-projects .container .filter .display {
    float: left;
    margin-top: 10px;
  }
  .showcase .thumbnail .thumb,
  .showcase-projects .thumbnail .thumb {
    width: 50%;
    padding: 0px 10px 40px;
  }
}

.showcase-detail,
.showcase-project-detail {
  /*#work{height:450px;
        #slider-work{position:relative; width:100%; height:100%;
            .slider-cell{height:calc(100% - 65px); background:$bgColor;  width:33.33%;
                img{@include transition($animatDefult);}
                .descri{position:absolute; top:20px; left:10px; width:90%; text-align:left; z-index:2;
                    .tipodata{float:left; width:100%; font-family:'AtlasT Regular'; font-size:14px; margin-bottom:6px;}
                    .titulo{float:left; width:100%; font-family:'AtlasG Medium'; font-size:26px; line-height:28px;}
                }
            }
        }
    }*/
}

.showcase-detail #billhead,
.showcase-project-detail #billhead {
  background: #ffffff;
  text-align: center;
}

.showcase-detail #billhead .imag,
.showcase-project-detail #billhead .imag {
  display: block;
  width: 190px;
  height: 190px;
  border-radius: 100%;
  overflow: hidden;
  margin: 0 auto 15px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.showcase-detail #billhead .local,
.showcase-project-detail #billhead .local {
  display: block;
  font-size: 24px;
  margin-top: 10px;
}

.showcase-detail #billhead .nome,
.showcase-project-detail #billhead .nome {
  display: block;
  font-size: 40px;
  font-weight: bold;
}

.showcase-detail #billhead p,
.showcase-project-detail #billhead p {
  display: block;
  width: 800px;
  padding-top: 30px;
  font-size: 20px;
  font-family: 'AtlasG Medium';
  margin: auto;
}

.showcase-detail #billhead .info,
.showcase-project-detail #billhead .info {
  height: auto;
  padding: 5px 0px;
  margin: 50px 0px;
}

.showcase-detail #billhead .info .imag,
.showcase-project-detail #billhead .info .imag {
  float: left;
  width: 80px;
  height: 80px;
  margin: 0 10px 0px 0px;
  border-radius: 100%;
  overflow: hidden;
}

.showcase-detail #billhead .info .local,
.showcase-project-detail #billhead .info .local {
  font-size: 16px;
  font-weight: bold;
  margin: 8px 0px -8px;
}

.showcase-detail #billhead .info .nome,
.showcase-project-detail #billhead .info .nome {
  width: auto;
  font-size: 28px;
  font-weight: bold;
}

.showcase-detail #billhead .info .middle,
.showcase-project-detail #billhead .info .middle {
  display: table;
  margin: auto;
  text-align: left;
}

.showcase-detail #billhead .info icon,
.showcase-project-detail #billhead .info icon {
  display: table-cell;
  vertical-align: middle;
  width: 50px;
  text-align: center;
  font-size: 36px;
}

.showcase-detail #billhead .info span,
.showcase-project-detail #billhead .info span {
  display: block;
  font-size: 12px;
  white-space: nowrap;
}

.showcase-detail #billhead .info span:nth-child(even),
.showcase-project-detail #billhead .info span:nth-child(even) {
  font-family: 'AtlasG Medium';
}

.showcase-detail #info .container,
.showcase-project-detail #info .container {
  position: relative;
  margin: 50px auto 70px;
  padding: 0px 0px 0px 0px;
}

.showcase-detail #info h2,
.showcase-project-detail #info h2 {
  margin-bottom: 20px;
  font-size: 30px;
  font-family: 'AtlasG Medium';
}

.showcase-detail #info h4,
.showcase-project-detail #info h4 {
  font-family: 'AtlasG Medium';
}

.showcase-detail #info span, .showcase-detail #info p,
.showcase-project-detail #info span,
.showcase-project-detail #info p {
  display: block;
  margin-bottom: 20px;
}

@media (max-width: 1199px) {
  .showcase-detail #info .container,
  .showcase-project-detail #info .container {
    padding: 0px 80px 50px;
  }
}

.showcase-detail .nav,
.showcase-project-detail .nav {
  position: absolute;
  top: 0;
  right: 8px;
}

.showcase-detail .nav a,
.showcase-project-detail .nav a {
  display: block;
  margin-bottom: 60px;
  font-size: 36px;
}

@media (max-width: 991px) {
  .showcase-detail #billhead .container p,
  .showcase-project-detail #billhead .container p {
    width: auto;
  }
  .showcase-detail #billhead .container .allinfo,
  .showcase-project-detail #billhead .container .allinfo {
    display: none;
  }
  .showcase-detail #info .container,
  .showcase-project-detail #info .container {
    padding: 0px 10px;
  }
}

.popover {
  position: absolute;
  padding: 20px;
  background: #ffffff;
  border: 2px solid #000000;
  z-index: 100;
}

.popover .popover-content {
  float: left;
  position: relative;
  width: 440px;
}

.popover .close {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 24px;
  cursor: pointer;
  z-index: 100;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

.popover .close:hover {
  color: #7300fa;
}

.popover .link {
  float: left;
  width: 100%;
  margin-top: 10px;
  font-size: 16px;
  text-align: right;
}

.popover .info {
  height: auto;
  padding: 5px 0px;
  margin-bottom: 15px;
}

.popover .info .imag {
  float: left;
  width: 80px;
  height: 80px;
  margin: 0 10px 0px 0px;
  border-radius: 100%;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
}

.popover .info .local {
  font-size: 16px;
  font-weight: bold;
  margin: 8px 0px -2px;
}

.popover .info .nome {
  width: auto;
  font-size: 28px;
  font-weight: bold;
}

.popover .info icon {
  float: left;
  width: 50px;
  text-align: center;
  font-size: 36px;
}

.popover .info span {
  display: block;
  font-size: 12px;
}

.popover .info span:nth-child(even) {
  font-family: 'AtlasG Medium';
}

.popover.right:after, .popover.right:before {
  content: '';
  position: absolute;
  left: -20px;
  top: 85px;
  height: 0;
  width: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.popover.right:before {
  border-right: 22px solid #000000;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  margin: -2px 0px 0px -2px;
  z-index: 100;
}

.popover.right:after {
  border-right: 20px solid #ffffff;
  z-index: 200;
}

.popover.left:after, .popover.left:before {
  content: '';
  position: absolute;
  right: -20px;
  top: 85px;
  height: 0;
  width: 0;
  border-top: 20px solid transparent;
  border-bottom: 20px solid transparent;
}

.popover.left:before {
  border-left: 22px solid #000000;
  border-top: 22px solid transparent;
  border-bottom: 22px solid transparent;
  margin: -2px -2px 0px 2px;
  z-index: 100;
}

.popover.left:after {
  border-left: 20px solid #ffffff;
  z-index: 200;
}

.contests-map #map {
  position: relative;
  height: 640px;
  background: #c6c6c6;
  padding: 0px;
}

.contests-map #map .area-info,
.contests-map #map .area-map {
  height: 100%;
  padding: 0px;
}

.contests-map #map .area-info {
  padding: 80px 40px;
}

.contests-map #map .area-info h2 {
  color: #666666;
  font-size: 20px;
  font-family: 'AtlasG Black';
}

.contests-map #map .area-info h4 {
  color: #666666;
  font-size: 13px;
  font-weight: bold;
  margin-bottom: 20px;
}

.contests-map #map .area-info .btn {
  position: relative;
  padding: 6px 0px 6px 44px;
  margin-bottom: 15px;
  color: #ffffff;
  font-size: 16px;
  cursor: pointer;
}

.contests-map #map .area-info .btn:before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 36px;
  height: 36px;
  margin: auto;
  border-radius: 100%;
  z-index: 1;
}

.contests-map #map .area-info .btn:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 12px;
  width: 12px;
  height: 12px;
  margin: auto;
  border-radius: 100%;
  z-index: 1;
  background-color: #c6c6c6;
  display: none;
}

.contests-map #map .area-info .btn.all:before {
  background-color: #000000;
}

.contests-map #map .area-info .btn.all.active {
  color: #000000;
}

.contests-map #map .area-info .btn.all.active:after {
  display: block;
}

.contests-map #map .area-info .btn.open:before {
  background-color: #7300fa;
}

.contests-map #map .area-info .btn.open.active {
  color: #7300fa;
}

.contests-map #map .area-info .btn.open.active:after {
  display: block;
}

.contests-map #map .area-info .btn.awarded:before {
  background-color: #550085;
}

.contests-map #map .area-info .btn.awarded.active {
  color: #550085;
}

.contests-map #map .area-info .btn.awarded.active:after {
  display: block;
}

.contests-map #map .area-info .btn.other:before {
  background-color: #39085a;
}

.contests-map #map .area-info .btn.other.active {
  color: #39085a;
}

.contests-map #map .area-info .btn.other.active:after {
  display: block;
}

.contests-map #map .area-infowindow .ballon-close {
  width: 16px !important;
  height: 16px !important;
  opacity: 1 !important;
}

.contests-map #map .area-infowindow .ballon-close img {
  display: none;
}

.contests-map #map .area-infowindow .ballon-close:before {
  content: '\f00d';
  color: #000000;
  font-family: 'FontAwesome';
  font-size: 18px;
  text-align: center;
}

.contests-map #map .area-infowindow .ballon-conten-info {
  width: 360px;
}

.contests-map #map .area-infowindow .ballon-conten-info h4 {
  float: left;
  width: 100%;
  font-family: 'AtlasT Regular';
  font-size: 12px;
}

.contests-map #map .area-infowindow .ballon-conten-info h2 {
  float: left;
  width: 100%;
  font-family: 'AtlasG Medium';
  font-size: 18px;
  line-height: 20px;
}

.contests-map #map .area-infowindow .ballon-conten-info p {
  float: left;
  width: 100%;
  font-family: 'AtlasT Regular';
  font-size: 12px;
  padding: 5px 0px;
}

.contests-map #map .area-infowindow .ballon-conten-info p span {
  font-family: 'AtlasG Regular';
  font-weight: bold;
}

.contests-map #map .area-infowindow .ballon-conten-info ul {
  float: left;
  width: 100%;
  margin: 10px 0px;
}

.contests-map #map .area-infowindow .ballon-conten-info ul li {
  float: left;
  width: 100%;
  padding: 2px 0px;
  font-size: 13px;
  font-family: 'AtlasG Regular';
}

.contests-map #map .area-infowindow .ballon-conten-info ul li span {
  float: left;
  width: 80px;
  font-weight: bold;
}

.contests-map #map .area-infowindow .ballon-conten-info a {
  float: right;
  font-family: 'AtlasG Regular';
  font-size: 16px;
}

.contests-map #map .area-infowindow.open .ballon-close:hover:before {
  color: #7300fa;
}

.contests-map #map .area-infowindow.open .ballon-conten-info h2,
.contests-map #map .area-infowindow.open .ballon-conten-info h4,
.contests-map #map .area-infowindow.open .ballon-conten-info p,
.contests-map #map .area-infowindow.open .ballon-conten-info a:hover {
  color: #7300fa;
}

.contests-map #map .area-infowindow.awarded .ballon-close:hover:before {
  color: #550085;
}

.contests-map #map .area-infowindow.awarded .ballon-conten-info h2,
.contests-map #map .area-infowindow.awarded .ballon-conten-info h4,
.contests-map #map .area-infowindow.awarded .ballon-conten-info p,
.contests-map #map .area-infowindow.awarded .ballon-conten-info a:hover {
  color: #550085;
}

.contests-map #map .area-infowindow.other .ballon-close:hover:before {
  color: #39085a;
}

.contests-map #map .area-infowindow.other .ballon-conten-info h2,
.contests-map #map .area-infowindow.other .ballon-conten-info h4,
.contests-map #map .area-infowindow.other .ballon-conten-info p,
.contests-map #map .area-infowindow.other .ballon-conten-info a:hover {
  color: #39085a;
}

.contests-map #mapoasrs {
  float: left;
  width: 100%;
  height: 100%;
}

@media (max-width: 1199px) {
  .contests-map #map .area-info {
    padding: 80px 20px;
  }
}

@media (max-width: 767px) {
  .contests-map #map {
    height: auto;
  }
  .contests-map #map .area-info {
    padding: 20px 10px;
  }
  .contests-map #map .area-map {
    height: 380px;
  }
}

.contest-title {
  margin: 40px 0 0;
}

.phase-title {
  margin: 20px 0 30px;
}

.phase-title h3 {
  font-size: 30px;
}

.category-editing {
  margin: 63px 0 0;
  text-align: right;
}

.toggle-submission-comments .toggle-comments-show {
  display: inline-block;
}

.toggle-submission-comments .toggle-comments-hide {
  display: none;
}

.toggle-submission-comments.showing .toggle-comments-show {
  display: none;
}

.toggle-submission-comments.showing .toggle-comments-hide {
  display: inline-block;
}

.caption {
  margin-bottom: 20px;
}

.caption .titulo {
  font-size: 30px;
  font-family: 'AtlasG Black';
}

.caption .fase {
  font-size: 14px;
}

.div-tabela {
  display: block;
  width: 100%;
  overflow: auto;
  margin: 20px 0;
}

.div-tabela table {
  width: 100%;
  margin-bottom: 15px;
}

.div-tabela table tr {
  position: relative;
}

.div-tabela table tr:first-child td {
  padding-top: 15px;
}

.div-tabela table th {
  min-width: 80px;
  height: 60px;
  font-size: 14px;
  padding: 20px 10px 0 0;
  border-bottom: 2px solid #cdcdcd;
}

.div-tabela table th:last-child {
  min-width: 80px;
}

.div-tabela table th.first-row {
  width: 320px;
  min-width: 320px;
  max-width: 320px;
}

.div-tabela table th .titulo {
  display: block;
  font-size: 26px;
  text-align: left;
  font-family: 'AtlasG Black';
}

.div-tabela table th small {
  display: block;
  font-size: 12px;
  font-weight: normal;
  margin: 2px 0 6px;
}

.div-tabela table td {
  min-width: 80px;
  padding-right: 10px;
}

.div-tabela table td:last-child {
  min-width: 80px;
}

.div-tabela table td.first-row {
  width: 320px;
  min-width: 320px;
  max-width: 320px;
  padding: 2px 10px;
}

.div-tabela table td .titulo {
  display: inline-block;
  width: 200px;
  vertical-align: middle;
  font-size: 16px;
  font-family: 'AtlasG Black';
}

.div-tabela table td .important {
  font-weight: bold;
  color: #000;
}

.div-tabela table td .faded {
  color: #ccc;
}

.div-tabela table td.center {
  text-align: center;
}

.div-tabela table .criterion {
  position: relative;
  cursor: pointer;
}

.div-tabela table .popover {
  top: inherit !important;
  margin-top: -80px;
}

.div-tabela table .popover.right {
  left: inherit !important;
  margin-left: 80px;
}

.div-tabela table .popover h5 {
  color: #000;
  font-family: 'AtlasG Black';
  font-size: 28px;
  margin-bottom: 20px;
}

.div-tabela table .popover h6 {
  color: #000;
  font-family: 'AtlasG Black';
  font-size: 16px;
  margin-bottom: 10px;
}

.div-tabela table .popover.right:after, .div-tabela table .popover.right:before, .div-tabela table .popover.left:after, .div-tabela table .popover.left:before {
  top: 40px;
}

.div-tabela table .info-box {
  display: none;
}

.div-tabela table .imag {
  display: inline-block;
  width: 55px;
  height: 55px;
  margin-right: 10px;
  border-radius: 3px;
  background-color: #d7d7d7;
  background-size: cover;
  vertical-align: middle;
}

.div-tabela table .valor {
  font-size: 14px;
  color: #7e7e7e;
}

.div-tabela table input.valor {
  color: #000;
  border: none;
  width: 100%;
  padding: 5px 0;
}

.div-tabela table input.error {
  color: red;
}

.div-agenda {
  margin-bottom: 60px;
}

.div-agenda .data {
  position: relative;
  margin: 5px 0px;
  font-size: 16px;
  text-align: center;
}

.div-agenda .data .item {
  width: 100%;
  padding: 10px;
  border: 2px solid #000;
  cursor: pointer;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

.div-agenda .data .item icon {
  width: 20px;
  margin: 0px 5px;
  font-size: 20px;
}

.div-agenda .data .item icon:before {
  content: "\f00d";
}

.div-agenda .data .item.active icon:before {
  content: "\f00c";
}

.div-agenda .data .item:hover {
  color: #7300fa;
}

.div-equipa {
  margin-bottom: 60px;
}

.div-equipa .membro {
  position: relative;
  float: left;
  margin: 10px 25px 10px 5px;
}

.div-equipa .membro .type {
  color: #7f7f7f;
  font-size: 12px;
}

.div-equipa .membro .name {
  color: #000000;
  font-size: 18px;
  font-weight: bold;
}

.div-equipa .membro .phone {
  color: #7f7f7f;
  font-size: 14px;
}

.div-equipa .membro .mail {
  color: #7f7f7f;
  font-size: 14px;
}

.div-equipa .membro span {
  display: block;
  word-wrap: inherit;
}

.div-obs {
  margin-bottom: 25px;
  overflow: hidden;
}

.div-action {
  padding: 40px 0px;
}

.div-action .btn-action {
  display: table;
  padding: 10px 0px;
  font-size: 40px;
  line-height: 26px;
  cursor: pointer;
}

.div-avaliar {
  float: right;
}

.div-cancel {
  float: left;
}

.div-projecto .imag img {
  font-size: 0;
  width: 100%;
}

.div-projecto .info {
  position: relative;
  padding: 0px 40px 60px;
}

.div-projecto .info p {
  float: left;
  width: 100%;
  margin: 0px 0px 30px;
  font-size: 14px;
  line-height: 24px;
}

.div-projecto .download {
  position: absolute;
  width: 50%;
  right: 10px;
  bottom: 42px;
  margin-bottom: 0;
  padding: 0px 40px;
}

.div-projecto .download icon {
  float: left;
  width: 50px;
  margin-top: 6px;
  text-align: center;
  font-size: 36px;
}

.div-projecto .download span {
  display: block;
  font-size: 16px;
}

.div-projecto .download .doc-name {
  font-size: 12px;
}

.div-projecto.simple .download {
  left: 0;
  padding: 0;
}

.submission-detail {
  margin-bottom: 40px;
}

.distinctions {
  margin-bottom: 15px;
}

.disqualified-td {
  padding-bottom: 15px;
}

.contest-finish-report .finish-report-title {
  text-align: center;
}

.contest-finish-report .finish-report-title h2 {
  font-size: 56px;
  margin-bottom: 0;
}

.contest-finish-report .finish-report-title p {
  font-size: 30px;
  margin-bottom: 40px;
}

.contest-finish-report h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.contest-finish-report .form-group {
  margin-bottom: 30px;
}

.contest-finish-report .download-report {
  margin-bottom: 35px;
}

.contest-finish-report .fileupload-group {
  margin-bottom: 10px;
}

.contest-finish-report .report-alert {
  margin-bottom: 15px;
}

.toggable-comments {
  display: none;
  border-bottom: 1px solid #cdcdcd;
}

.toggable-comments td {
  padding: 10px 0;
}

.with-comments .toggable-comments {
  display: table-row;
}

.juri-override td {
  padding-top: 10px;
}

.meeting-dates-wrapper .new-date-wrapper .plus {
  position: absolute;
  right: 30px;
  top: 26px;
}

.meeting-dates-wrapper .fixed-date {
  margin: 15px 0 40px;
}

.meeting-dates-wrapper .fixed-date .meeting-date-text {
  font-size: 24px;
}

.meeting-dates-wrapper .fixed-date icon {
  font-size: 36px;
}

.meeting-dates-wrapper .reset-date {
  display: block;
  margin: 10px 0 0;
}

.meeting-dates-wrapper .meeting-time {
  display: none;
}

.meeting-dates-wrapper .scheduling-cta,
.meeting-dates-wrapper .dates-box {
  display: block;
}

.meeting-dates-wrapper.scheduled .meeting-time {
  display: block;
}

.meeting-dates-wrapper.scheduled .scheduling-cta,
.meeting-dates-wrapper.scheduled .dates-box {
  display: none;
}

#ui-datepicker-div {
  z-index: 999 !important;
}

.ui-datepicker {
  width: 280px;
  height: auto;
  padding: 0px 8px;
  background-color: #fff;
  margin-top: -24px;
  border: 2px solid #000;
  z-index: 10 !important;
  display: none;
}

.ui-datepicker-header {
  height: 38px;
  background-color: #fff;
  font-family: 'AtlasG Black';
}

.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 6px;
  width: 20px;
  height: 24px;
  text-align: center;
  cursor: pointer;
}

.ui-datepicker .ui-datepicker-prev:before,
.ui-datepicker .ui-datepicker-next:before {
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
}

.ui-datepicker .ui-datepicker-prev {
  left: 8px;
}

.ui-datepicker .ui-datepicker-prev:before {
  content: "\f053";
}

.ui-datepicker .ui-datepicker-next {
  right: 8px;
}

.ui-datepicker .ui-datepicker-next:before {
  content: "\f054";
}

.ui-datepicker-title {
  color: #000000;
  padding-top: 6px;
  text-align: center;
}

.ui-datepicker-calendar {
  width: 100%;
  height: 160px;
  text-align: center;
  margin-bottom: 6px;
}

.ui-datepicker-calendar thead {
  color: #000000;
  font-size: 16px;
  font-family: 'AtlasG Regular', sans-serif;
  font-weight: bold;
}

.ui-datepicker-calendar thead th {
  text-align: center;
}

.ui-datepicker-calendar tbody a {
  color: #000000;
  font-size: 16px;
  font-family: 'AtlasG Regular', sans-serif;
}

.ui-state-default:hover {
  color: #7300fa;
}

.ui-datepicker-calendar .ui-state-active {
  color: #7300fa;
  font-weight: bold;
}

.ui-timepicker-div {
  padding: 6px 0px;
  border-top: 2px solid #000;
  border-bottom: 2px solid #000;
}

.ui-timepicker-div dt {
  font-weight: bold;
}

.ui_tpicker_time_input {
  border: none !important;
}

.ui-timepicker-select {
  width: 100%;
}

.ui-datepicker-buttonpane .ui-datepicker-current {
  float: left;
  font-size: 20px;
  padding: 6px;
}

.ui-datepicker-buttonpane .ui-datepicker-close {
  float: right;
  font-size: 20px;
  padding: 6px;
}

.ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}

/*/////////////////////////////////////////////////////////////////////////////////////////// NORMALISE */
html, body, div, pre, form, fieldset, input, h1, h2, h3, h4, h5, h6, p, textarea, ul, ol, li, dl, dt, dd, blockquote, th, td,
abbr, article, aside, audio, canvas, details, figcaption, figure, footer, header, hgroup,
mark, menu, meter, nav, output, progress, section, summary, time, video {
  margin: 0;
  padding: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section, main {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

fieldset, img {
  border: 0;
}

address, caption, cite, code {
  font-style: normal;
  font-weight: normal;
}

ul {
  list-style: none;
}

caption, th {
  text-align: left;
}

h1, h2, h3, h4, h5, h6 {
  font-size: 100%;
  font-weight: normal;
}

q:before, q:after {
  content: '';
}

abbr, acronym {
  border: 0;
}

a {
  outline: none;
  text-decoration: none;
  color: #000000;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

a img {
  border: none;
}

a:hover {
  color: #7300fa;
}

:focus {
  outline: none;
}

/* For Chrome */
*, *:before, *:after {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

body, html {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 320px;
  margin: auto;
  background: #ffffff;
  color: #000000;
  font-family: 'AtlasG Regular', sans-serif;
  font-size: 100%;
}

body.no-scroll {
  overflow: hidden;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.clearfix:before, .clearfix:after {
  display: table;
  content: " ";
}

.clearfix:after {
  clear: both;
}

.grecaptcha-badge {
  display: none !important;
}

.action:hover {
  color: #7300fa;
}

main {
  min-height: 100%;
  padding-top: 170px;
  padding-bottom: 170px;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  z-index: 2;
}

@media (max-width: 991px) {
  main {
    padding-top: 140px;
  }
}

@media (max-width: 767px) {
  main {
    padding-top: 60px;
  }
}

@media (max-width: 767px) {
  main {
    padding-bottom: 140px;
  }
}

main.fixed {
  padding-top: 90px;
}

@media (max-width: 767px) {
  main.fixed {
    padding-top: 60px;
  }
}

.mini-header + main {
  padding-top: 0px;
}

section {
  filter: alpha(opacity=100);
  opacity: 1;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

section.show {
  filter: alpha(opacity=100);
  opacity: 1;
}

.container.right-nav {
  padding-right: 80px;
}

button {
  border: 0px;
  background: none;
  font-size: 40px;
  cursor: pointer;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

button:hover {
  color: #7300fa;
}

a.btn, button.btn {
  outline: 0;
  padding: 0;
  border: 0;
}

a.btn:hover, button.btn:hover {
  color: #7300fa;
}

.backgrd, .showcase-detail #billhead .info .imag,
.showcase-project-detail #billhead .info .imag, footer .logo a, .home #map, .thumbnail .imag {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.cor {
  color: #7300fa;
}

.fade {
  color: #7f7f7f;
}

p > a {
  text-decoration: underline;
}

.help-block {
  margin: 3px 0 15px 0;
  font-size: 12px;
}

.help-block.error, .form-group.has-error .help-block {
  color: red;
}

.tipografia h1, .tipografia h2, .tipografia h3, .tipografia h4, .tipografia h5, .tipografia h6,
.tipografia .h1, .tipografia .h2, .tipografia .h3, .tipografia .h4, .tipografia .h5, .tipografia .h6 {
  font-family: inherit;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

.tipografia h1 small,
.tipografia h1 .small, .tipografia h2 small,
.tipografia h2 .small, .tipografia h3 small,
.tipografia h3 .small, .tipografia h4 small,
.tipografia h4 .small, .tipografia h5 small,
.tipografia h5 .small, .tipografia h6 small,
.tipografia h6 .small,
.tipografia .h1 small,
.tipografia .h1 .small, .tipografia .h2 small,
.tipografia .h2 .small, .tipografia .h3 small,
.tipografia .h3 .small, .tipografia .h4 small,
.tipografia .h4 .small, .tipografia .h5 small,
.tipografia .h5 .small, .tipografia .h6 small,
.tipografia .h6 .small {
  font-weight: 400;
  line-height: 1;
  color: #777777;
}

.tipografia h1, .tipografia .h1,
.tipografia h2, .tipografia .h2,
.tipografia h3, .tipografia .h3 {
  margin-top: 20px;
  margin-bottom: 10px;
}

.tipografia h1 small,
.tipografia h1 .small, .tipografia .h1 small,
.tipografia .h1 .small,
.tipografia h2 small,
.tipografia h2 .small, .tipografia .h2 small,
.tipografia .h2 .small,
.tipografia h3 small,
.tipografia h3 .small, .tipografia .h3 small,
.tipografia .h3 .small {
  font-size: 65%;
}

.tipografia h4, .tipografia .h4,
.tipografia h5, .tipografia .h5,
.tipografia h6, .tipografia .h6 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.tipografia h4 small,
.tipografia h4 .small, .tipografia .h4 small,
.tipografia .h4 .small,
.tipografia h5 small,
.tipografia h5 .small, .tipografia .h5 small,
.tipografia .h5 .small,
.tipografia h6 small,
.tipografia h6 .small, .tipografia .h6 small,
.tipografia .h6 .small {
  font-size: 75%;
}

.tipografia h1, .tipografia .h1 {
  font-size: 36px;
}

.tipografia h2, .tipografia .h2 {
  font-size: 30px;
}

.tipografia h3, .tipografia .h3 {
  font-size: 24px;
}

.tipografia h4, .tipografia .h4 {
  font-size: 18px;
}

.tipografia h5, .tipografia .h5 {
  font-size: 14px;
}

.tipografia h6, .tipografia .h6 {
  font-size: 12px;
}

.tipografia p {
  margin: 0 0 10px;
}

.tipografia .lead {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 300;
  line-height: 1.4;
}

@media (min-width: 768px) {
  .tipografia .lead {
    font-size: 21px;
  }
}

.tipografia small,
.tipografia .small {
  font-size: 85%;
}

.tipografia mark,
.tipografia .mark {
  padding: .2em;
  background-color: #fcf8e3;
}

.tipografia .text-left {
  text-align: left;
}

.tipografia .text-right {
  text-align: right;
}

.tipografia .text-center {
  text-align: center;
}

.tipografia .text-justify {
  text-align: justify;
}

.tipografia .text-nowrap {
  white-space: nowrap;
}

.tipografia .text-lowercase {
  text-transform: lowercase;
}

.tipografia .text-uppercase, .tipografia .initialism {
  text-transform: uppercase;
}

.tipografia .text-capitalize {
  text-transform: capitalize;
}

.tipografia .text-muted {
  color: #777777;
}

.tipografia .text-primary {
  color: #337ab7;
}

.tipografia a.text-primary:hover,
.tipografia a.text-primary:focus {
  color: #286090;
}

.tipografia .text-success {
  color: #3c763d;
}

.tipografia a.text-success:hover,
.tipografia a.text-success:focus {
  color: #2b542c;
}

.tipografia .text-info {
  color: #31708f;
}

.tipografia a.text-info:hover,
.tipografia a.text-info:focus {
  color: #245269;
}

.tipografia .text-warning {
  color: #8a6d3b;
}

.tipografia a.text-warning:hover,
.tipografia a.text-warning:focus {
  color: #66512c;
}

.tipografia .text-danger {
  color: #a94442;
}

.tipografia a.text-danger:hover,
.tipografia a.text-danger:focus {
  color: #843534;
}

.tipografia .bg-primary {
  color: #fff;
}

.tipografia .bg-primary {
  background-color: #337ab7;
}

.tipografia a.bg-primary:hover,
.tipografia a.bg-primary:focus {
  background-color: #286090;
}

.tipografia .bg-success {
  background-color: #dff0d8;
}

.tipografia a.bg-success:hover,
.tipografia a.bg-success:focus {
  background-color: #c1e2b3;
}

.tipografia .bg-info {
  background-color: #d9edf7;
}

.tipografia a.bg-info:hover,
.tipografia a.bg-info:focus {
  background-color: #afd9ee;
}

.tipografia .bg-warning {
  background-color: #fcf8e3;
}

.tipografia a.bg-warning:hover,
.tipografia a.bg-warning:focus {
  background-color: #f7ecb5;
}

.tipografia .bg-danger {
  background-color: #f2dede;
}

.tipografia a.bg-danger:hover,
.tipografia a.bg-danger:focus {
  background-color: #e4b9b9;
}

.tipografia .page-header {
  padding-bottom: 9px;
  margin: 40px 0 20px;
  border-bottom: 1px solid #eeeeee;
}

.tipografia ul,
.tipografia ol {
  margin-top: 0;
  margin-bottom: 10px;
}

.tipografia ul ul,
.tipografia ul ol,
.tipografia ol ul,
.tipografia ol ol {
  margin-bottom: 0;
}

.tipografia .list-unstyled {
  padding-left: 0;
  list-style: none;
}

.tipografia .list-inline {
  padding-left: 0;
  list-style: none;
  margin-left: -5px;
}

.tipografia .list-inline > li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}

.tipografia dl {
  margin-top: 0;
  margin-bottom: 20px;
}

.tipografia dt,
.tipografia dd {
  line-height: 1.428571429;
}

.tipografia dt {
  font-weight: 700;
}

.tipografia dd {
  margin-left: 0;
}

.tipografia .dl-horizontal dd:before, .tipografia .dl-horizontal dd:after {
  display: table;
  content: " ";
}

.tipografia .dl-horizontal dd:after {
  clear: both;
}

@media (min-width: 768px) {
  .tipografia .dl-horizontal dt {
    float: left;
    width: 160px;
    clear: left;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .tipografia .dl-horizontal dd {
    margin-left: 180px;
  }
}

.tipografia abbr[title],
.tipografia abbr[data-original-title] {
  cursor: help;
}

.tipografia .initialism {
  font-size: 90%;
}

.tipografia blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 17.5px;
  border-left: 5px solid #eeeeee;
}

.tipografia blockquote p:last-child,
.tipografia blockquote ul:last-child,
.tipografia blockquote ol:last-child {
  margin-bottom: 0;
}

.tipografia blockquote footer,
.tipografia blockquote small,
.tipografia blockquote .small {
  display: block;
  font-size: 80%;
  line-height: 1.428571429;
  color: #777777;
}

.tipografia blockquote footer:before,
.tipografia blockquote small:before,
.tipografia blockquote .small:before {
  content: "\2014 \00A0";
}

.tipografia .blockquote-reverse,
.tipografia blockquote.pull-right {
  padding-right: 15px;
  padding-left: 0;
  text-align: right;
  border-right: 5px solid #eeeeee;
  border-left: 0;
}

.tipografia .blockquote-reverse footer:before,
.tipografia .blockquote-reverse small:before,
.tipografia .blockquote-reverse .small:before,
.tipografia blockquote.pull-right footer:before,
.tipografia blockquote.pull-right small:before,
.tipografia blockquote.pull-right .small:before {
  content: "";
}

.tipografia .blockquote-reverse footer:after,
.tipografia .blockquote-reverse small:after,
.tipografia .blockquote-reverse .small:after,
.tipografia blockquote.pull-right footer:after,
.tipografia blockquote.pull-right small:after,
.tipografia blockquote.pull-right .small:after {
  content: "\00A0 \2014";
}

.tipografia address {
  margin-bottom: 20px;
  font-style: normal;
  line-height: 1.428571429;
}

.tipografia ol {
  margin-left: 25px;
}

.tag-box {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}

.tag-box li {
  float: left;
  padding: 14px 30px;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 2px solid #000000;
  font-size: 14px;
  line-height: 12px;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

.tag-box li icon {
  margin-left: 5px;
}

.tag-box li:hover {
  color: #7300fa;
  cursor: pointer;
}

/*/////////////////////////////////////////////////////////////////////////////////////////// HEADER */
header {
  position: fixed;
  height: 170px;
  background: #ffffff;
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-top: 40px;
  z-index: 8;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

@media (max-width: 991px) {
  header {
    height: 140px;
  }
}

@media (max-width: 767px) {
  header {
    height: 60px !important;
  }
}

header .center {
  max-width: 1150px;
  position: absolute;
  left: 0px;
  right: 0px;
  width: 100%;
  margin: auto;
  padding: 0px 5px;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

header .center .active {
  color: #7300fa;
}

header nav .header-titulo {
  float: left;
  -webkit-transition: 10ms ease-in-out;
  -o-transition: 10ms ease-in-out;
  transition: 10ms ease-in-out;
}

header nav .header-titulo span {
  display: block;
  max-height: 50px;
  font-family: 'AtlasT Regular';
  font-size: 16px;
  overflow: hidden;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

header nav .header-titulo h2 {
  font-family: 'AtlasG Black';
  font-size: 32px;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

header nav .header-login {
  float: right;
  margin-top: 0px;
  max-height: 45px;
  position: relative;
  right: 5px;
}

header nav .header-login .lang {
  display: inline-block;
  /*display: none;*/
  vertical-align: top;
  margin: 0px 5px;
}

header nav .header-login .btn-login,
header nav .header-login .btn-logout,
header nav .header-login .btn-alerta {
  display: inline-block;
  position: relative;
  margin: 0px 5px;
  cursor: pointer;
}

header nav .header-login .btn-login span,
header nav .header-login .btn-logout span,
header nav .header-login .btn-alerta span {
  display: inline-block;
  max-width: 130px;
  margin: 0px 5px;
  font-size: 14px;
  line-height: 14px;
  text-align: right;
  vertical-align: middle;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

header nav .header-login .btn-login icon,
header nav .header-login .btn-logout icon,
header nav .header-login .btn-alerta icon {
  font-size: 36px;
  vertical-align: middle;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

header nav .header-login .btn-login:hover,
header nav .header-login .btn-logout:hover,
header nav .header-login .btn-alerta:hover {
  color: #7300fa;
}

header nav .header-login .btn-login:hover icon,
header nav .header-login .btn-logout:hover icon,
header nav .header-login .btn-alerta:hover icon {
  color: #7300fa;
}

header nav .header-login .btn-logout {
  cursor: default;
  overflow: hidden;
}

header nav .header-login .btn-logout span {
  color: #7300fa;
}

header nav .header-login .btn-logout span small {
  color: #000000;
  font-size: 12px;
}

header nav .header-login .btn-alerta {
  float: right;
}

header nav .header-login .btn-alerta icon.fa-bell {
  filter: alpha(opacity=100);
  opacity: 1;
}

header nav .header-login .btn-alerta icon.fa-times-circle {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
  filter: alpha(opacity=0);
  opacity: 0;
}

header nav .header-login .btn-alerta .ball {
  position: absolute;
  right: -15px;
  bottom: -5px;
  border-radius: 20px;
  width: auto;
  text-align: center;
  background: #000000;
  color: white;
  border: 2px solid white;
  font-size: 12px;
  padding: 2px 7px;
  line-height: 18px;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

header nav .header-login .btn-alerta:hover .ball {
  background: #7300fa;
}

header nav menu {
  position: absolute;
  top: 60px;
  right: 0;
  min-width: 0;
  width: auto;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

header nav menu ul {
  display: table;
  margin: auto;
}

header nav menu ul li {
  float: left;
  margin: 0px 10px;
  font-size: 20px;
}

header nav menu ul li a:hover {
  color: #7300fa;
}

header nav menu ul li a.active {
  color: #7300fa;
  font-weight: bold;
}

header nav .burger {
  position: absolute;
  right: 10px;
  width: 34px;
  height: 22px;
  margin-top: 2px;
  z-index: 2;
  cursor: pointer;
}

header nav .burger span {
  float: left;
  width: 34px;
  height: 5px;
  margin: 5px 0px;
  background-color: #000000;
  -webkit-animation: close_burg_samp 250ms forwards ease-in-out;
  -o-animation: close_burg_samp 250ms forwards ease-in-out;
  animation: close_burg_samp 250ms forwards ease-in-out;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  border-radius: 2px;
}

header nav .burger:before {
  content: '';
  float: left;
  width: 34px;
  height: 5px;
  background-color: #000000;
  -webkit-animation: close_burg_before 250ms forwards ease-in-out;
  -o-animation: close_burg_before 250ms forwards ease-in-out;
  animation: close_burg_before 250ms forwards ease-in-out;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  border-radius: 2px;
}

header nav .burger:after {
  content: '';
  float: left;
  width: 34px;
  height: 5px;
  background-color: #000000;
  -webkit-animation: close_burg_after 250ms forwards ease-in-out;
  -o-animation: close_burg_after 250ms forwards ease-in-out;
  animation: close_burg_after 250ms forwards ease-in-out;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  border-radius: 2px;
}

header .box-alerta {
  position: fixed;
  top: 0;
  right: -360px;
  width: 328px;
  height: 100%;
  background: #ffffff;
  border-left: 2px solid #000000;
  z-index: 10;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

header .box-alerta:after, header .box-alerta:before {
  content: '';
  position: absolute;
  left: -30px;
  top: 28px;
  height: 0;
  width: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
}

header .box-alerta:before {
  border-right: 32px solid #000000;
  border-top: 32px solid transparent;
  border-bottom: 32px solid transparent;
  margin: -2px 0px 0px -2px;
  z-index: 1;
}

header .box-alerta:after {
  border-right: 30px solid #ffffff;
  z-index: 2;
}

header.fixed, header.mini-header {
  height: 90px;
  padding-top: 34px;
}

header.fixed .header-titulo span, header.mini-header .header-titulo span {
  max-height: 0px;
}

header.fixed .header-titulo h2, header.mini-header .header-titulo h2 {
  font-size: 18px;
}

header.fixed .header-login, header.mini-header .header-login {
  float: right;
  margin-top: -6px;
}

header.fixed menu, header.mini-header menu {
  top: 0px;
  right: 230px;
}

header.fixed menu ul li, header.mini-header menu ul li {
  font-size: 18px;
}

header.fixed .box-alerta:after, header.fixed .box-alerta:before, header.mini-header .box-alerta:after, header.mini-header .box-alerta:before {
  top: 16px;
}

header.mini-header {
  position: relative;
  height: 80px;
  padding-top: 28px;
  background-color: transparent;
}

header.mini-header .logout {
  position: absolute;
  left: 0;
  right: 0;
  width: 80px;
  margin: auto;
  text-align: center;
}

header.mini-header .back-home {
  float: right;
}

header.mini-header .back-home icon {
  font-size: 36px;
  vertical-align: middle;
}

header.open-alert nav .header-login .btn-alerta icon.fa-bell, header.open-alert nav .header-login .btn-alerta .ball {
  filter: alpha(opacity=0);
  opacity: 0;
}

header.open-alert nav .header-login .btn-alerta icon.fa-times-circle {
  filter: alpha(opacity=100);
  opacity: 1;
}

header.open-alert .box-alerta {
  right: 0;
}

header.open-menu .burger span {
  -webkit-animation: open_burg_samp 250ms forwards ease-in-out;
  -o-animation: open_burg_samp 250ms forwards ease-in-out;
  animation: open_burg_samp 250ms forwards ease-in-out;
}

header.open-menu .burger:before {
  -webkit-animation: open_burg_before 250ms forwards ease-in-out;
  -o-animation: open_burg_before 250ms forwards ease-in-out;
  animation: open_burg_before 250ms forwards ease-in-out;
}

header.open-menu .burger:after {
  -webkit-animation: open_burg_after 250ms forwards ease-in-out;
  -o-animation: open_burg_after 250ms forwards ease-in-out;
  animation: open_burg_after 250ms forwards ease-in-out;
}

@media (max-width: 1850px) {
  header.open-alert .center {
    left: inherit;
    right: 350px;
  }
}

@media (max-width: 1199px) {
  header {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
  header .center {
    margin-left: 0px;
  }
  header nav .header-titulo h2 {
    font-size: 30px;
  }
  header nav menu ul li {
    float: left;
    margin: 0px 8px;
    font-size: 18px;
  }
  header.fixed .header-titulo h2 {
    font-size: 16px;
  }
  header.fixed menu ul li {
    font-size: 16px;
  }
  header.open-alert .center {
    left: 0px;
    right: 0px;
    margin-left: -350px;
  }
}

@media (max-width: 991px) {
  header {
    padding-top: 34px;
  }
  header nav .header-titulo span {
    font-size: 12px;
  }
  header nav .header-titulo h2 {
    font-size: 26px;
  }
  header nav .header-login {
    max-height: 0;
    filter: alpha(opacity=0);
    opacity: 0;
  }
  header nav menu {
    top: 0px;
  }
  header nav menu ul li {
    font-size: 16px;
  }
  header.fixed menu {
    top: 0px;
    right: 0px;
  }
  header.open-alert .center {
    margin-left: 0px;
  }
}

@media (max-width: 767px) {
  header {
    padding-top: 16px !important;
  }
  header nav .header-titulo {
    position: relative;
    z-index: 1;
  }
  header nav .header-titulo span {
    font-size: 0px;
  }
  header nav .header-titulo h2 {
    font-size: 16px !important;
  }
  header nav menu {
    position: fixed;
    left: -100%;
    width: 250px;
    height: 100%;
    background-color: #ffffff;
    z-index: 1;
    padding-top: 60px;
    z-index: 0;
  }
  header nav menu ul {
    width: 100%;
    height: 100%;
  }
  header nav menu ul li {
    display: table;
    width: 100%;
    height: 14%;
    padding: 0px 20px;
    margin: 0;
    font-size: 30px !important;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }
  header nav menu ul li a {
    display: table-cell;
    vertical-align: middle;
  }
  header.open-menu menu {
    left: 0%;
  }
}

/*/////////////////////////////////////////////////////////////////////////////////////////// LOGIN HOME */
header .box-login {
  position: absolute;
  left: 0;
  top: -800px;
  width: 100%;
  height: auto;
  padding: 80px 0px;
  background: #ffffff;
  z-index: 4;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

header .box-login .close {
  position: absolute;
  top: -60px;
  right: 0px;
  font-size: 36px;
  cursor: pointer;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

header .box-login .close:hover {
  color: #7300fa;
}

header .box-login h2 {
  font-size: 60px;
  margin-bottom: 20px;
}

header .box-login p {
  font-size: 18px;
  margin-bottom: 40px;
}

header .box-login .login {
  height: 480px;
  border-right: 1px solid #000000;
  padding: 0px 60px;
}

header .box-login .login .btn-send {
  position: absolute;
  bottom: 0px;
  right: 60px;
}

header .box-login .login .btn-send:hover {
  color: #7300fa;
}

header .box-login .login .btn-send icon {
  font-size: 30px;
}

header .box-login .login .btn-forgot {
  position: relative;
  display: table;
  bottom: 0px;
  width: 65%;
  height: 48px;
  font-size: 16px;
}

header .box-login .login .btn-forgot a {
  display: table-cell;
  vertical-align: middle;
}

header .box-login .register {
  height: 480px;
  border-left: 1px solid #000000;
  padding: 0px 60px;
}

header .box-login .register .btn-send {
  position: absolute;
  bottom: 0px;
  right: 60px;
  font-size: 40px;
}

header .box-login .register .btn-send:hover {
  color: #7300fa;
}

header .box-login .register .btn-send icon {
  font-size: 30px;
}

header .box-login .register .disclaimer {
  float: left;
  color: #989898;
  font-size: 14px;
}

header .box-login.show {
  top: 0;
}

@media (max-width: 1199px) {
  header .box-login .login .btn-send {
    margin-right: -20px;
  }
  header .box-login .login .btn-forgot {
    margin-left: -20px;
  }
}

@media (max-width: 991px) {
  header .box-login {
    display: none;
  }
}

.content-box {
  padding: 18px;
  border: 2px solid #000000;
  font-size: 18px;
  line-height: 26px;
  float: left;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}

.content-box button {
  font-size: 18px;
}

.content-box.danger {
  border-color: red;
  color: red;
}

/*/////////////////////////////////////////////////////////////////////////////////////////// FORMS */
.form {
  float: left;
  text-align: left;
}

.form-control:focus {
  outline: 0;
}

.form-group {
  float: left;
  position: relative;
  width: 100%;
  border: 2px solid #000000;
  margin-bottom: 10px;
  padding: 20px 30px;
  overflow: hidden;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
  text-align: left;
}

.form-group small {
  font-size: 12px;
  line-height: 12px;
}

.form-group label {
  display: block;
  width: 100%;
  height: 24px;
  color: #000000;
  font-family: 'AtlasG Medium';
  font-size: 16px;
}

.form-group input,
.form-group select,
.form-group textarea {
  display: block;
  position: relative;
  width: 100%;
  padding: 0 40px 0 0;
  height: 30px;
  line-height: 30px;
  border: none;
  background-color: transparent !important;
  color: #000000;
  font-family: 'AtlasG Regular';
  font-size: 16px;
  box-shadow: none;
  outline: 0;
}

.form-group input:-webkit-autofill,
.form-group select:-webkit-autofill,
.form-group textarea:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}

.form-group select {
  height: 60px;
  margin: -15px 0px;
  padding: 0px;
  z-index: 2;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-indent: 0.01px;
  text-overflow: '';
}

.form-group select::-ms-expand {
  display: none;
}

.form-group textarea {
  min-height: 130px;
  resize: none;
}

.form-group .input-replacement {
  padding: 4px 0 3px;
}

.form-group.has-error {
  border: 2px solid red;
}

.form-group.has-error .help-block {
  margin-bottom: 0;
}

.form-group.focus {
  border: 2px solid #7300fa;
}

.form-group.radio, .form-group.checkbox {
  width: auto;
  margin-bottom: 0px;
  padding: 3px 0px;
  border: none;
}

.form-group.radio label, .form-group.checkbox label {
  display: block;
  margin-right: 20px;
  font-family: 'AtlasG Regular';
}

.form-group.radio input, .form-group.checkbox input {
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-right: 10px;
  margin-bottom: 2px;
  vertical-align: text-bottom;
}

.form-group.select:after {
  content: '\f0d7';
  position: absolute;
  top: 27px;
  right: 37px;
  width: 40px;
  height: 60px;
  padding-top: 8px;
  margin: auto;
  background-color: #ffffff;
  font-family: 'FontAwesome';
  font-size: 30px;
  text-align: center;
  z-index: 3;
}

.form-group.file {
  position: absolute;
  border: none;
}

.form-group.file input {
  opacity: 0.01;
}

.form-group.file:after {
  content: '\f093';
  position: absolute;
  top: 27px;
  right: 37px;
  width: 40px;
  height: 60px;
  padding-top: 8px;
  margin: auto;
  background-color: #ffffff;
  font-family: 'FontAwesome';
  font-size: 30px;
  text-align: center;
  z-index: 3;
}

.form-group.empty {
  display: table;
  border: none;
  height: 94px;
  padding: 0;
}

.form-group.empty .center {
  display: table-cell;
  vertical-align: middle;
}

.small .form-group.select select {
  padding-right: 40px;
}

.small .form-group.select:after {
  top: 4px;
  right: 15px;
}

.select-multiple .form-group {
  overflow: visible;
}

.select-multiple .form-group.select:after {
  display: none;
}

.select-multiple .form-group .chosen-choices {
  border: 0;
  padding: 0;
  background-image: none;
  box-shadow: none;
  margin-top: 6px;
}

.select-multiple .form-group .chosen-choices .search-field input {
  font-family: "AtlasG Regular" !important;
  font-size: 16px !important;
}

.select-multiple .form-group .chosen-choices .search-choice {
  border: none;
  box-shadow: none;
  color: #000;
  font-family: 'AtlasG Regular', sans-serif;
  font-size: 16px;
  background: none;
}

.select-multiple .form-group .chosen-choices .search-choice .search-choice-close {
  top: 4px;
  background-position: 0;
  font: normal normal normal 14px/1 FontAwesome;
}

.select-multiple .form-group .chosen-choices .search-choice .search-choice-close:before {
  content: "\f00d";
}

.select-multiple .form-group .chosen-choices .search-choice .search-choice-close:hover {
  background-position: 0;
}

.select-multiple .form-group .chosen-drop {
  border: 2px solid #000;
  box-shadow: none;
}

.select-multiple .form-group .chosen-drop li {
  height: 30px;
  padding: 5px 10px;
  color: #000;
  font-family: 'AtlasG Regular';
  font-size: 16px;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

.select-multiple .form-group .chosen-drop li.result-selected {
  filter: alpha(opacity=50);
  opacity: 0.5;
}

.select-multiple .form-group .chosen-drop li.highlighted {
  background: none;
  color: #7300fa;
}

.select-multiple .form-group .chosen-drop li.no-results {
  background: none;
}

.ui-autocomplete {
  background: #ffffff;
  border: 2px solid #000;
}

.ui-autocomplete li {
  padding: 10px 10px !important;
  font-family: 'AtlasG Regular';
  font-size: 16px;
}

.ui-autocomplete li.ui-state-focus {
  margin: 0;
  border: none;
  background: none;
  font-weight: normal;
  color: #7300fa;
}

.fileupload-group {
  padding: 0;
  border: none;
}

.fileupload-group label, .fileupload-group input, .fileupload-group a, .fileupload-group .glyphicon {
  display: none;
}

.fileupload-group .fileupload {
  min-height: 90px;
  margin: 0;
  border: 2px solid #000000;
  color: #000000;
  cursor: pointer;
}

.fileupload-group .fileupload ol {
  margin: 0;
  text-align: left;
  padding: 5px 10px;
  font-size: 16px;
  word-break: break-word;
}

.fileupload-group .fileupload ol li {
  margin: 5px 0 5px 25px;
}

.fileupload-group .fileupload ol li:only-child {
  margin: 5px 0 5px 0;
  list-style: none;
}

.fileupload-group .fileupload img {
  width: 100%;
}

.fileupload-group .fileupload .progress {
  display: none;
  position: relative;
  z-index: 1;
}

.fileupload-group .fileupload .progress .progress-bar {
  float: left;
  background-color: #7300fa;
  height: 90px;
}

.fileupload-group .fileupload.empty:after {
  content: '\f093';
  position: absolute;
  top: 20px;
  right: 25px;
  width: 40px;
  height: 60px;
  padding-top: 8px;
  margin: auto;
  font-family: 'FontAwesome';
  font-size: 30px;
  text-align: center;
  z-index: 3;
  color: #000;
}

.fileupload-group .fileupload.dragover {
  border-color: #7300fa;
}

.fileupload-group .fileupload.dragover.empty:after {
  color: #7300fa;
}

.fileupload-group .fileupload.uploading .progress {
  display: block;
}

/*/////////////////////////////////////////////////////////////////////////////////////////// AUTENTICAÇÔES/AREA PESSOAL*/
.auth-box,
.personal-box,
.submit-box,
.dates-box {
  text-align: center;
  margin: 40px 0px;
}

.auth-box h2,
.personal-box h2,
.submit-box h2,
.dates-box h2 {
  font-size: 60px;
  margin-bottom: 20px;
}

.auth-box h3,
.personal-box h3,
.submit-box h3,
.dates-box h3 {
  font-size: 20px;
  margin-bottom: 10px;
}

.auth-box h4,
.personal-box h4,
.submit-box h4,
.dates-box h4 {
  font-family: 'AtlasG Medium';
  font-size: 24px;
  margin-bottom: 20px;
}

.auth-box h5,
.personal-box h5,
.submit-box h5,
.dates-box h5 {
  float: left;
  width: 100%;
  font-family: 'AtlasG Medium';
  font-size: 16px;
  margin-bottom: 10px;
}

.auth-box h5 span,
.personal-box h5 span,
.submit-box h5 span,
.dates-box h5 span {
  font-family: 'AtlasG Regular';
  color: #7f7f7f;
}

.auth-box h6,
.personal-box h6,
.submit-box h6,
.dates-box h6 {
  font-size: 11px;
}

.auth-box h6 span,
.personal-box h6 span,
.submit-box h6 span,
.dates-box h6 span {
  float: left;
  color: #7f7f7f;
  margin: 0px 4px 40px;
}

.auth-box .intro-text,
.personal-box .intro-text,
.submit-box .intro-text,
.dates-box .intro-text {
  font-size: 18px;
  margin-bottom: 40px;
}

.auth-box .intro-text.secondary,
.personal-box .intro-text.secondary,
.submit-box .intro-text.secondary,
.dates-box .intro-text.secondary {
  margin-bottom: 10px;
}

.auth-box .btn,
.personal-box .btn,
.submit-box .btn,
.dates-box .btn {
  font-size: 40px;
  margin: 24px 0px;
}

.auth-box .btn.next,
.personal-box .btn.next,
.submit-box .btn.next,
.dates-box .btn.next {
  float: right;
}

.auth-box .btn.prev,
.personal-box .btn.prev,
.submit-box .btn.prev,
.dates-box .btn.prev {
  float: left;
}

.auth-box a.btn,
.personal-box a.btn,
.submit-box a.btn,
.dates-box a.btn {
  line-height: 38px;
}

.auth-box .btn-send,
.personal-box .btn-send,
.submit-box .btn-send,
.dates-box .btn-send {
  position: absolute;
  bottom: 20px;
  right: 0px;
}

.auth-box .areaform,
.personal-box .areaform,
.submit-box .areaform,
.dates-box .areaform {
  display: none;
}

.auth-box .row,
.personal-box .row,
.submit-box .row,
.dates-box .row {
  margin-bottom: 20px;
  text-align: left;
}

.auth-box .conter,
.personal-box .conter,
.submit-box .conter,
.dates-box .conter {
  margin: 20px 0px;
  font-family: 'AtlasG Medium';
}

.auth-box .conter a,
.auth-box .conter span,
.personal-box .conter a,
.personal-box .conter span,
.submit-box .conter a,
.submit-box .conter span,
.dates-box .conter a,
.dates-box .conter span {
  margin: 0px 30px;
  font-size: 60px;
  font-weight: bold;
}

.auth-box .conter a.active,
.auth-box .conter span.active,
.personal-box .conter a.active,
.personal-box .conter span.active,
.submit-box .conter a.active,
.submit-box .conter span.active,
.dates-box .conter a.active,
.dates-box .conter span.active {
  color: #7300fa;
}

.auth-box .area,
.personal-box .area,
.submit-box .area,
.dates-box .area {
  margin: 20px 0px 40px;
  font-size: 36px;
}

.auth-box .area.a-smaller,
.personal-box .area.a-smaller,
.submit-box .area.a-smaller,
.dates-box .area.a-smaller {
  font-size: 30px;
}

.auth-box .area a,
.personal-box .area a,
.submit-box .area a,
.dates-box .area a {
  margin: 0px 30px;
}

.auth-box .area a.active,
.personal-box .area a.active,
.submit-box .area a.active,
.dates-box .area a.active {
  color: #7300fa;
  font-weight: bold;
}

.auth-box .hauto,
.personal-box .hauto,
.submit-box .hauto,
.dates-box .hauto {
  height: auto;
}

.auth-box .hint,
.personal-box .hint,
.submit-box .hint,
.dates-box .hint {
  float: left;
  width: 100%;
  margin-top: -10px;
  margin-bottom: 10px;
}

.auth-box .link,
.personal-box .link,
.submit-box .link,
.dates-box .link {
  display: block;
  font-size: 18px;
  margin: -20px 0px 40px;
}

.auth-box .btn-ok,
.personal-box .btn-ok,
.submit-box .btn-ok,
.dates-box .btn-ok {
  display: block;
  border: 0px;
  background: none;
  font-size: 40px;
  cursor: pointer;
  margin: 40px 0px 20px;
}

.auth-box .btn-mail,
.personal-box .btn-mail,
.submit-box .btn-mail,
.dates-box .btn-mail {
  position: absolute;
  right: -140px;
  top: 0;
  bottom: 0;
}

.auth-box .small .form-group,
.personal-box .small .form-group,
.submit-box .small .form-group,
.dates-box .small .form-group {
  padding: 10px 30px;
}

.auth-box .small .form-group .row,
.personal-box .small .form-group .row,
.submit-box .small .form-group .row,
.dates-box .small .form-group .row {
  margin-bottom: 0;
}

.auth-box .small .nome,
.personal-box .small .nome,
.submit-box .small .nome,
.dates-box .small .nome {
  float: left;
  font-family: 'AtlasG Medium';
  font-size: 16px;
  margin-right: 6px;
}

.auth-box .small .tipo,
.personal-box .small .tipo,
.submit-box .small .tipo,
.dates-box .small .tipo {
  color: #7f7f7f;
}

.auth-box .small .apagar,
.auth-box .small .icon,
.auth-box .small .plus,
.personal-box .small .apagar,
.personal-box .small .icon,
.personal-box .small .plus,
.submit-box .small .apagar,
.submit-box .small .icon,
.submit-box .small .plus,
.dates-box .small .apagar,
.dates-box .small .icon,
.dates-box .small .plus {
  float: right;
  font-family: 'AtlasG Regular';
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
}

.auth-box .small .plus,
.personal-box .small .plus,
.submit-box .small .plus,
.dates-box .small .plus {
  font-size: 26px;
  margin: -10px;
}

.auth-box .small .hover:hover,
.personal-box .small .hover:hover,
.submit-box .small .hover:hover,
.dates-box .small .hover:hover {
  color: #7300fa;
  cursor: pointer;
}

.auth-box .download,
.personal-box .download,
.submit-box .download,
.dates-box .download {
  float: left;
  width: 33%;
  margin-bottom: 20px;
}

.auth-box .download icon,
.personal-box .download icon,
.submit-box .download icon,
.dates-box .download icon {
  float: left;
  width: 50px;
  margin-top: 6px;
  text-align: center;
  font-size: 36px;
}

.auth-box .download span,
.personal-box .download span,
.submit-box .download span,
.dates-box .download span {
  display: block;
  font-size: 16px;
}

.auth-box .download .doc-name,
.personal-box .download .doc-name,
.submit-box .download .doc-name,
.dates-box .download .doc-name {
  font-size: 12px;
}

.auth-box .dados,
.personal-box .dados,
.submit-box .dados,
.dates-box .dados {
  text-align: left;
  margin-bottom: 40px;
}

.auth-box .dados .cell,
.personal-box .dados .cell,
.submit-box .dados .cell,
.dates-box .dados .cell {
  font-family: 'AtlasG Regular';
  color: #7f7f7f;
}

.auth-box .dados .cell .label,
.personal-box .dados .cell .label,
.submit-box .dados .cell .label,
.dates-box .dados .cell .label {
  font-family: 'AtlasG Medium';
  font-size: 16px;
  color: #000000;
  text-align: right;
}

.auth-box .dados .cell .row div,
.personal-box .dados .cell .row div,
.submit-box .dados .cell .row div,
.dates-box .dados .cell .row div {
  min-height: 24px;
}

@media (max-width: 991px) {
  .auth-box .dados .cell .row,
  .personal-box .dados .cell .row,
  .submit-box .dados .cell .row,
  .dates-box .dados .cell .row {
    text-align: center;
  }
  .auth-box .dados .cell .label,
  .personal-box .dados .cell .label,
  .submit-box .dados .cell .label,
  .dates-box .dados .cell .label {
    margin-top: 10px;
    text-align: center;
  }
}

.auth-box .btn-forgot,
.personal-box .btn-forgot,
.submit-box .btn-forgot,
.dates-box .btn-forgot {
  float: left;
  display: table;
  width: 65%;
  height: 48px;
  margin: 20px 0px;
  font-size: 16px;
  text-align: left;
}

.auth-box .btn-forgot a,
.personal-box .btn-forgot a,
.submit-box .btn-forgot a,
.dates-box .btn-forgot a {
  display: table-cell;
  vertical-align: middle;
  font-size: 16px;
}

.auth-box .team-row,
.auth-box .project-row,
.auth-box .date-row,
.personal-box .team-row,
.personal-box .project-row,
.personal-box .date-row,
.submit-box .team-row,
.submit-box .project-row,
.submit-box .date-row,
.dates-box .team-row,
.dates-box .project-row,
.dates-box .date-row {
  margin-bottom: 5px;
}

.auth-box .team-row .action,
.auth-box .project-row .action,
.auth-box .date-row .action,
.personal-box .team-row .action,
.personal-box .project-row .action,
.personal-box .date-row .action,
.submit-box .team-row .action,
.submit-box .project-row .action,
.submit-box .date-row .action,
.dates-box .team-row .action,
.dates-box .project-row .action,
.dates-box .date-row .action {
  font-size: 16px;
}

.auth-box .team-row icon,
.auth-box .project-row icon,
.auth-box .date-row icon,
.personal-box .team-row icon,
.personal-box .project-row icon,
.personal-box .date-row icon,
.submit-box .team-row icon,
.submit-box .project-row icon,
.submit-box .date-row icon,
.dates-box .team-row icon,
.dates-box .project-row icon,
.dates-box .date-row icon {
  font-size: 18px;
}

.auth-box .team-row icon:before,
.auth-box .project-row icon:before,
.auth-box .date-row icon:before,
.personal-box .team-row icon:before,
.personal-box .project-row icon:before,
.personal-box .date-row icon:before,
.submit-box .team-row icon:before,
.submit-box .project-row icon:before,
.submit-box .date-row icon:before,
.dates-box .team-row icon:before,
.dates-box .project-row icon:before,
.dates-box .date-row icon:before {
  margin-left: 4px;
}

.auth-box .team-row a,
.auth-box .team-row button,
.auth-box .project-row a,
.auth-box .project-row button,
.auth-box .date-row a,
.auth-box .date-row button,
.personal-box .team-row a,
.personal-box .team-row button,
.personal-box .project-row a,
.personal-box .project-row button,
.personal-box .date-row a,
.personal-box .date-row button,
.submit-box .team-row a,
.submit-box .team-row button,
.submit-box .project-row a,
.submit-box .project-row button,
.submit-box .date-row a,
.submit-box .date-row button,
.dates-box .team-row a,
.dates-box .team-row button,
.dates-box .project-row a,
.dates-box .project-row button,
.dates-box .date-row a,
.dates-box .date-row button {
  margin-left: 20px;
}

.auth-box .team-row a:before,
.auth-box .team-row button:before,
.auth-box .project-row a:before,
.auth-box .project-row button:before,
.auth-box .date-row a:before,
.auth-box .date-row button:before,
.personal-box .team-row a:before,
.personal-box .team-row button:before,
.personal-box .project-row a:before,
.personal-box .project-row button:before,
.personal-box .date-row a:before,
.personal-box .date-row button:before,
.submit-box .team-row a:before,
.submit-box .team-row button:before,
.submit-box .project-row a:before,
.submit-box .project-row button:before,
.submit-box .date-row a:before,
.submit-box .date-row button:before,
.dates-box .team-row a:before,
.dates-box .team-row button:before,
.dates-box .project-row a:before,
.dates-box .project-row button:before,
.dates-box .date-row a:before,
.dates-box .date-row button:before {
  content: '|';
  margin-right: 15px;
}

.auth-box .team-row a:last-child:before,
.auth-box .team-row button:last-child:before,
.auth-box .project-row a:last-child:before,
.auth-box .project-row button:last-child:before,
.auth-box .date-row a:last-child:before,
.auth-box .date-row button:last-child:before,
.personal-box .team-row a:last-child:before,
.personal-box .team-row button:last-child:before,
.personal-box .project-row a:last-child:before,
.personal-box .project-row button:last-child:before,
.personal-box .date-row a:last-child:before,
.personal-box .date-row button:last-child:before,
.submit-box .team-row a:last-child:before,
.submit-box .team-row button:last-child:before,
.submit-box .project-row a:last-child:before,
.submit-box .project-row button:last-child:before,
.submit-box .date-row a:last-child:before,
.submit-box .date-row button:last-child:before,
.dates-box .team-row a:last-child:before,
.dates-box .team-row button:last-child:before,
.dates-box .project-row a:last-child:before,
.dates-box .project-row button:last-child:before,
.dates-box .date-row a:last-child:before,
.dates-box .date-row button:last-child:before {
  content: '';
  margin-right: 0;
}

.auth-box .team-row + .add-new,
.auth-box .project-row + .add-new,
.auth-box .date-row + .add-new,
.personal-box .team-row + .add-new,
.personal-box .project-row + .add-new,
.personal-box .date-row + .add-new,
.submit-box .team-row + .add-new,
.submit-box .project-row + .add-new,
.submit-box .date-row + .add-new,
.dates-box .team-row + .add-new,
.dates-box .project-row + .add-new,
.dates-box .date-row + .add-new {
  margin-top: 25px;
}

.auth-box .project-row .project-name,
.personal-box .project-row .project-name,
.submit-box .project-row .project-name,
.dates-box .project-row .project-name {
  font-family: 'AtlasG Medium';
}

.auth-box .project-row button,
.personal-box .project-row button,
.submit-box .project-row button,
.dates-box .project-row button {
  padding: 0;
}

.auth-box .project-row.add-new .form-group,
.personal-box .project-row.add-new .form-group,
.submit-box .project-row.add-new .form-group,
.dates-box .project-row.add-new .form-group {
  padding: 0;
}

.auth-box .project-row.add-new .form-group button,
.personal-box .project-row.add-new .form-group button,
.submit-box .project-row.add-new .form-group button,
.dates-box .project-row.add-new .form-group button {
  width: 100%;
  padding: 10px 30px;
  margin: 0;
  text-align: left;
  font-family: 'AtlasG Medium';
  font-size: 16px;
}

.auth-box .accept-invite,
.auth-box .remove-from-team,
.personal-box .accept-invite,
.personal-box .remove-from-team,
.submit-box .accept-invite,
.submit-box .remove-from-team,
.dates-box .accept-invite,
.dates-box .remove-from-team {
  text-align: right;
  position: absolute;
  right: 15px;
  padding-right: 30px;
}

.auth-box .accept-invite icon,
.auth-box .remove-from-team icon,
.personal-box .accept-invite icon,
.personal-box .remove-from-team icon,
.submit-box .accept-invite icon,
.submit-box .remove-from-team icon,
.dates-box .accept-invite icon,
.dates-box .remove-from-team icon {
  font-size: 20px;
  position: absolute;
  top: 2px;
  right: 4px;
}

.auth-box .remove-from-team icon,
.personal-box .remove-from-team icon,
.submit-box .remove-from-team icon,
.dates-box .remove-from-team icon {
  right: 9px;
}

.member-info {
  color: #7f7f7f;
  font-family: 'AtlasG Regular';
}

.member-info:before {
  content: '|';
  margin: 0 10px 0 5px;
}

.member-pre-register .link {
  display: table;
  width: 100%;
  padding: 20px 0px;
  margin: auto;
  border-bottom: 3px solid #000000;
  text-align: left;
}

.member-pre-register .link span {
  float: left;
  font-size: 34px;
  line-height: 36px;
}

.member-pre-register .link icon {
  float: right;
  width: 100px;
  text-align: center;
  font-size: 44px;
}

.member-pre-register .link:last-child {
  border: none;
}

.document-type-and-number .form-group.text {
  padding-bottom: 14px;
}

.document-type-and-number .form-group.radio label {
  font-family: 'AtlasG Medium';
  font-size: 16px;
}

.register-success .btn {
  display: inline-block;
  margin: 40px 0 0;
}

.toggable-cell {
  cursor: pointer;
}

.toggable-cell .form-group.radio {
  display: none;
}

.toggable-cell icon {
  visibility: hidden;
}

.toggable-cell.active {
  color: #7300fa;
}

.toggable-cell.active icon {
  visibility: visible;
}

.coordination-method .feedback {
  margin-top: 10px;
}

.row.partners-radios {
  margin-bottom: 8px;
}

.row.partners-radios label {
  display: inline-block;
}

.row.partners-radios label input {
  display: inline-block;
  width: auto;
  margin-right: 10px;
  position: relative;
  top: 7px;
}

.contest-submission .submit-box,
.contest-submission-success .submit-box {
  text-align: left;
}

.contest-submission .submit-box.submission-header,
.contest-submission-success .submit-box.submission-header {
  margin: 0px;
  text-align: center;
}

.contest-submission .submit-box.submission-header .container,
.contest-submission-success .submit-box.submission-header .container {
  position: relative;
  z-index: 2;
}

.contest-submission .submit-box.submission-header p,
.contest-submission-success .submit-box.submission-header p {
  margin-bottom: 0px;
}

.success-notification div {
  text-align: center;
}

.success-notification .btn {
  display: inline-block;
  margin: 40px 0 0;
}

.contest-submission .submission-wrapper {
  margin-bottom: 30px;
}

.contest-submission .submission-coordinator {
  width: auto;
  min-width: 150px;
}

.contest-submission .submission-member {
  margin-bottom: 0px;
}

.contest-submission .submission-team {
  margin-bottom: 20px;
}

.contest-submission .submission-team .list-items {
  margin-bottom: 0;
}

.contest-submission .submission-team .tab.active .list-items {
  padding-top: 25px;
}

.contest-submission .toggable-member .action {
  position: absolute;
  right: 10px;
  top: 4px;
  font-size: 20px;
}

.contest-submission .toggable-member .action .enable {
  display: none;
}

.contest-submission .toggable-member.disabled .form-group {
  border-color: #7f7f7f;
}

.contest-submission .toggable-member.disabled .form-group label, .contest-submission .toggable-member.disabled .form-group input {
  color: #7f7f7f;
}

.contest-submission .toggable-member.disabled .member-field {
  display: none;
}

.contest-submission .toggable-member.disabled .action .disable {
  display: none;
}

.contest-submission .toggable-member.disabled .action .enable {
  display: block;
}

.contest-submission .member-input-template {
  display: none;
}

.proposal .coordinator {
  margin-top: 25px;
}

.proposal .imag img {
  font-size: 0;
  width: 100%;
}

.proposal .info {
  position: relative;
  padding: 0px 40px 60px;
}

.proposal .info p {
  float: left;
  width: 100%;
  margin: 0px 0px 30px;
  font-size: 14px;
  line-height: 24px;
}

.proposal .ppl {
  margin: 25px 0px 25px;
}

.proposal .ppl span {
  display: block;
  font-family: 'AtlasG Medium';
  font-size: 16px;
  margin-bottom: 20px;
}

.proposal .ppl li {
  display: block;
  font-size: 14px;
  margin: 4px 0px;
}

.proposal .download {
  position: absolute;
  width: 50%;
  right: 10px;
  bottom: 42px;
  margin-bottom: 0;
  padding: 0px 40px;
}

.proposal .download icon {
  float: left;
  width: 50px;
  margin-top: 6px;
  text-align: center;
  font-size: 36px;
}

.proposal .download span {
  display: block;
  font-size: 16px;
}

.proposal .download .doc-name {
  font-size: 12px;
}

/*/////////////////////////////////////////////////////////////////////////////////////////// TABS */
.tabs {
  float: left;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  width: 100%;
}

.tabs .tab {
  position: relative;
  float: left;
  width: 100%;
  border-top: 1px solid #000000;
  border-bottom: 1px solid #000000;
  padding: 20px 30px;
  overflow: hidden;
  background-color: #ffffff;
}

.tabs .tab .info-span {
  position: absolute;
  left: 6px;
  top: 22px;
}

.tabs .tab a {
  display: block;
  position: relative;
  height: auto;
  padding-right: 60px;
  font-family: 'AtlasG Medium';
  font-size: 19px;
  cursor: pointer;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

.tabs .tab a:after {
  position: absolute;
  top: 0px;
  bottom: 0;
  right: 10px;
  margin: auto;
  content: '\f107';
  color: #000000;
  font-family: 'FontAwesome';
  font-size: 48px;
  line-height: 28px;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

.tabs .tab a:hover {
  color: #7300fa;
}

.tabs .tab a:hover:after {
  color: #7300fa;
}

.tabs .tab div {
  max-height: 0px;
  overflow: hidden;
  background-color: #ffffff;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

.tabs .tab div p {
  margin: 20px 0px;
  font-size: 16px;
  line-height: 24px;
}

.tabs .tab.active div {
  max-height: 5000px;
}

.tabs .tab.active a {
  color: #7300fa;
}

.tabs .tab.active a:after {
  content: '\f106';
  color: #7300fa;
}

/*/////////////////////////////////////////////////////////////////////////////////////////// FOOTER */
footer {
  height: 170px;
  margin-top: -170px;
  padding: 60px 20px;
  background: #ffffff;
  z-index: 4;
  font-family: 'AtlasT Medium';
  clear: both;
}

@media (max-width: 767px) {
  footer {
    height: 140px;
  }
}

@media (max-width: 767px) {
  footer {
    margin-top: -140px;
  }
}

footer .container {
  margin: auto !important;
}

footer .links-and-copyright {
  float: left;
  width: auto;
  margin-top: 8px;
  font-family: 'AtlasG Regular';
}

footer .links-and-copyright .copyright {
  display: inline-block;
  margin-right: 30px;
  font-size: 12px;
  font-weight: bold;
  vertical-align: top;
}

footer .links-and-copyright .links a {
  display: inline-block;
  margin: 0;
  margin-right: 10px;
  font-size: 14px;
  font-weight: normal;
}

footer .links-and-copyright .links a:after {
  content: '/';
  position: relative;
  left: 10px;
}

footer .links-and-copyright .links a:last-child:after {
  content: '';
}

footer .logo {
  float: right;
  width: auto;
  text-align: right;
}

footer .logo a {
  display: inline-block;
  width: 55px;
  height: 51px;
  background-image: url("../../img/logo.png");
}

footer .logo span {
  display: block;
  margin-top: 16px;
  font-size: 14px;
  white-space: nowrap;
}

@media (max-width: 767px) {
  footer {
    padding: 16px 10px;
  }
  footer .copyright {
    max-width: 380px;
    width: 75%;
  }
  footer .links a {
    display: block;
    font-size: 12px;
  }
  footer .links span {
    display: block;
    font-size: 14px;
  }
  footer .logo {
    position: absolute;
    right: 0;
    /*a{width:48px; height:45px;}*/
  }
  footer .logo span {
    margin-top: 6px;
    font-size: 12px;
  }
}

/*/////////////////////////////////////////////////////////////////////////////////////////// FAQ´s */
#faqs {
  position: relative;
  padding: 50px 0px;
}

#faqs h2 {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  font-size: 36px;
  padding-right: 100px;
}

#faqs h2 icon {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 40px;
  height: 22px;
  margin: auto;
}

/*/////////////////////////////////////////////////////////////////////////////////////////// HOME */
.home #billhead {
  position: relative;
  height: 500px;
  max-width: 1150px;
  margin: auto;
}

.home #billhead .cell {
  display: table;
  position: relative;
  width: 460px;
  height: 160px;
  margin: auto;
}

.home #billhead .slider-home {
  height: 100%;
  overflow: hidden;
}

.home #billhead .slider-home #slider-contests {
  position: relative;
  width: 100%;
  height: 100%;
}

.home #billhead .slider-home .slider .slider-cell {
  background: #ffffff;
}

.home #billhead .slider-home .slider .slider-cell .descri {
  position: absolute;
  top: 20px;
  left: 10px;
  width: 60%;
  text-align: left;
  z-index: 2;
}

.home #billhead .slider-home .slider .slider-cell .descri .tipodata {
  float: left;
  width: 100%;
  font-family: 'AtlasT Regular';
  font-size: 18px;
  margin-bottom: 6px;
}

.home #billhead .slider-home .slider .slider-cell .descri .titulo {
  float: left;
  width: 100%;
  font-family: 'AtlasG Medium';
  font-size: 32px;
  line-height: 34px;
}

.home #billhead .slider-home .btn {
  position: absolute;
  top: 30px;
  right: 5%;
  z-index: 2;
}

.home #billhead .container {
  height: 100%;
}

.home #billhead .container .links {
  display: table;
  height: 100%;
  text-align: center;
}

.home #billhead .container .links .cell:first-child {
  border-bottom: 3px solid #000000;
}

.home #billhead .container .links .center {
  display: table-cell;
  vertical-align: middle;
}

.home #billhead .container .links h2 {
  margin-bottom: 40px;
  font-family: 'AtlasG Black';
  font-size: 40px;
  font-weight: bold;
}

.home #billhead .container .links h3 {
  margin-bottom: 10px;
  font-family: 'AtlasG Black';
  font-size: 30px;
  line-height: 30px;
}

.home #billhead .container .links .box {
  display: table;
  padding: 0px 30px;
  height: 100%;
  text-align: left;
}

.home #billhead .container .links .box .link {
  width: 100%;
}

.home #billhead .container .links .link {
  display: table;
  width: 480px;
  padding: 20px 0px;
  margin: auto;
  border-bottom: 3px solid #000000;
  text-align: left;
}

.home #billhead .container .links .link icon {
  display: table-cell;
  vertical-align: middle;
  width: 100px;
  font-size: 62px;
  text-align: center;
}

.home #billhead .container .links .link p {
  float: left;
}

.home #billhead .container .links .link p small {
  display: block;
  font-size: 14px;
}

.home #billhead .container .links .link p span {
  display: block;
  font-size: 34px;
  line-height: 34px;
}

.home #billhead .container .links .link .fa-arrowchevron {
  font-size: 44px;
  margin-top: 10px;
}

.home #billhead .container .links .link:last-child {
  border: none;
}

@media (max-width: 991px) {
  .home #billhead .slider-home .slider .slider-cell .descri .tipodata {
    font-size: 12px;
  }
  .home #billhead .slider-home .slider .slider-cell .descri .titulo {
    font-size: 22px;
    line-height: 24px;
  }
  .home #billhead .container .links {
    display: none;
  }
  .home #billhead .container .links .center h2 {
    margin-bottom: 20px;
    font-size: 28px;
  }
  .home #billhead .container .links .center .link {
    width: 300px;
    padding: 10px 0px;
  }
  .home #billhead .container .links .center .link icon {
    width: 60px;
    font-size: 44px;
    margin-top: 4px;
  }
  .home #billhead .container .links .center .link p small {
    font-size: 12px;
  }
  .home #billhead .container .links .center .link p span {
    font-size: 26px;
    line-height: 28px;
  }
  .home #billhead .container .links .center .link .fa-chevron-right {
    font-size: 30px;
    margin-top: 10px;
  }
}

.home #news {
  position: relative;
  padding: 50px 0px;
  background: #ffffff;
}

.home #news .container {
  overflow: hidden;
}

.home #news h2 {
  margin-bottom: 20px;
  font-size: 36px;
}

.home #news .btn {
  float: right;
}

@media (max-width: 991px) {
  .home #news .btn {
    font-size: 20px;
  }
}

.home #promoter {
  margin: -40px 0px 20px;
  display: none;
}

.home #promoter .link {
  display: table;
  width: 300px;
  padding: 20px 0px;
  margin: auto;
  text-align: left;
}

.home #promoter .link h3 {
  margin-bottom: 10px;
  font-family: 'AtlasG Black';
  font-size: 30px;
  line-height: 30px;
}

.home #promoter .link icon {
  display: table-cell;
  vertical-align: middle;
  width: 100px;
  font-size: 62px;
  text-align: center;
}

.home #promoter .link .fa-arrowchevron {
  font-size: 44px;
  margin-top: 10px;
}

@media (max-width: 991px) {
  .home #promoter {
    display: block;
  }
}

.home #map {
  position: relative;
  height: 400px;
  background-image: url("../../img/backgrd-map.jpg");
  max-width: 1150px;
  margin: auto;
}

.home #map .container {
  display: table;
  height: 100%;
  text-align: center;
}

.home #map .container .center {
  display: table-cell;
  vertical-align: middle;
}

.home #map .container .center icon {
  font-size: 130px;
}

.home #map .container .center h2 {
  font-size: 40px;
  font-family: 'AtlasG Medium';
  font-weight: bold;
}

.home #contact {
  position: relative;
  padding: 50px 0px;
}

.home #contact .container {
  padding: 0px;
}

.home #contact h2 {
  margin-bottom: 20px;
  font-size: 36px;
}

.home #contact h3 {
  font-family: 'AtlasG Medium';
  font-size: 16px;
  font-weight: bold;
}

.home #contact .info p {
  margin-bottom: 80px;
  font-size: 16px;
}

.home #contact .btn-send {
  float: right;
  margin: 20px 0px 0px;
}

.home #contact .btn-send:hover {
  color: #7300fa;
}

.home #contact .btn-send icon {
  font-size: 30px;
}

@media (max-width: 767px) {
  .home #contact p {
    margin-bottom: 20px;
  }
}

/*/////////////////////////////////////////////////////////////////////////////////////////// ABOUT */
.about #billhead {
  position: relative;
  height: 540px;
}

.about #billhead .container {
  display: table;
  position: relative;
  height: 100%;
  text-align: center;
  z-index: 2;
}

.about #billhead .container .center {
  display: table-cell;
  vertical-align: middle;
}

.about #billhead .container .center h2 {
  font-size: 48px;
  font-weight: bold;
  color: #fff;
}

.about .area {
  padding: 100px 0px;
}

.about .area h3 {
  width: 100%;
  font-family: 'AtlasG Black';
  font-size: 32px;
  text-align: center;
}

.about .area p {
  display: block;
  width: 100%;
  margin: 40px auto;
  font-size: 16px;
}

.about .sub-menu {
  position: absolute;
  height: 70px;
  width: 100%;
  z-index: 1;
  background-color: #ffffff;
}

.about .sub-menu ul {
  display: table;
  margin: auto;
  padding-top: 18px;
}

.about .sub-menu ul li {
  float: left;
  margin: 0px 10px;
  font-size: 16px;
}

.about .sub-menu ul li a:hover {
  color: #7300fa;
}

.about .sub-menu ul li a.active {
  color: #7300fa;
  font-weight: bold;
}

.about .sub-menu.fixed {
  position: fixed;
  top: 90px;
}

.about .gotop {
  float: right;
  margin: 20px 0px -20px;
  font-size: 30px;
  cursor: pointer;
}

@media (max-width: 991px) {
  .about .sub-menu {
    overflow: auto;
  }
  .about .sub-menu ul {
    box-sizing: content-box;
  }
  .about .sub-menu ul li {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .about #billhead .container .center h3 {
    font-size: 40px;
    line-height: 42px;
  }
  .about #billhead .container .center p span {
    display: block;
  }
  .about .area {
    padding: 60px 0px;
  }
  .about .area h3 {
    font-size: 36px;
    line-height: 40px;
  }
  .about .area p {
    width: 100%;
  }
  .about .sub-menu {
    display: none;
  }
}

/*/////////////////////////////////////////////////////////////////////////////////////////// NOTICIAS */
.contests .container,
.contests-internal .container,
.contests-external .container,
.contests-awarded .container,
.contests-archive .container,
.contests-pending-assessment .container,
.awards .container,
.news .container,
.partners .container,
.publications .container {
  margin: 50px auto 70px;
  overflow: hidden;
}

.contests h2,
.contests-internal h2,
.contests-external h2,
.contests-awarded h2,
.contests-archive h2,
.contests-pending-assessment h2,
.awards h2,
.news h2,
.partners h2,
.publications h2 {
  margin-bottom: 20px;
  font-size: 36px;
}

.contests .filtros,
.contests-internal .filtros,
.contests-external .filtros,
.contests-awarded .filtros,
.contests-archive .filtros,
.contests-pending-assessment .filtros,
.awards .filtros,
.news .filtros,
.partners .filtros,
.publications .filtros {
  text-align: center;
  padding-top: 24px;
  white-space: nowrap;
}

.contests .filtros span,
.contests-internal .filtros span,
.contests-external .filtros span,
.contests-awarded .filtros span,
.contests-archive .filtros span,
.contests-pending-assessment .filtros span,
.awards .filtros span,
.news .filtros span,
.partners .filtros span,
.publications .filtros span {
  display: inline-block;
  color: #7f7f7f;
}

.contests .filtros .filter-option,
.contests-internal .filtros .filter-option,
.contests-external .filtros .filter-option,
.contests-awarded .filtros .filter-option,
.contests-archive .filtros .filter-option,
.contests-pending-assessment .filtros .filter-option,
.awards .filtros .filter-option,
.news .filtros .filter-option,
.partners .filtros .filter-option,
.publications .filtros .filter-option {
  display: inline-block;
  margin: 0px 10px;
}

.contests .filtros .filter-option:hover,
.contests-internal .filtros .filter-option:hover,
.contests-external .filtros .filter-option:hover,
.contests-awarded .filtros .filter-option:hover,
.contests-archive .filtros .filter-option:hover,
.contests-pending-assessment .filtros .filter-option:hover,
.awards .filtros .filter-option:hover,
.news .filtros .filter-option:hover,
.partners .filtros .filter-option:hover,
.publications .filtros .filter-option:hover {
  color: #7300fa;
}

.contests .filtros .filter-option.active,
.contests-internal .filtros .filter-option.active,
.contests-external .filtros .filter-option.active,
.contests-awarded .filtros .filter-option.active,
.contests-archive .filtros .filter-option.active,
.contests-pending-assessment .filtros .filter-option.active,
.awards .filtros .filter-option.active,
.news .filtros .filter-option.active,
.partners .filtros .filter-option.active,
.publications .filtros .filter-option.active {
  color: #7300fa;
  font-weight: bold;
}

.contests .pesquisa,
.contests-internal .pesquisa,
.contests-external .pesquisa,
.contests-awarded .pesquisa,
.contests-archive .pesquisa,
.contests-pending-assessment .pesquisa,
.awards .pesquisa,
.news .pesquisa,
.partners .pesquisa,
.publications .pesquisa {
  text-align: right;
  padding-top: 24px;
}

.contests .pesquisa input,
.contests-internal .pesquisa input,
.contests-external .pesquisa input,
.contests-awarded .pesquisa input,
.contests-archive .pesquisa input,
.contests-pending-assessment .pesquisa input,
.awards .pesquisa input,
.news .pesquisa input,
.partners .pesquisa input,
.publications .pesquisa input {
  display: inline-block;
  width: 100px;
  border: none;
  background-color: transparent;
  color: #7300fa;
  font-family: 'AtlasG Regular';
  font-size: 16px;
}

.contests .pesquisa input:-webkit-autofill,
.contests-internal .pesquisa input:-webkit-autofill,
.contests-external .pesquisa input:-webkit-autofill,
.contests-awarded .pesquisa input:-webkit-autofill,
.contests-archive .pesquisa input:-webkit-autofill,
.contests-pending-assessment .pesquisa input:-webkit-autofill,
.awards .pesquisa input:-webkit-autofill,
.news .pesquisa input:-webkit-autofill,
.partners .pesquisa input:-webkit-autofill,
.publications .pesquisa input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
}

.contests .pesquisa button,
.contests-internal .pesquisa button,
.contests-external .pesquisa button,
.contests-awarded .pesquisa button,
.contests-archive .pesquisa button,
.contests-pending-assessment .pesquisa button,
.awards .pesquisa button,
.news .pesquisa button,
.partners .pesquisa button,
.publications .pesquisa button {
  display: inline-block;
  font-size: 16px;
}

@media (max-width: 991px) {
  .contests h2,
  .contests-internal h2,
  .contests-external h2,
  .contests-awarded h2,
  .contests-archive h2,
  .contests-pending-assessment h2,
  .awards h2,
  .news h2,
  .partners h2,
  .publications h2 {
    margin-bottom: 0px;
  }
  .contests .filtros,
  .contests-internal .filtros,
  .contests-external .filtros,
  .contests-awarded .filtros,
  .contests-archive .filtros,
  .contests-pending-assessment .filtros,
  .awards .filtros,
  .news .filtros,
  .partners .filtros,
  .publications .filtros {
    float: left;
    text-align: left;
    padding-top: 0px;
    padding: 14px 5px;
  }
  .contests .filtros span,
  .contests-internal .filtros span,
  .contests-external .filtros span,
  .contests-awarded .filtros span,
  .contests-archive .filtros span,
  .contests-pending-assessment .filtros span,
  .awards .filtros span,
  .news .filtros span,
  .partners .filtros span,
  .publications .filtros span {
    font-size: 14px;
  }
  .contests .filtros .filter-option,
  .contests-internal .filtros .filter-option,
  .contests-external .filtros .filter-option,
  .contests-awarded .filtros .filter-option,
  .contests-archive .filtros .filter-option,
  .contests-pending-assessment .filtros .filter-option,
  .awards .filtros .filter-option,
  .news .filtros .filter-option,
  .partners .filtros .filter-option,
  .publications .filtros .filter-option {
    font-size: 14px;
    margin: 0px 5px;
  }
  .contests .pesquisa,
  .contests-internal .pesquisa,
  .contests-external .pesquisa,
  .contests-awarded .pesquisa,
  .contests-archive .pesquisa,
  .contests-pending-assessment .pesquisa,
  .awards .pesquisa,
  .news .pesquisa,
  .partners .pesquisa,
  .publications .pesquisa {
    float: right;
    width: auto;
    text-align: right;
    padding: 14px;
  }
}

.news-detail .container,
.award-detail .container {
  position: relative;
  margin: 50px auto 70px;
}

.news-detail .tipodata,
.award-detail .tipodata {
  float: left;
  width: 100%;
  font-family: 'AtlasT Regular';
  font-size: 16px;
  margin-bottom: 20px;
}

.news-detail .titulo,
.award-detail .titulo {
  float: left;
  width: 100%;
  font-family: 'AtlasG Medium';
  font-size: 30px;
  line-height: 32px;
  margin-bottom: 40px;
}

.news-detail p,
.award-detail p {
  float: left;
  width: 100%;
  margin: 15px 0px;
  font-size: 16px;
  line-height: 18px;
}

.news-detail .nav,
.award-detail .nav {
  position: absolute;
  top: 0;
  right: 8px;
}

.news-detail .nav a,
.award-detail .nav a {
  display: block;
  margin-bottom: 60px;
  font-size: 36px;
}

@media (max-width: 1199px) {
  .news-detail .container,
  .award-detail .container {
    padding: 0px 80px;
  }
}

@media (max-width: 991px) {
  .news-detail .container,
  .award-detail .container {
    padding: 0px 10px 0px 10px;
  }
  .news-detail .nav a,
  .award-detail .nav a {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .news-detail .tipodata,
  .award-detail .tipodata {
    margin-bottom: 10px;
  }
  .news-detail .titulo,
  .award-detail .titulo {
    margin-bottom: 20px;
  }
}

.resizeimg {
  height: 100%;
  width: 100%;
  position: absolute;
  overflow: hidden;
}

.thumbnail {
  float: left;
  margin-right: -30px;
  margin-left: -30px;
  font-size: 0;
  min-width: 100%;
}

.thumbnail .thumb {
  display: inline-block;
  float: none;
  padding: 0px 30px 30px;
  vertical-align: top;
}

.thumbnail .imag {
  float: left;
  position: relative;
  width: 100%;
  height: 250px;
  margin-bottom: 20px;
  background-size: cover;
}

.thumbnail .imag img {
  position: relative;
  width: 100%;
  margin: auto;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

.thumbnail .tipodata {
  float: left;
  width: 100%;
  font-family: 'AtlasT Regular';
  font-size: 12px;
}

.thumbnail .titulo {
  float: left;
  width: 100%;
  font-family: 'AtlasG Medium';
  font-size: 19px;
}

@media (max-width: 1199px) {
  .thumbnail .imag {
    height: 200px;
  }
}

@media (max-width: 991px) {
  .thumbnail .imag {
    height: 220px;
  }
}

@media (max-width: 767px) {
  .thumbnail .imag {
    height: 30vw;
  }
}

@media (max-width: 479px) {
  .thumbnail {
    margin-right: 0px;
    margin-left: 0px;
  }
  .thumbnail .imag {
    height: 64vw;
  }
  .thumbnail .thumb {
    width: 100%;
    padding: 0px 0px 30px;
  }
}

/*/////////////////////////////////////////////////////////////////////////////////////////// CONCURSOS */
.contests-detail #billhead,
.contest-assessment #billhead,
.contest-generate-report #billhead,
.contest-report-success #billhead {
  background: #ffffff;
  text-align: center;
}

.contests-detail #billhead .container,
.contest-assessment #billhead .container,
.contest-generate-report #billhead .container,
.contest-report-success #billhead .container {
  position: relative;
  display: table;
  height: 540px;
  z-index: 2;
}

.contests-detail #billhead .container.alert,
.contest-assessment #billhead .container.alert,
.contest-generate-report #billhead .container.alert,
.contest-report-success #billhead .container.alert {
  height: auto;
}

.contests-detail #billhead .container .timer,
.contest-assessment #billhead .container .timer,
.contest-generate-report #billhead .container .timer,
.contest-report-success #billhead .container .timer {
  color: #fff;
  margin-top: 20px;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

.contests-detail #billhead .container .timer .missing,
.contest-assessment #billhead .container .timer .missing,
.contest-generate-report #billhead .container .timer .missing,
.contest-report-success #billhead .container .timer .missing {
  display: inline-block;
  width: auto;
  font-family: 'AtlasG Medium';
  vertical-align: middle;
}

.contests-detail #billhead .container .timer .countdown,
.contest-assessment #billhead .container .timer .countdown,
.contest-generate-report #billhead .container .timer .countdown,
.contest-report-success #billhead .container .timer .countdown {
  display: inline-block;
  width: auto;
  margin: 0px 8px;
  vertical-align: middle;
}

.contests-detail #billhead .container .timer .countdown span,
.contest-assessment #billhead .container .timer .countdown span,
.contest-generate-report #billhead .container .timer .countdown span,
.contest-report-success #billhead .container .timer .countdown span {
  float: left;
  margin: 0px 6px;
  font-size: 30px;
  font-weight: bold;
}

.contests-detail #billhead .container .timer .countdown .cell,
.contest-assessment #billhead .container .timer .countdown .cell,
.contest-generate-report #billhead .container .timer .countdown .cell,
.contest-report-success #billhead .container .timer .countdown .cell {
  float: left;
}

.contests-detail #billhead .container .timer .countdown .cell span,
.contest-assessment #billhead .container .timer .countdown .cell span,
.contest-generate-report #billhead .container .timer .countdown .cell span,
.contest-report-success #billhead .container .timer .countdown .cell span {
  display: block;
  margin: 0px;
  float: none;
}

.contests-detail #billhead .container .timer .countdown .cell span:nth-child(even),
.contest-assessment #billhead .container .timer .countdown .cell span:nth-child(even),
.contest-generate-report #billhead .container .timer .countdown .cell span:nth-child(even),
.contest-report-success #billhead .container .timer .countdown .cell span:nth-child(even) {
  font-size: 11px;
}

.contests-detail #billhead .container .timer .entrega, .contests-detail #billhead .container .timer .limite,
.contest-assessment #billhead .container .timer .entrega,
.contest-assessment #billhead .container .timer .limite,
.contest-generate-report #billhead .container .timer .entrega,
.contest-generate-report #billhead .container .timer .limite,
.contest-report-success #billhead .container .timer .entrega,
.contest-report-success #billhead .container .timer .limite {
  display: inline-block;
  width: 160px;
  vertical-align: middle;
  text-align: left;
}

.contests-detail #billhead .container .timer .entrega icon, .contests-detail #billhead .container .timer .limite icon,
.contest-assessment #billhead .container .timer .entrega icon,
.contest-assessment #billhead .container .timer .limite icon,
.contest-generate-report #billhead .container .timer .entrega icon,
.contest-generate-report #billhead .container .timer .limite icon,
.contest-report-success #billhead .container .timer .entrega icon,
.contest-report-success #billhead .container .timer .limite icon {
  float: left;
  width: 50px;
  text-align: center;
  font-size: 36px;
}

.contests-detail #billhead .container .timer .entrega span, .contests-detail #billhead .container .timer .limite span,
.contest-assessment #billhead .container .timer .entrega span,
.contest-assessment #billhead .container .timer .limite span,
.contest-generate-report #billhead .container .timer .entrega span,
.contest-generate-report #billhead .container .timer .limite span,
.contest-report-success #billhead .container .timer .entrega span,
.contest-report-success #billhead .container .timer .limite span {
  display: block;
  font-size: 12px;
}

.contests-detail #billhead .container .timer .entrega span:nth-child(even), .contests-detail #billhead .container .timer .limite span:nth-child(even),
.contest-assessment #billhead .container .timer .entrega span:nth-child(even),
.contest-assessment #billhead .container .timer .limite span:nth-child(even),
.contest-generate-report #billhead .container .timer .entrega span:nth-child(even),
.contest-generate-report #billhead .container .timer .limite span:nth-child(even),
.contest-report-success #billhead .container .timer .entrega span:nth-child(even),
.contest-report-success #billhead .container .timer .limite span:nth-child(even) {
  font-family: 'AtlasG Medium';
}

.contests-detail #billhead .container .timer .limite,
.contest-assessment #billhead .container .timer .limite,
.contest-generate-report #billhead .container .timer .limite,
.contest-report-success #billhead .container .timer .limite {
  width: 210px;
}

.contests-detail #billhead .container:hover .timer,
.contest-assessment #billhead .container:hover .timer,
.contest-generate-report #billhead .container:hover .timer,
.contest-report-success #billhead .container:hover .timer {
  color: #7300fa;
}

.contests-detail #billhead.small .container,
.contest-assessment #billhead.small .container,
.contest-generate-report #billhead.small .container,
.contest-report-success #billhead.small .container {
  height: 420px;
}

.contests-detail #billhead.title-only .container,
.contest-assessment #billhead.title-only .container,
.contest-generate-report #billhead.title-only .container,
.contest-report-success #billhead.title-only .container {
  height: 270px;
}

.contests-detail #billhead .center,
.contest-assessment #billhead .center,
.contest-generate-report #billhead .center,
.contest-report-success #billhead .center {
  display: table-cell;
  vertical-align: middle;
  margin-top: -100px;
}

.contests-detail #billhead h2,
.contest-assessment #billhead h2,
.contest-generate-report #billhead h2,
.contest-report-success #billhead h2 {
  font-size: 48px;
  font-weight: bold;
}

.contests-detail #billhead h3,
.contest-assessment #billhead h3,
.contest-generate-report #billhead h3,
.contest-report-success #billhead h3 {
  font-size: 36px;
  margin-top: -80px;
}

.contests-detail #billhead .allinfo,
.contest-assessment #billhead .allinfo,
.contest-generate-report #billhead .allinfo,
.contest-report-success #billhead .allinfo {
  position: absolute;
  bottom: 0;
  left: 0px;
  width: 100%;
}

.contests-detail #billhead .info,
.contest-assessment #billhead .info,
.contest-generate-report #billhead .info,
.contest-report-success #billhead .info {
  height: auto;
  padding: 5px 15px;
  margin: 20px 0px;
}

.contests-detail #billhead .info .middle,
.contest-assessment #billhead .info .middle,
.contest-generate-report #billhead .info .middle,
.contest-report-success #billhead .info .middle {
  display: table;
  margin: auto;
  text-align: left;
}

.contests-detail #billhead .info icon,
.contest-assessment #billhead .info icon,
.contest-generate-report #billhead .info icon,
.contest-report-success #billhead .info icon {
  display: table-cell;
  vertical-align: middle;
  width: 50px;
  text-align: center;
  font-size: 36px;
}

.contests-detail #billhead .info span,
.contest-assessment #billhead .info span,
.contest-generate-report #billhead .info span,
.contest-report-success #billhead .info span {
  display: block;
  font-size: 12px;
}

.contests-detail #billhead .info span:nth-child(even),
.contest-assessment #billhead .info span:nth-child(even),
.contest-generate-report #billhead .info span:nth-child(even),
.contest-report-success #billhead .info span:nth-child(even) {
  font-family: 'AtlasG Medium';
}

#info .col-mobile {
  float: left;
}

.contests-detail #info {
  background-color: #ffffff;
}

.contests-detail #info .container {
  position: relative;
  margin: 50px auto 70px;
}

.contests-detail #info h2 {
  margin: 20px 0;
  font-size: 30px;
  font-family: 'AtlasG Medium';
}

.contests-detail #info p {
  display: block;
  margin-bottom: 20px;
}

.contests-detail #info .download-wrapper {
  margin-bottom: 25px;
}

.contests-detail #info .download icon {
  float: left;
  width: 50px;
  margin-top: -6px;
  text-align: center;
  font-size: 36px;
}

.contests-detail #info .download span {
  display: block;
  font-size: 16px;
}

.contests-detail #faqs .send-faq {
  float: left;
  margin: 40px 0px;
}

.contests-detail #faqs .send-faq h3 {
  font-size: 24px;
  font-weight: bold;
}

.contests-detail #faqs .send-faq a {
  padding: 10px 0px;
  font-size: 36px;
  font-family: 'AtlasG Medium';
}

.contests-detail #submeter {
  padding: 40px 0px;
}

.contests-detail #submeter .btn-sub {
  float: right;
  padding: 18px;
  border: 2px solid #000000;
  font-size: 26px;
  font-weight: bold;
  line-height: 26px;
}

.contests-detail #submeter .btn-login-cta {
  float: right;
  width: 100%;
  padding: 10px 0px;
  text-align: right;
  font-size: 20px;
}

.contests-detail .nav {
  position: absolute;
  top: 0px;
  right: 8px;
}

.contests-detail .nav a {
  display: block;
  margin-bottom: 60px;
  font-size: 36px;
}

@media (max-width: 1199px) {
  .contests-detail #billhead h2 {
    font-size: 38px;
    line-height: 44px;
  }
  .contests-detail #billhead h3 {
    font-size: 26px;
  }
  .contests-detail #info .container {
    padding: 0px 80px 50px;
  }
}

@media (max-width: 991px) {
  .contests-detail #billhead .container {
    height: 400px;
  }
  .contests-detail #billhead h3 {
    margin-top: 0px;
  }
  .contests-detail #billhead .allinfo .info {
    margin: 0px 0px;
  }
  .contests-detail #info .container {
    padding: 0px 10px 0px 10px;
  }
  .contests-detail .nav a {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .contests-detail #billhead .allinfo {
    display: none;
  }
}

@media (max-width: 479px) {
  .contests-detail #billhead img {
    width: auto;
    height: 100%;
  }
  .contests-detail #billhead .timer {
    display: block;
    width: 300px;
    margin: auto;
  }
  .contests-detail #billhead .entrega {
    margin-top: 10px;
  }
}

.billhead-with-image {
  overflow: hidden;
}

.billhead-with-image .billhead-bg {
  width: 100%;
  position: absolute;
  z-index: 1;
  top: -100%;
  bottom: -100%;
  left: -100%;
  right: -100%;
  margin: auto;
}

.billhead-with-image .billhead-heading {
  position: relative;
  z-index: 2;
}

.billhead-with-image.submit-box .billhead-heading {
  padding-top: 30px;
}

.billhead-with-image .allinfo {
  z-index: 2;
}

/*/////////////////////////////////////////////////////////////////////////////////////////// PROMOTORES */
.promoter #billhead {
  position: relative;
  height: 540px;
}

.promoter #billhead .container {
  position: relative;
  display: table;
  height: 100%;
  text-align: center;
  z-index: 2;
}

.promoter #billhead .container .center {
  display: table-cell;
  vertical-align: middle;
}

.promoter #billhead .container .center h2 {
  font-size: 48px;
  font-weight: bold;
  color: #fff;
}

.promoter #area {
  padding: 40px 0px;
}

.promoter #area .container {
  height: 0;
  filter: alpha(opacity=0);
  opacity: 0;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

.promoter #area .container.active {
  height: auto;
  filter: alpha(opacity=100);
  opacity: 1;
}

.promoter #area h3 {
  display: none;
}

.promoter #area p {
  display: block;
  width: 100%;
  margin: 40px auto;
  font-size: 16px;
}

.promoter .sub-menu-tabs {
  position: absolute;
  height: 70px;
  width: 100%;
  z-index: 1;
}

.promoter .sub-menu-tabs ul {
  display: table;
  margin: auto;
  padding-top: 18px;
}

.promoter .sub-menu-tabs ul li {
  float: left;
  margin: 0px 10px;
  font-size: 16px;
}

.promoter .sub-menu-tabs ul li a:hover {
  color: #7300fa;
}

.promoter .sub-menu-tabs ul li a.active {
  color: #7300fa;
  font-weight: bold;
}

.promoter #titulo {
  padding: 0px 0px 20px;
}

.promoter #titulo h3 {
  width: 100%;
  font-family: 'AtlasG Black';
  font-size: 32px;
  text-align: center;
}

.promoter #download {
  padding: 20px 0px;
}

.promoter #download a {
  display: table;
  margin: auto;
}

.promoter #download a icon {
  float: left;
  width: 50px;
  text-align: center;
  font-size: 36px;
}

.promoter #download a span {
  float: left;
  font-size: 26px;
  font-weight: bold;
}

.promoter #submeter {
  padding: 40px 0px;
}

.promoter #submeter .btn-sub {
  float: right;
  padding: 18px;
  border: 2px solid #000000;
  font-size: 26px;
  font-weight: bold;
  line-height: 26px;
}

@media (max-width: 991px) {
  .promoter .sub-menu-tabs {
    overflow: auto;
  }
  .promoter .sub-menu-tabs ul {
    box-sizing: content-box;
  }
  .promoter .sub-menu-tabs ul li {
    font-size: 16px;
  }
}

@media (max-width: 767px) {
  .promoter #billhead {
    height: 400px;
  }
  .promoter #billhead .container .center h2 {
    font-size: 38px;
    line-height: 44px;
  }
  .promoter #billhead .container .center h3 {
    font-size: 40px;
    line-height: 42px;
  }
  .promoter #billhead .container .center p span {
    display: block;
  }
  .promoter #billhead img {
    width: auto;
    height: 100%;
  }
  .promoter .sub-menu-tabs {
    display: none;
  }
  .promoter #area {
    padding: 60px 10px;
  }
  .promoter #area .container {
    height: auto;
    filter: alpha(opacity=100);
    opacity: 1;
  }
  .promoter #area h3 {
    display: block;
    width: 100%;
    color: #7300fa;
    font-family: 'AtlasG Black';
    font-size: 36px;
    line-height: 40px;
    text-align: center;
  }
  .promoter #area p {
    width: 100%;
  }
}

@media (max-width: 479px) {
  .promoter #download {
    padding: 20px 0px;
  }
  .promoter #download a icon {
    width: 40px;
    font-size: 26px;
  }
  .promoter #download a span {
    font-size: 16px;
  }
}

.case-study #billhead {
  background: #ffffff;
  text-align: center;
}

.case-study #billhead .container {
  position: relative;
  display: table;
  height: 540px;
  z-index: 2;
}

.case-study #billhead .container.alert {
  height: auto;
}

.case-study #billhead .container .timer {
  color: #fff;
  margin-top: 20px;
  -webkit-transition: 250ms ease-in-out;
  -o-transition: 250ms ease-in-out;
  transition: 250ms ease-in-out;
}

.case-study #billhead .container .timer .missing {
  display: inline-block;
  width: auto;
  font-family: 'AtlasG Medium';
  vertical-align: middle;
}

.case-study #billhead .container .timer .countdown {
  display: inline-block;
  width: auto;
  margin: 0px 8px;
  vertical-align: middle;
}

.case-study #billhead .container .timer .countdown span {
  float: left;
  margin: 0px 6px;
  font-size: 30px;
  font-weight: bold;
}

.case-study #billhead .container .timer .countdown .cell {
  float: left;
}

.case-study #billhead .container .timer .countdown .cell span {
  display: block;
  margin: 0px;
  float: none;
}

.case-study #billhead .container .timer .countdown .cell span:nth-child(even) {
  font-size: 11px;
}

.case-study #billhead .container .timer .entrega {
  display: inline-block;
  width: 160px;
  vertical-align: middle;
  text-align: left;
}

.case-study #billhead .container .timer .entrega icon {
  float: left;
  width: 50px;
  text-align: center;
  font-size: 36px;
}

.case-study #billhead .container .timer .entrega span {
  display: block;
  font-size: 12px;
}

.case-study #billhead .container .timer .entrega span:nth-child(even) {
  font-family: 'AtlasG Medium';
}

.case-study #billhead .container:hover .timer {
  color: #7300fa;
}

.case-study #billhead .center {
  display: table-cell;
  vertical-align: middle;
  margin-top: -100px;
}

.case-study #billhead h2 {
  font-size: 48px;
  font-weight: bold;
}

.case-study #billhead h3 {
  font-size: 36px;
  margin-top: -80px;
}

.case-study #billhead .allinfo {
  position: absolute;
  bottom: 0;
  left: 0px;
  width: 100%;
}

.case-study #billhead .info {
  height: auto;
  padding: 5px 15px;
  margin: 20px 0px;
}

.case-study #billhead .info .middle {
  display: table;
  margin: auto;
  text-align: left;
}

.case-study #billhead .info icon {
  display: table-cell;
  vertical-align: middle;
  width: 50px;
  text-align: center;
  font-size: 36px;
}

.case-study #billhead .info span {
  display: block;
  font-size: 12px;
}

.case-study #billhead .info span:nth-child(even) {
  font-family: 'AtlasG Medium';
}

.case-study #info {
  background-color: #ffffff;
}

.case-study #info .container {
  position: relative;
  margin: 50px auto 70px;
}

.case-study #info h2 {
  margin-bottom: 20px;
  font-size: 16px;
  font-family: 'AtlasG Medium';
}

.case-study #info p {
  display: block;
  margin-bottom: 20px;
}

.case-study #info .download {
  float: left;
  width: 33%;
  margin-bottom: 20px;
}

.case-study #info .download icon {
  float: left;
  width: 50px;
  margin-top: -6px;
  text-align: center;
  font-size: 36px;
}

.case-study #info .download span {
  display: block;
  font-size: 16px;
}

.case-study #submeter {
  padding: 40px 0px;
}

.case-study #submeter .btn-sub {
  float: right;
  padding: 18px;
  border: 2px solid #000000;
  font-size: 26px;
  font-weight: bold;
  line-height: 26px;
}

@media (max-width: 991px) {
  .case-study #billhead .container {
    height: 400px;
  }
  .case-study #billhead h2 {
    font-size: 38px;
    line-height: 44px;
  }
  .case-study #billhead h3 {
    font-size: 26px;
    margin-top: 0px;
  }
  .case-study #billhead .allinfo .info {
    margin: 0px 0px;
  }
  .case-study #info .container {
    padding: 0px 10px 0px 10px;
  }
  .case-study .nav a {
    font-size: 30px;
  }
}

@media (max-width: 767px) {
  .case-study #billhead .allinfo {
    display: none;
  }
}

@media (max-width: 479px) {
  .case-study #billhead img {
    width: auto;
    height: 100%;
  }
}

.contest-type-description-wrapper h6 {
  margin-top: 15px;
}

.contest-type-description-wrapper #contest-type-description {
  float: none;
  color: #000000;
  margin-left: 70px;
  margin-bottom: 0;
  display: block;
}

/*------- simulations -------*/
.disclaimer {
  margin: 100px auto;
  width: 1040px;
}

.disclaimer .terms {
  text-align: center;
  margin-bottom: 30px;
}

.disclaimer .message {
  margin-bottom: 50px;
}

.disclaimer .cell {
  text-align: center;
}

.disclaimer .cell .form-group {
  float: none;
  text-align: center;
  margin-bottom: 50px;
}

.info-btn {
  font-size: 18px;
  padding: 0;
}

.info-btn .fa-info-circle {
  padding-left: 3px;
}

.budget-staff-employee-edit .popover,
.budget-info-edit .popover,
.budget-params-edit .popover,
.budget-structure-edit .popover,
.simulation-step-1 .popover,
.simulation-step-2 .popover {
  min-height: 200px;
  padding: 20px;
  transition: opacity 250ms ease-in-out;
}

.budget-staff-employee-edit .popover .close,
.budget-info-edit .popover .close,
.budget-params-edit .popover .close,
.budget-structure-edit .popover .close,
.simulation-step-1 .popover .close,
.simulation-step-2 .popover .close {
  right: 20px;
}

.budget-staff-employee-edit .right,
.budget-info-edit .right,
.budget-params-edit .right,
.budget-structure-edit .right,
.simulation-step-1 .right,
.simulation-step-2 .right {
  margin-left: 20px;
  margin-top: -7px;
}

.budget-staff-employee-edit .left,
.budget-info-edit .left,
.budget-params-edit .left,
.budget-structure-edit .left,
.simulation-step-1 .left,
.simulation-step-2 .left {
  margin-left: -15px;
  margin-top: -7px;
}

.budget-staff-employee-edit .form-group,
.budget-info-edit .form-group,
.budget-params-edit .form-group,
.budget-structure-edit .form-group,
.simulation-step-1 .form-group,
.simulation-step-2 .form-group {
  overflow: initial;
}

.budget-staff-employee-edit .form-group label,
.budget-info-edit .form-group label,
.budget-params-edit .form-group label,
.budget-structure-edit .form-group label,
.simulation-step-1 .form-group label,
.simulation-step-2 .form-group label {
  display: inline;
}

.budget-staff-employee-edit .form-group select,
.budget-info-edit .form-group select,
.budget-params-edit .form-group select,
.budget-structure-edit .form-group select,
.simulation-step-1 .form-group select,
.simulation-step-2 .form-group select {
  height: 30px;
  margin: 0;
}

.budget-staff-employee-edit .total,
.budget-info-edit .total,
.budget-params-edit .total,
.budget-structure-edit .total,
.simulation-step-1 .total,
.simulation-step-2 .total {
  text-align: right;
}

.simulation-step-3 .popover,
.simulation-step-4 .popover {
  min-height: 200px;
  padding: 20px;
  transition: opacity 250ms ease-in-out;
}

.simulation-step-3 .popover .close,
.simulation-step-4 .popover .close {
  right: 20px;
}

.simulation-step-3 .right,
.simulation-step-4 .right {
  margin-left: 20px;
  margin-top: -7px;
}

.simulation-step-3 .left,
.simulation-step-4 .left {
  margin-left: -15px;
  margin-top: -7px;
}

.simulation-step-3 .info-btn {
  margin-top: 12px;
}

/*------- splash-screen -------*/
.hint {
  text-align: center;
}

/*------- end splash-screen -------*/
#phases .tabs .tab div .headers {
  overflow: visible;
}

#phases .section-title {
  font-size: 30px;
  margin-bottom: 30px;
}

#phases .phase {
  text-align: left;
}

#phases .phase .phase-total {
  float: right;
}

#phases .wrapper a:after {
  content: '';
}

#phases .wrapper .subphase {
  margin-top: 20px;
}

#phases .wrapper .subphase .subphase-title {
  height: 65px;
  padding-top: 18px;
  margin-bottom: 30px;
}

#phases .wrapper .subphase .subphase-title .duration {
  padding: 10px;
  border: 2px solid #000;
}

#phases .wrapper .subphase .subphase-title .duration input {
  border: none;
  width: 40px;
  font-size: 16px;
  text-align: center;
}

#phases .wrapper .subphase .subphase-title .info {
  margin-left: 5px;
  cursor: pointer;
}

#phases .wrapper .subphase .headers {
  font-weight: 700;
  padding: 5px;
  overflow: visible;
}

#phases .wrapper .subphase .add-resource {
  margin-top: 30px;
  color: #000000;
}

#phases .wrapper .subphase .resource-select {
  width: 50%;
}

#phases .wrapper .subphase .not-editable {
  opacity: 0.3;
  height: 45px;
}

#phases .wrapper .subphase .not-editable .field {
  height: 100%;
  border: 2px solid #000;
  padding: 10px 28px;
}

#phases .wrapper .subphase .not-editable .no-border {
  border: none;
  padding: 10px 0;
  text-align: center;
}

#phases .wrapper .subphase .resource {
  position: relative;
}

#phases .wrapper .subphase .resource .select {
  height: 45px;
}

#phases .wrapper .subphase .resource .select select {
  height: initial;
  display: initial;
}

#phases .wrapper .subphase .resource .form-group.select:after {
  top: 7px;
  padding-top: 0;
}

#phases .wrapper .subphase .resource .input {
  height: 45px;
  padding: 0;
}

#phases .wrapper .subphase .resource .input input {
  padding: 0;
  height: 100%;
  margin-left: 20px;
  width: 20px;
  display: inline-block;
}

#phases .wrapper .subphase .resource .result {
  width: 100%;
  padding-top: 10px;
  text-align: center;
}

#phases .wrapper .subphase .resource .result .value {
  font-family: 'AtlasG Medium';
  font-size: 16px;
}

#phases .wrapper .subphase .resource .remove-resource {
  font-size: 26px;
}

#phases .wrapper .subphase .resource .remove-resource span {
  cursor: pointer;
  float: right;
  padding-right: 25px;
}

#phases .phases-total {
  font-size: 19px;
  font-weight: 700;
  float: right;
  padding: 10px 50px;
}

/*------- simulation preview screen -------*/
.project-info {
  padding-bottom: 20px;
  margin-bottom: 20px;
}

.project-info .row {
  margin: 0;
}

.project-info .section-title {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 900;
  font-size: 20px;
}

.project-info .field-name {
  font-weight: bold;
  float: right;
}

.project-info .field-info {
  color: #7f7f7f;
  float: left;
}

.project-phases .row {
  margin: 0;
}

.project-phases .section-title {
  text-align: center;
  font-weight: 900;
  font-size: 20px;
}

.project-phases .cell {
  padding: 0;
}

.project-phases .phase {
  font-weight: 900;
  padding-top: 20px;
}

.project-phases .phase .phase-fields {
  margin-top: 20px;
}

.project-phases .subphase {
  margin-top: 20px;
}

.project-phases .subphase .subphase-name {
  margin-bottom: 10px;
}

.project-phases .resource-field {
  padding: 5px 0;
}

.project-phases .resource {
  margin-top: 10px;
  border: 2px solid #000;
}

.project-phases .total-row {
  font-size: 24px;
  font-weight: 700;
  margin-top: 30px;
}

.project-phases .total-row .total-label {
  text-align: right;
}

.project-phases .total {
  text-align: center;
}

.complementary-values .row, .payment-schedule .row {
  margin: 0;
}

.complementary-values .section-title, .payment-schedule .section-title {
  text-align: center;
  font-weight: 900;
  font-size: 20px;
  margin-top: 20px;
}

.complementary-values .subsection-title, .payment-schedule .subsection-title {
  margin: 20px 0;
}

.complementary-values .subsection-total, .payment-schedule .subsection-total {
  text-align: center;
}

.complementary-values .service, .payment-schedule .service {
  margin-top: 10px;
  border: 2px solid #000;
}

.complementary-values .service .service-field, .payment-schedule .service .service-field {
  padding: 5px 0;
}

.complementary-values .service .description, .payment-schedule .service .description {
  padding: 15px;
}

.complementary-values .total-row, .payment-schedule .total-row {
  font-size: 24px;
  font-weight: 700;
  margin-top: 30px;
}

.complementary-values .total-row .total-label, .payment-schedule .total-row .total-label {
  text-align: right;
}

.complementary-values .total, .payment-schedule .total {
  text-align: center;
}

.complementary-values .payment-description, .payment-schedule .payment-description {
  border: 2px solid #000;
  margin: 30px 0;
  padding: 20px;
}

.project-phases-descriptions {
  font-size: 14px;
}

.project-phases-descriptions .left {
  text-align: left;
}

.project-phases-descriptions .two-columns {
  width: 25%;
}

.project-phases-descriptions .six-columns {
  width: 75%;
}

.project-phases-descriptions .right {
  text-align: right;
}

.project-phases-descriptions .top {
  vertical-align: top;
}

.project-phases-descriptions .sub {
  margin-left: 15px;
}

.project-phases-descriptions .sub-sub {
  margin-left: 30px;
}

.project-phases-descriptions .subphase-title {
  padding-left: 20px;
}

.project-phases-descriptions .resource-field-label {
  font-size: 16px;
  font-weight: bolder;
  padding: 20px 0;
  font-weight: bold;
}

.project-phases-descriptions p {
  margin-bottom: 10px;
}

.project-phases-descriptions .section-title {
  text-align: center;
  font-weight: 900;
  font-size: 20px;
}

.back-button {
  margin-top: 20px;
  width: 100%;
  text-align: center;
}

.back-button .back-link {
  font-size: 40px;
  margin: 0 20px;
}

/*-------- project-diagram -------*/
.diagram-wrapper {
  margin: 50px 0;
  min-width: 940px;
}

.diagram-wrapper .section-title {
  text-align: center;
  margin-bottom: 20px;
  font-weight: 900;
  font-size: 20px;
}

.diagram-wrapper #diagram {
  position: relative;
}

.diagram-wrapper #diagram .row.phase .row.phase-content {
  margin-bottom: 2px;
}

.diagram-wrapper #diagram .row.phase .row.phase-content .phase-duration-gr {
  height: 20px;
  padding: 0;
}

.diagram-wrapper #diagram .row.phase .row.phase-content .phase-duration-gr .before {
  border-top: 2px solid #000;
  float: left;
  height: 10px;
  margin-top: 10px;
}

.diagram-wrapper #diagram .row.phase .row.phase-content .phase-duration-gr .duration {
  position: relative;
  float: left;
  border: 2px solid #000;
  height: 100%;
}

.diagram-wrapper #diagram .row.phase .row.phase-content .phase-duration-gr .duration .line {
  position: absolute;
  width: 100%;
  height: calc(50% + 2px);
  border-bottom: 2px solid #000;
}

.diagram-wrapper #diagram .row.phase .subphase-content .subphase-name {
  padding-left: 20px;
}

.diagram-wrapper #diagram .row.phase .subphase-content .subphase-duration-gr {
  height: 20px;
  padding: 0;
}

.diagram-wrapper #diagram .row.phase .subphase-content .subphase-duration-gr .before {
  float: left;
  height: 10px;
}

.diagram-wrapper #diagram .row.phase .subphase-content .subphase-duration-gr .duration {
  float: left;
  background-color: #000;
  height: 100%;
}

.diagram-wrapper .scale {
  margin-bottom: 0;
}

.diagram-wrapper .scale .week-scale {
  position: initial;
  border-bottom: 2px solid black;
}

.diagram-wrapper .scale .week-scale .week {
  display: inline-block;
  vertical-align: bottom;
  margin-bottom: -2px;
  height: 20px;
}

.diagram-wrapper .scale .week-scale .week:before {
  content: '';
  position: absolute;
  height: calc(100% - 20px);
  width: 2px;
  bottom: 20px;
  background-color: #7300fa;
  visibility: hidden;
}

.diagram-wrapper .scale .week-scale .week:after {
  content: '';
  width: 2px;
  height: 7px;
  background-color: #000;
  display: inline-block;
  margin-bottom: -1px;
}

.diagram-wrapper .scale .week-scale .week:hover:before {
  visibility: visible;
}

.diagram-wrapper .scale .week-scale .month:after {
  height: 10px;
}

.diagram-wrapper .scale .col {
  position: initial;
}

.diagram-wrapper .scale-legend .legend-name {
  text-align: right;
  font-size: 10px;
  padding-right: 15px;
}

.diagram-wrapper .scale-legend .week-scale-legend {
  font-size: 8px;
  margin-top: 3px;
  margin-left: -3px;
  white-space: nowrap;
}

.diagram-wrapper .scale-legend .week-scale-legend .week-legend {
  display: inline-block;
}

/*------- raw row -------*/
.raw {
  margin: 0;
  padding: 0;
}

.row .raw .scale {
  padding: 0;
}

.submit-box .raw {
  margin: 0;
  padding: 0;
}

.submit-box .raw .week-scale {
  margin-top: 3px;
}

.submit-box .raw .legend-name {
  text-align: right;
  font-size: 10px;
  padding-right: 15px;
}

.personal-box .row, .submit-box .row {
  margin-bottom: 2px;
}

/*------- dashboard -------*/
.budget-simulations .personal-box .container,
.budget-params-edit .personal-box .container,
.budget-info-edit .personal-box .container,
.budget-structure-edit .personal-box .container,
.budget-staff-edit .personal-box .container,
.budget-staff-employee-edit .personal-box .container,
.budget-simulations-simulation-success .personal-box .container {
  width: 1140px;
  height: 300px;
  overflow: hidden;
}

.budget-simulations .personal-box .container h2,
.budget-params-edit .personal-box .container h2,
.budget-info-edit .personal-box .container h2,
.budget-structure-edit .personal-box .container h2,
.budget-staff-edit .personal-box .container h2,
.budget-staff-employee-edit .personal-box .container h2,
.budget-simulations-simulation-success .personal-box .container h2 {
  position: relative;
  z-index: 2;
  margin-top: 100px;
  color: #fff;
}

.budget-simulations .personal-box .selected-profile,
.budget-params-edit .personal-box .selected-profile,
.budget-info-edit .personal-box .selected-profile,
.budget-structure-edit .personal-box .selected-profile,
.budget-staff-edit .personal-box .selected-profile,
.budget-staff-employee-edit .personal-box .selected-profile,
.budget-simulations-simulation-success .personal-box .selected-profile {
  position: relative;
  font-weight: 900;
  padding: 10px;
  margin-bottom: 30px;
}

.budget-simulations .personal-box .selected-profile .avatar,
.budget-params-edit .personal-box .selected-profile .avatar,
.budget-info-edit .personal-box .selected-profile .avatar,
.budget-structure-edit .personal-box .selected-profile .avatar,
.budget-staff-edit .personal-box .selected-profile .avatar,
.budget-staff-employee-edit .personal-box .selected-profile .avatar,
.budget-simulations-simulation-success .personal-box .selected-profile .avatar {
  position: relative;
  z-index: 2;
  width: 120px;
  height: 120px;
  margin: 10px auto 15px auto;
  border-radius: 60px;
  background-size: cover;
  background-position: 50% 50%;
}

.budget-simulations .personal-box .selected-profile .button,
.budget-params-edit .personal-box .selected-profile .button,
.budget-info-edit .personal-box .selected-profile .button,
.budget-structure-edit .personal-box .selected-profile .button,
.budget-staff-edit .personal-box .selected-profile .button,
.budget-staff-employee-edit .personal-box .selected-profile .button,
.budget-simulations-simulation-success .personal-box .selected-profile .button {
  font-size: 22px;
}

.budget-simulations .personal-box .container {
  width: 1140px;
  height: 300px;
  overflow: hidden;
}

.budget-simulations .personal-box .container h2 {
  position: relative;
  z-index: 2;
  margin-top: 100px;
  color: #fff;
}

.budget-simulations #dashboard .terms {
  margin-top: 50px;
  cursor: pointer;
}

.budget-simulations #dashboard .selected-profile {
  position: relative;
  font-weight: 900;
  padding: 10px;
  margin-bottom: 30px;
}

.budget-simulations #dashboard .selected-profile .avatar {
  position: relative;
  z-index: 2;
  width: 120px;
  height: 120px;
  margin: 10px auto 15px auto;
  border-radius: 60px;
  background-size: cover;
  background-position: 50% 50%;
}

.budget-simulations #dashboard .selected-profile .button {
  font-size: 22px;
  cursor: pointer;
}

.budget-simulations #dashboard .selected-profile .button:hover:after {
  border-color: #7300fa;
}

.budget-simulations #dashboard .selected-profile .button:after {
  content: '';
  position: absolute;
  width: 10px;
  height: 10px;
  margin: 7px 16px;
  border-bottom: 2px solid #000;
  border-left: 2px solid #000;
  transform: rotate(-45deg);
}

.budget-simulations #dashboard .selected-profile .active {
  color: #7300fa;
}

.budget-simulations #dashboard .selected-profile .active:after {
  border-color: #7300fa;
  margin: 12px 16px;
  transform: rotate(-225deg);
}

.budget-simulations #dashboard .selected-profile .hint {
  margin-top: 0;
}

.budget-simulations #dashboard .profile-grid {
  margin: 0 auto 20px auto;
  width: 400px;
  border: 2px solid #000;
}

.budget-simulations #dashboard .profile-grid .profile {
  display: inline-block;
  margin: 15px;
  cursor: pointer;
}

.budget-simulations #dashboard .profile-grid .profile:hover {
  color: #7300fa;
}

.budget-simulations #dashboard .simulations .add-new {
  margin: 0 0 30px 0;
}

.budget-simulations #dashboard .simulations .simulation-header {
  margin-bottom: 15px;
}

.budget-simulations #dashboard .simulations .year {
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
  cursor: pointer;
}

.budget-simulations #dashboard .simulations .year:hover {
  color: #7300fa;
}

.budget-simulations #dashboard .sections {
  margin-top: 50px;
  display: inline-block;
}

.budget-simulations #dashboard .sections .section {
  box-sizing: content-box;
  border: 2px solid #000;
  width: 266px;
  height: 266px;
  float: left;
  margin-right: 20px;
  margin-top: 20px;
}

.budget-simulations #dashboard .sections .section .icon {
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 50px;
}

.budget-simulations #dashboard .sections .section .title {
  display: block;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
}

.budget-simulations #dashboard .sections .section .impediment .icon {
  font-size: 16px;
}

.budget-simulations #dashboard .sections .staff {
  margin-right: 0;
}

.budget-simulations #dashboard .expand-transition {
  transition: all .3s ease;
}

.budget-simulations #dashboard .expand-transition .profile {
  opacity: 1;
}

.budget-simulations #dashboard .expand-enter, .budget-simulations #dashboard .expand-leave {
  height: 0;
  opacity: 0;
  margin-bottom: 0;
}

.budget-simulations #dashboard .expand-enter .profile, .budget-simulations #dashboard .expand-leave .profile {
  opacity: 0;
}

/*------- block screen -------*/
.error-message {
  text-align: center;
  padding: 10px;
  border: 2px solid #000;
  width: 1040px;
  margin: 30px auto;
}

.no-hover:hover {
  color: #000;
}

/*------- other services -------*/
#services .section-title {
  font-size: 30px;
  margin-bottom: 30px;
}

#services .services .multi .tab .tab-title {
  text-align: left;
}

#services .services .multi .tab .tab-title .total {
  float: right;
}

#services .services .multi .tab .wrapper .select {
  height: 45px;
}

#services .services .multi .tab .wrapper .select select {
  height: initial;
}

#services .services .multi .tab .wrapper .form-group.select:after {
  top: 7px;
  padding-top: 0;
}

#services .services .multi .tab .wrapper .input {
  height: 45px;
  padding: 0;
}

#services .services .multi .tab .wrapper .input input {
  height: 100%;
  margin-left: 20px;
}

#services .services .multi .tab .wrapper .remove-service {
  height: 45px;
  position: relative;
}

#services .services .multi .tab .wrapper .remove-service .remove {
  cursor: pointer;
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  display: block;
  right: 20px;
  font-size: 26px;
}

#services .services .multi .tab .wrapper .add-new {
  margin-top: 30px;
}

#services .services .multi .tab .wrapper .add-new a {
  color: #000;
}

#services .services .multi .tab .wrapper .add-new a:hover {
  color: #7300fa;
}

#services .services .multi .tab .wrapper .add-new a:after {
  content: '';
}

#services .services .multi .tab .wrapper .other-values {
  height: 45px;
  padding: 0;
}

#services .services .multi .tab .wrapper .other-values input {
  height: 45px;
  margin-left: 20px;
}

#services .services .budget-total {
  float: right;
  padding: 10px 50px;
  font-size: 19px;
}

.padder {
  margin-top: 30px;
}

/*------- vue css part -------*/
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  position: relative;
  width: 600px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border: 2px solid #000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-container .header {
  font-size: 20px;
  margin-bottom: 20px;
}

.modal-container .content {
  width: 100%;
  max-height: 300px;
  overflow: scroll;
}

.modal-container .footer {
  position: relative;
  height: 60px;
  width: 100%;
  display: table;
}

.modal-container .footer .dismiss-button {
  display: table-cell;
  vertical-align: middle;
  /* position: absolute;
          margin: auto;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;*/
}

.modal-container .footer .dismiss-button button {
  font-size: 16px;
}

.modal-default-button {
  float: right;
}

/*
 * the following styles are auto-applied to elements with
 * v-transition="modal" when their visiblity is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */
.modal-enter, .modal-leave {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

[v-cloak] {
  display: none;
}

.assessment-popover {
  min-height: 200px;
  padding: 20px;
  margin-left: 25px;
  margin-top: -7px;
  transition: opacity 250ms ease-in-out;
}

.assessment-popover .close {
  right: 20px;
}
