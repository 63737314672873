@mixin calc($property, $expression) {
  #{$property}: -webkit-calc(#{$expression});
  #{$property}: calc(#{$expression});
}

@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}

@mixin responseSize($property, $df-size:null, $md-size:null, $sm-size:null, $xs-size:null) {
    #{$property}:#{$df-size};
    @media (max-width:$screen-md-max) {
        #{$property}:#{$md-size};
    }
    @media (max-width:$screen-sm-max) {
        #{$property}:#{$sm-size};
    }
    @media (max-width:$screen-xs-max) {
        #{$property}:#{$xs-size};
    }
}
