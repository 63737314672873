$grid-columns: 24;
$grid-gutter-width: 10px;

$colorDefault: #000000;
$colorActive: #7300fa;
$colorSecondary: #7f7f7f;
$colorFade: #e5e5e5;
$colorDanger: red;
$bgColor: #ffffff;
$heightHeader: 170px;
$heightFooter: 170px;
$animatDefult: 250ms ease-in-out;
$maxWidth: 1150px;
