/*/////////////////////////////////////////////////////////////////////////////////////////// ANIMATE */
/* HAMBURGER */
$burg_timer: 250ms forwards ease-in-out;

@include keyframes(open_burg_samp) {
    0% {height:5px; margin:5px 0px 0px;}
    50% {height:0px; margin:1px 0px 0px;}
    100% {height:0px; margin:1px 0px 0px;} 
}
$open-burg_samp: open_burg_samp $burg_timer;

@include keyframes(close_burg_samp) {
    0% {height:0px; margin:0px 0px;}
    50% {height:0px; margin:0px 0px;}
    100% {height:5px; margin:5px 0px;} 
}
$close-burg_samp: close_burg_samp $burg_timer;

@include keyframes(open_burg_before) {
    0% {transform: rotate(0deg); margin-top:0px;}
    50% {transform: rotate(0deg); margin-top:5px;}
    100% {transform: rotate(45deg); margin-top:10px;}
}
$open-burg_before: open_burg_before $burg_timer;

@include keyframes(close_burg_before) {
    0% {transform: rotate(45deg); margin-top:10px;}
    50% {transform: rotate(0deg); margin-top:5px;}
    100% {ransform: rotate(0deg); margin-top:0px;}
}
$close-burg_before: close_burg_before $burg_timer;

@include keyframes(open_burg_after) {
    0% {transform: rotate(0deg); margin-top:0px;}
    50% {transform: rotate(0deg); margin-top:-6px;}
    100% {transform: rotate(-45deg); margin-top:-6px;}
}
$open-burg_after: open_burg_after $burg_timer;

@include keyframes(close_burg_after) {
    0% {transform: rotate(-45deg); margin-top:-6px;}
    50% {transform: rotate(0deg); margin-top:-6px;}
    100% {transform: rotate(0deg); margin-top:0px;}
}
$close-burg_after: close_burg_after $burg_timer;
