@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/mixins";
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/utilities";

@import "variables";
@import "mixins";
@import "fonts";
@import "icons";
@import "grid";
@import "grayscale";
@import "hamburger";
@import "modals";
@import "notifications";
@import "slider";
@import "paging";

@import "showcase";
@import "map";
@import "assessment";
@import "datepicker";

/*/////////////////////////////////////////////////////////////////////////////////////////// NORMALISE */
html,body,div,pre,form,fieldset,input,h1,h2,h3,h4,h5,h6,p,textarea,ul,ol,li,dl,dt,dd,blockquote,th,td,
/* HTML 5 elements */ abbr,article,aside,audio,canvas,details,figcaption,figure,footer,header,hgroup,
mark,menu,meter,nav,output,progress,section,summary,time,video { margin: 0; padding: 0;}
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section,main{position:relative; display:block; width:100%; height:auto; @include box-sizing(border-box);}
table { border-collapse: collapse; border-spacing: 0; }
fieldset,img { border: 0; }
address,caption,cite,code { font-style: normal; font-weight: normal; }
ul { list-style: none; }
caption,th { text-align: left; }
h1,h2,h3,h4,h5,h6 { font-size: 100%; font-weight: normal; }
q:before,q:after { content: ''; }
abbr,acronym { border: 0; }
a { outline:none; text-decoration:none; color:$colorDefault; @include transition($animatDefult);} a img { border: none; } a:hover { color:$colorActive; }
:focus { outline: none; }/* For Chrome */
*, *:before, *:after { @include box-sizing(border-box);}
body,html{position:relative; width:100%; height:100%; min-width:320px; margin:auto; background:$bgColor; color:$colorDefault; font-family:'AtlasG Regular', sans-serif; font-size:100%;}
body.no-scroll{overflow:hidden;}

.sr-only {
	position: absolute;
	width: 1px;
	height: 1px;
	margin: -1px;
	padding: 0;
	overflow: hidden;
	clip: rect(0,0,0,0);
	border: 0;
}

.clearfix {
	@include clearfix();
}

.grecaptcha-badge {
    display: none !important;
}

.action {

	&:hover {
		color:$colorActive;
	}
}

main {
	min-height:100%;
	@include responseSize(padding-top, $heightHeader, $sm-size:140px, $xs-size:60px);
	@include responseSize(padding-bottom, $heightFooter, $xs-size:140px);
	@include transition($animatDefult);
	z-index:2;

	&.fixed{
		@include responseSize(padding-top, $df-size:90px, $xs-size:60px);
	}
}

.mini-header + main {
	padding-top: 0px;
}

section {
	@include opacity(1);
	@include transition($animatDefult);

	&.show {
		@include opacity(1);
	}
}

.container {

	&.right-nav {
		padding-right: 80px;
	}
}

button {
    border: 0px;
    background: none;
    font-size: 40px;
    cursor: pointer;
    @include transition($animatDefult);

    &:hover {
        color: $colorActive
    }
}

a, button {

	&.btn {
		outline: 0;
		padding: 0;
		border: 0;

	    &:hover {
	        color: $colorActive
	    }
	}
}

.backgrd{
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.cor {
	color: $colorActive;
}

.fade {
	color: $colorSecondary;
}

p > a {
	text-decoration: underline;
}

.help-block {
	margin: 3px 0 15px 0;
	font-size: 12px;

	&.error{
		color: $colorDanger;
	}
}

.tipografia{
	@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap/type";

	ol{
		margin-left: 25px;
	}
}

.tag-box{float:left; width:100%; margin-bottom:10px;
	li{float:left; padding:14px 30px; margin-right:10px; margin-bottom:10px; border:2px solid $colorDefault; font-size:14px; line-height:12px; @include transition($animatDefult);
		icon{margin-left:5px;}
		&:hover{color:$colorActive; cursor:pointer;}
	}
}

/*/////////////////////////////////////////////////////////////////////////////////////////// HEADER */
header {
	position:fixed;
	@include responseSize(height, $heightHeader, $sm-size: 140px, $xs-size: 60px!important);
	background:$bgColor;
	padding-left: 40px!important;
	padding-right: 40px!important;
	padding-top: 40px;
	z-index: 8;
	@include transition($animatDefult);

	.center {
		max-width: $maxWidth;
		position: absolute;
		left: 0px;
		right: 0px;
		width: 100%;
		margin: auto;
		padding: 0px $grid-gutter-width/2;
		@include transition($animatDefult);
        .active {
            color: $colorActive;
        }
	}

    nav {

        .header-titulo {
            float: left;
            @include transition(10ms ease-in-out);

            span {
                display: block;
                max-height: 50px;
                font-family: 'AtlasT Regular';
                font-size: 16px;
                overflow: hidden;
                @include transition($animatDefult);
            }

            h2 {
                font-family: 'AtlasG Black';
                font-size: 32px;
                @include transition($animatDefult);
            }
        }

        .header-login {
            float: right;
            margin-top: 0px;
            max-height: 45px;
            position: relative;
            right: 5px;

            .lang {
                display: inline-block;
                /*display: none;*/
                vertical-align: top;
                margin: 0px 5px;
            }

            .btn-login,
            .btn-logout,
            .btn-alerta {
                display: inline-block;
                position: relative;
                margin: 0px 5px;
                cursor: pointer;

                span {
                    display: inline-block;
                    max-width: 130px;
                    margin: 0px 5px;
                    font-size: 14px;
                    line-height: 14px;
                    text-align: right;
                    vertical-align: middle;
                    @include transition($animatDefult);
                }

                icon {
                    font-size: 36px;
                    vertical-align: middle;
                    @include transition($animatDefult);
                }

                &:hover {
                    color: $colorActive;

                    icon {
                        color: $colorActive;
                    }
                }
            }

			.btn-logout{
                cursor:default;
                overflow:hidden;

				span{color:$colorActive;
					small{color:$colorDefault; font-size:12px;}
				}
			}
			.btn-alerta{
                float: right;

                icon.fa-bell {
                    @include opacity(1);
                }

                icon.fa-times-circle {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    text-align: center;
                    @include opacity(0);
                }

                .ball {
                    position: absolute;
                    right: -15px;
                    bottom: -5px;
                    border-radius: 20px;
                    width: auto;
                    text-align: center;
                    background: $colorDefault;
                    color: white;
                    border: 2px solid white;
                    font-size: 12px;
                    padding: 2px 7px;
                    line-height: 18px;
                    @include transition($animatDefult);
                }

                &:hover {

                    .ball {
                        background: $colorActive;
                    }
                }
			}
		}
		menu{position:absolute; top:60px; right:0; min-width:0; width:auto; @include transition($animatDefult);
			ul{display:table; margin:auto;
				li{float:left; margin:0px 10px; font-size:20px;
					a{
						&:hover{color:$colorActive;}
						&.active{color:$colorActive; font-weight:bold}
					}
				}
			}
		}
		.burger{position:absolute; right:10px; width:34px; height:22px; margin-top:2px; z-index:2; cursor:pointer;
			span{float:left; width:34px; height:5px; margin:5px 0px; background-color:$colorDefault; @include animation($close-burg_samp); @include transition($animatDefult); border-radius:2px;}
			&:before{content:''; float:left; width:34px; height:5px; background-color:$colorDefault; @include animation($close-burg_before); @include transition($animatDefult); border-radius:2px;}
			&:after{content:''; float:left; width:34px; height:5px; background-color:$colorDefault; @include animation($close-burg_after); @include transition($animatDefult); border-radius:2px;}
		}
	}
	.box-alerta{position:fixed; top:0; right:-360px; width:328px; height:100%; background:$bgColor; border-left:2px solid $colorDefault; z-index:10; @include transition($animatDefult);
		&:after, &:before{content:''; position:absolute; left:-30px; top:28px; height:0; width:0; border-top:30px solid transparent; border-bottom:30px solid transparent;}
		&:before{border-right:32px solid $colorDefault; border-top:32px solid transparent; border-bottom:32px solid transparent; margin:-2px 0px 0px -2px; z-index:1;}
		&:after{border-right:30px solid $bgColor; z-index:2;}
	}
	&.fixed, &.mini-header{height:90px; padding-top:34px;
		.header-titulo{
			span{max-height:0px;}
			h2{font-size:18px;}
		}
		.header-login{float:right; margin-top:-6px;}
		menu{top:0px; right:230px;
			ul{
				li{font-size:18px;}
			}
		}
		.box-alerta{
			&:after, &:before{top:16px;}
		}
	}
	&.mini-header{position:relative; height:80px; padding-top:28px; background-color:transparent;
		.logout{position:absolute; left:0; right:0; width:80px; margin:auto; text-align:center;}
		.back-home{float:right;
			icon{font-size:36px; vertical-align:middle;}
		}
	}
	&.open-alert{
		nav{
			.header-login{
				.btn-alerta{
					icon.fa-bell, .ball {@include opacity(0);}
					icon.fa-times-circle{@include opacity(1);}
				}
			}
		}
		.box-alerta{right:0;}
	}
	&.open-menu{
		.burger{
			span{@include animation($open-burg_samp);}
			&:before{@include animation($open-burg_before);}
			&:after{@include animation($open-burg_after);}
		}
	}
}

@media (max-width: 1850px) {
	header{
		&.open-alert{
			.center{left:inherit; right:350px;}
		}
	}
}

@media (max-width:$screen-md-max) {
	header{padding-left:10px !important; padding-right:10px !important;
		.center{margin-left:0px;}
		nav{
			.header-titulo{
				h2{font-size:30px;}
			}
			menu{
				ul{
					li{float:left; margin:0px 8px; font-size:18px;}
				}
			}
		}
		&.fixed{
			.header-titulo{
				h2{font-size:16px;}
			}
			menu{
				ul{
					li{font-size:16px;}
				}
			}
		}
		&.open-alert{
			.center{left:0px; right:0px; margin-left:-350px;}
		}
	}
}

@media (max-width:$screen-sm-max) {
	header{padding-top:34px;
		nav{
			.header-titulo{
				span{font-size:12px;}
				h2{font-size:26px;}
			}
			.header-login{max-height:0; @include opacity(0);}
			menu{top:0px;
				ul{
					li{font-size:16px;}
				}
			}
		}
		&.fixed{
			menu{top:0px; right:0px;}
		}
		&.open-alert{
			.center{margin-left:0px;}
		}
	}
}

@media (max-width:$screen-xs-max) {
	header{padding-top:16px !important;
		nav{
			.header-titulo{position:relative; z-index:1;
				span{font-size:0px;}
				h2{font-size:16px !important;}
			}
			menu{position:fixed; left:-100%; width:250px; height:100%; background-color:$bgColor; z-index:1; padding-top:60px; z-index:0;
				ul{width:100%; height:100%;
					li{display:table; width:100%; height:14%; padding:0px 20px; margin:0; font-size:30px !important; @include box-sizing(border-box);
						a{display:table-cell; vertical-align:middle;}
					}
				}
			}
		}
		&.open-menu{
			menu{left:0%;}
		}
	}
}

/*/////////////////////////////////////////////////////////////////////////////////////////// LOGIN HOME */
header{
	.box-login{position:absolute; left:0; top:-800px; width:100%; height:auto; padding:80px 0px; background:$bgColor; z-index:4; @include transition($animatDefult);
		.close{position:absolute; top:-60px; right:0px; font-size:36px; cursor:pointer; @include transition($animatDefult);
			&:hover{color:$colorActive;}
		}
		h2{font-size:60px; margin-bottom:20px;}
		p{font-size:18px; margin-bottom:40px;}
		.login{height:480px; border-right:1px solid $colorDefault; padding:0px 60px;
			.btn-send{position:absolute; bottom:0px; right:60px;
				&:hover{color:$colorActive;}
				icon{font-size:30px;}
			}
			.btn-forgot{position:relative; display:table; bottom:0px; width:65%; height:48px; font-size:16px;
				a{display:table-cell; vertical-align:middle;}
			}
		}
		.register{height:480px; border-left:1px solid $colorDefault; padding:0px 60px;
			.btn-send{position:absolute; bottom:0px; right:60px; font-size:40px;
				&:hover{color:$colorActive;}
				icon{font-size:30px;}
			}
			.disclaimer{float:left; color:#989898; font-size:14px;}
		}
		&.show{top:0;}
	}
	@media (max-width:$screen-md-max) {
		.box-login{
			.login{
				.btn-send{margin-right:-20px;}
				.btn-forgot{margin-left:-20px;}
			}
		}
	}
	@media (max-width:$screen-sm-max) {
		.box-login{display:none}
	}
}

.content-box {
	padding: 18px;
	border: 2px solid $colorDefault;
	font-size: 18px;
	line-height: 26px;
	float: left;
	width: 100%;
	margin-top: 20px;
	margin-bottom: 20px;

	button {
		font-size: 18px;
	}

	&.danger {
		border-color: $colorDanger;
		color: $colorDanger;
	}
}

/*/////////////////////////////////////////////////////////////////////////////////////////// FORMS */

.form {
    float: left;
    text-align: left;
}

.form-control {

	&:focus{
		outline: 0;
	}
}

.form-group {
    float: left;
    position: relative;
    width: 100%;
    border: 2px solid $colorDefault;
    margin-bottom: 10px;
    padding: 20px 30px;
    overflow: hidden;
    @include transition($animatDefult);
    text-align: left;

    small {
        font-size: 12px;
        line-height: 12px;
    }

    label {
        display: block;
        width: 100%;
        height: 24px;
        color: $colorDefault;
        font-family: 'AtlasG Medium';
        font-size: 16px;
    }

    input,
    select,
    textarea {
        display: block;
        position: relative;
        width: 100%;
        padding: 0 40px 0 0;
        height: 30px;
        line-height: 30px;
        border: none;
        background-color: transparent!important; // Don't allow native autocomplete to override this
        color: $colorDefault;
        font-family: 'AtlasG Regular';
        font-size: 16px;
        box-shadow: none;
        outline: 0;

        &:-webkit-autofill {
            -webkit-box-shadow: 0 0 0px 1000px $bgColor inset;
        }
    }

    select {
        height: 60px;
        margin: -15px 0px;
        padding: 0px;
        z-index: 2;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        text-indent: 0.01px;
        text-overflow: '';

        &::-ms-expand {
            display: none;
        }
    }

    textarea {
        min-height: 130px;
        resize: none;
    }

    .input-replacement {
        padding: 4px 0 3px;
    }

    &.has-error {
        border: 2px solid $colorDanger;

        .help-block {
            @extend .help-block.error;
            margin-bottom: 0;
        }
    }

    &.focus {
        border: 2px solid $colorActive;
    }

    &.radio,
    &.checkbox {
        width: auto;
        margin-bottom: 0px;
        padding: 3px 0px;
        border: none;

        label {
            display: block;
            margin-right: 20px;
            font-family: 'AtlasG Regular';
        }

        input {
            display: inline-block;
            width: 18px;
            height: 18px;
            margin-right: 10px;
            margin-bottom: 2px;
            vertical-align: text-bottom;
        }
    }

    &.select {

        &:after {
            content: '\f0d7';
            position: absolute;
            top: 27px;
            right: 37px;
            width: 40px;
            height: 60px;
            padding-top: 8px;
            margin: auto;
            background-color: $bgColor;
            font-family: 'FontAwesome';
            font-size: 30px;
            text-align: center;
            z-index: 3;
        }
    }

    &.file {
        position: absolute;
        border: none;

        input {
            opacity: 0.01;
        }

        &:after {
            content: '\f093';
            position: absolute;
            top: 27px;
            right: 37px;
            width: 40px;
            height: 60px;
            padding-top: 8px;
            margin: auto;
            background-color: $bgColor;
            font-family: 'FontAwesome';
            font-size: 30px;
            text-align: center;
            z-index: 3;
        }
    }

    &.empty {
        display: table;
        border: none;
        height: 94px;
        padding: 0;

        .center {
            display: table-cell;
            vertical-align: middle;
        }
    }
}

.small {

    .form-group {

        &.select {

            select {
                padding-right: 40px;
            }

            &:after {
                top: 4px;
                right: 15px;
            }
        }
    }
}

.select-multiple {

    .form-group {
        overflow: visible;

        &.select:after {
            display: none;
        }

        .chosen-choices {
            border: 0;
            padding: 0;
            background-image: none;
            box-shadow: none;
            margin-top: 6px;

            .search-field {

                input {
                    font-family: 'AtlasG Regular'!important;
                    font-size: 16px!important;
                }
            }

            .search-choice {
                border: none;
                box-shadow: none;
                color: #000;
                font-family: 'AtlasG Regular', sans-serif;
                font-size: 16px;
                background: none;

                .search-choice-close {
                    top: 4px;
                    background-position: 0;
                    font: normal normal normal 14px/1 FontAwesome;

                    &:before {
                        content: "\f00d";
                    }

                    &:hover {
                        background-position: 0;
                    }
                }
            }
        }
        .chosen-drop {
            border: 2px solid #000;
            box-shadow: none;

            li {
                height: 30px;
                padding: 5px 10px;
                color: #000;
                font-family: 'AtlasG Regular';
                font-size: 16px;
                @include transition($animatDefult);

                &.result-selected {
                    @include opacity(0.5);
                }

                &.highlighted {
                    background: none;
                    color: $colorActive;
                }

                &.no-results {
                    background: none;
                }
            }
        }
    }
}


.ui-autocomplete {
    background: $bgColor;
    border: 2px solid #000;

    li {
        padding: 10px 10px !important;
        font-family: 'AtlasG Regular';
        font-size: 16px;

        &.ui-state-focus {
            margin: 0;
            border: none;
            background: none;
            font-weight: normal;
            color: $colorActive;
        }
    }
}

.fileupload-group {
	padding: 0;
	border: none;

	label, input, a, .glyphicon {
		display: none;
	}

	.fileupload {
		min-height: 90px;
		margin: 0;
		border: 2px solid $colorDefault;
		color: $colorDefault;
		cursor: pointer;

		ol {
		    margin: 0;
		    text-align: left;
		    padding: 5px 10px;
		    font-size: 16px;
		    word-break: break-word;

		    li {
		        margin: 5px 0 5px 25px;
		    }

		    li:only-child {
		        margin: 5px 0 5px 0;
		        list-style: none;
		    }
		}

		img{
			width: 100%;
		}

		.progress{
			display: none;
            position: relative;
            z-index: 1;

            .progress-bar{
                float: left;
                background-color: $colorActive;
                height:90px;
            }
		}

		&.empty {

			&:after {
				content: '\f093';
				position: absolute;
				top: 20px;
				right: 25px;
				width: 40px;
				height: 60px;
				padding-top: 8px;
				margin: auto;
				font-family: 'FontAwesome';
				font-size: 30px;
				text-align: center;
				z-index: 3;
				color: #000;
			}
		}

		&.dragover {
			border-color: $colorActive;

			&.empty {

				&:after {
					color: $colorActive;
				}
			}
		}

        &.uploading {

            .progress {
                display: block;
            }
        }
	}
}

/*/////////////////////////////////////////////////////////////////////////////////////////// AUTENTICAÇÔES/AREA PESSOAL*/

.auth-box,
.personal-box,
.submit-box,
.dates-box {
    text-align: center;
    margin: 40px 0px;

    h2 {
        font-size: 60px;
        margin-bottom: 20px;
    }

    h3 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    h4 {
        font-family: 'AtlasG Medium';
        font-size: 24px;
        margin-bottom: 20px;
    }

    h5 {
        float: left;
        width: 100%;
        font-family: 'AtlasG Medium';
        font-size: 16px;
        margin-bottom: 10px;
        span {
            font-family: 'AtlasG Regular';
            color: $colorSecondary;
        }
    }

    h6 {
        font-size: 11px;

        span {
            float: left;
            color: $colorSecondary;
            margin: 0px 4px 40px;
        }
    }

    .intro-text {
        font-size: 18px;
        margin-bottom: 40px;

        &.secondary {
            margin-bottom: 10px;
        }
    }

    .btn {
        font-size: 40px;
        margin: 24px 0px;

        &.next {
            float: right;
        }

        &.prev {
            float: left;
        }
    }

    a {
        &.btn {
            line-height: 38px;
        }
    }

    .btn-send {
        position: absolute;
        bottom: 20px;
        right: 0px;
    }

    .areaform {
        display: none;
    }

    .row {
        margin-bottom: 20px;
        text-align: left;
    }

    .conter {
        margin: 20px 0px;
        font-family: 'AtlasG Medium';

        a,
        span {
            margin: 0px 30px;
            font-size: 60px;
            font-weight: bold;
            &.active {
                color: $colorActive;
            }
        }
    }

    .area {
        margin: 20px 0px 40px;

        font-size: 36px;

        &.a-smaller {
            font-size: 30px;
        }
        a {
            margin: 0px 30px;
            &.active {
                color: $colorActive;
                font-weight: bold;
            }
        }
    }

    .hauto {
        height: auto;
    }

    .hint {
        float: left;
        width: 100%;
        margin-top: -10px;
        margin-bottom: 10px;
    }

    .link {
        display: block;
        font-size: 18px;
        margin: -20px 0px 40px;
    }

    .btn-ok {
        display: block;
        border: 0px;
        background: none;
        font-size: 40px;
        cursor: pointer;
        margin: 40px 0px 20px;
    }

    .btn-mail {
        position: absolute;
        right: -140px;
        top: 0;
        bottom: 0;
    }

    .small {

        .form-group {
            padding: 10px 30px;

            .row {
                margin-bottom: 0;
            }
        }

        .nome {
            float: left;
            font-family: 'AtlasG Medium';
            font-size: 16px;
            margin-right: 6px;
        }

        .tipo {
            color: $colorSecondary;
        }

        .apagar,
        .icon,
        .plus {
            float: right;
            font-family: 'AtlasG Regular';
            font-size: 14px;
            cursor: pointer;
            white-space: nowrap;
        }

        .plus {
            font-size: 26px;
            margin: -10px;
        }

        .hover:hover {
            color: $colorActive;
            cursor: pointer;
        }
    }

    .download {
        float: left;
        width: 33%;
        margin-bottom: 20px;

        icon {
            float: left;
            width: 50px;
            margin-top: 6px;
            text-align: center;
            font-size: 36px;
        }

        span {
            display: block;
            font-size: 16px;
        }

        .doc-name {
            font-size: 12px;
        }
    }

    .dados {
        text-align: left;
        margin-bottom: 40px;

        .cell {
            font-family: 'AtlasG Regular';
            color: $colorSecondary;

            .label {
                font-family: 'AtlasG Medium';
                font-size: 16px;
                color: $colorDefault;
                text-align: right;
            }
            .row {

            	div {
                    min-height: 24px;
                }
            }
        }

        @media (max-width:$screen-sm-max) {

            .cell {

                .row {
                    text-align: center;
                }

                .label {
                    margin-top: 10px;
                    text-align: center;
                }
            }
        }
    }

	.btn-forgot {
	    float: left;
	    display: table;
	    width: 65%;
	    height: 48px;
	    margin: 20px 0px;
	    font-size: 16px;
	    text-align: left;

	    a {
	        display: table-cell;
	        vertical-align: middle;
	        font-size: 16px;
	    }
	}

	.team-row,
	.project-row,
    .date-row {
		margin-bottom: 5px;

		.action {
			font-size: 16px;
		}

		icon {
			font-size: 18px;

			&:before {
				margin-left: 4px;
			}
		}

		a,
		button {
			margin-left: 20px;

			&:before {
				content: '|';
				margin-right: 15px;
			}

			&:last-child {
				&:before {
					content: '';
					margin-right: 0;
				}
			}
		}

		& + .add-new {
			margin-top: 25px;
		}
	}

	.project-row {

		.project-name {
			font-family: 'AtlasG Medium';
		}

		button {
			padding: 0;
		}

		&.add-new {

			.form-group {
				padding: 0;

				button {
					width: 100%;
					padding: 10px 30px;
					margin: 0;
			    	text-align: left;
					font-family: 'AtlasG Medium';
					font-size: 16px;
				}
			}
		}
	}

    .accept-invite,
    .remove-from-team {
        text-align: right;
        position: absolute;
        right: 15px;
        padding-right: 30px;

        icon {
            font-size: 20px;
            position: absolute;
            top: 2px;
            right: 4px;
        }
    }

    .remove-from-team {

        icon {
            right: 9px;
        }
    }
}

.member-info {
	color: $colorSecondary;
	font-family: 'AtlasG Regular';

	&:before {
		content: '|';
		margin: 0 10px 0 5px;
	}
}

.member-pre-register {

    .link {
        display: table;
        width: 100%;
        padding: 20px 0px;
        margin: auto;
        border-bottom: 3px solid $colorDefault;
        text-align: left;

        span {
            float: left;
            font-size: 34px;
            line-height: 36px;
        }

        icon {
            float: right;
            width: 100px;
            text-align: center;
            font-size: 44px;
        }

        &:last-child {
            border: none;
        }
    }
}

.document-type-and-number {

    .form-group {

        &.text {
            padding-bottom: 14px;
        }

        &.radio {

            label {
                font-family: 'AtlasG Medium';
                font-size: 16px;
            }
        }
    }
}

.register-success {

	.btn {
		display: inline-block;
		margin: 40px 0 0;
	}
}

.toggable-cell {
	cursor: pointer;

	.form-group.radio {
		display: none;
	}

	icon {
		visibility: hidden;
	}

	&.active {
		color: $colorActive;

		icon {
			visibility: visible;
		}
	}
}

.coordination-method {

	.feedback {
		margin-top: 10px;
	}
}

.row.partners-radios {
    margin-bottom: 8px;

	label {
		display: inline-block;

		input {
			display: inline-block;
			width: auto;
			margin-right: 10px;
			position: relative;
			top: 7px;
		}
	}
}

.contest-submission,
.contest-submission-success {

    .submit-box {
        text-align: left;

        &.submission-header {
            margin: 0px;
            text-align: center;

            .container {
                position: relative;
                z-index: 2;
            }

            p {
                margin-bottom: 0px;
            }
        }
    }
}

.success-notification {

    div {
        text-align: center;
    }

    .btn {
        display: inline-block;
        margin: 40px 0 0;
    }
}

.contest-submission {

	.submission-wrapper {
		margin-bottom: 30px;
	}

    .submission-coordinator {
        width: auto;
        min-width: 150px;
    }

	.submission-member {
		margin-bottom: 0px;
	}

	.submission-team {
		margin-bottom: 20px;

		.list-items {
			margin-bottom: 0;
		}

		.tab {
			&.active {
				.list-items {
					padding-top: 25px;
				}
			}
		}
	}

	.toggable-member {

		.action {
            position: absolute;
            right: 10px;
            top: 4px;
            font-size: 20px;

			.enable {
				display: none;
			}
		}

		&.disabled {

			.form-group {
				border-color: $colorSecondary;

				label, input {
					color: $colorSecondary;
				}
			}

			.member-field {
				display: none;
			}

			.action {
				.disable {
					display: none;
				}
				.enable {
					display: block;
				}
			}
		}
	}

	.member-input-template {
		display: none;
	}
}

.proposal {

    .coordinator {
    	margin-top: 25px;
    }

	.imag {
	    img {
	        font-size: 0;
	        width: 100%;
	    }
	}

	.info {
	    position: relative;
	    padding: 0px 40px 60px;

	    p {
	        float: left;
	        width: 100%;
	        margin: 0px 0px 30px;
	        font-size: 14px;
	        line-height: 24px;
	    }
	}

	.ppl {
	    margin: 25px 0px 25px;

	    span {
	        display: block;
	        font-family: 'AtlasG Medium';
	        font-size: 16px;
	        margin-bottom: 20px;
	    }

	    li {
	        display: block;
	        font-size: 14px;
	        margin: 4px 0px;
	    }
	}

	.download {
	    position: absolute;
	    width: 50%;
	    right: 10px;
	    bottom: 42px;
	    margin-bottom: 0;
	    padding: 0px 40px;

	    icon {
	        float: left;
	        width: 50px;
	        margin-top: 6px;
	        text-align: center;
	        font-size: 36px;
	    }

	    span {
	        display: block;
	        font-size: 16px;
	    }

	    .doc-name {
	        font-size: 12px;
	    }
	}
}

/*/////////////////////////////////////////////////////////////////////////////////////////// TABS */
.tabs {
    float: left;
    border-top: 1px solid $colorDefault;
    border-bottom: 1px solid $colorDefault;
    width: 100%;

    .tab {
        position: relative;
        float: left;
        width: 100%;
        border-top: 1px solid $colorDefault;
        border-bottom: 1px solid $colorDefault;
        padding: 20px 30px;
        overflow: hidden;
        background-color: $bgColor;

        .info-span {
            position: absolute;
            left: 6px;
            top: 22px;
        }

        a {
            display: block;
            position: relative;
            height: auto;
            padding-right: 60px;
            font-family: 'AtlasG Medium';
            font-size: 19px;
            cursor: pointer;
            @include transition($animatDefult);

            &:after {
                position: absolute;
                top: 0px;
                bottom: 0;
                right: 10px;
                margin: auto;
                content: '\f107';
                color: $colorDefault;
                font-family: 'FontAwesome';
                font-size: 48px;
                line-height: 28px;
                @include transition($animatDefult);
            }

            &:hover {
                color: $colorActive;

                &:after {
                    color: $colorActive;
                }
            }
        }

        div {
            max-height: 0px;
            overflow: hidden;
            background-color: $bgColor;
            @include transition($animatDefult);

            p {
                margin: 20px 0px;
                font-size: 16px;
                line-height: 24px;
            }
        }
        &.active {

            div {
                max-height: 5000px;
            }

            a {
                color: $colorActive;

                &:after {
                    content: '\f106';
                    color: $colorActive;
                }
            }
        }
    }
}

/*/////////////////////////////////////////////////////////////////////////////////////////// FOOTER */
footer {
    @include responseSize(height, $heightFooter, $xs-size: 140px);
    @include responseSize(margin-top, -$heightFooter, $xs-size: -140px);
    padding: 60px 20px;
    background: $bgColor;
    z-index: 4;
    font-family:'AtlasT Medium';
    clear: both;

    .container {
        margin: auto !important;
    }

    .links-and-copyright {
        float: left;
        width: auto;
    	margin-top: 8px;
    	font-family: 'AtlasG Regular';

        .copyright {
            display: inline-block;
            margin-right: 30px;
            font-size: 12px;
            font-weight: bold;
            vertical-align: top;
        }

        .links {
	        a {
			    display: inline-block;
			    margin: 0;
			    margin-right: 10px;
			    font-size: 14px;
			    font-weight: normal;

			    &:after {
			    	content: '/';
			    	position: relative;
			    	left: 10px;
			    }

			    &:last-child {

			    	&:after {
			    		content: '';
			    	}
			    }
	        }
        }
    }

    .logo {
        float: right;
        width: auto;
        text-align: right;

        a {
            display: inline-block;
            width: 55px;
            height: 51px;
            background-image: url('../../img/logo.png');
            @extend .backgrd;
        }

        span {
            display: block;
            margin-top: 16px;
            font-size: 14px;
            white-space: nowrap;
        }
    }
}

@media (max-width:$screen-xs-max) {

    footer {
        padding: 16px 10px;

        .copyright {
        	max-width: 380px;
        	width: 75%;
        }

        .links {

            a {
                display: block;
                font-size: 12px;
            }

            span {
                display: block;
                font-size: 14px;
            }
        }

        .logo {
            position: absolute;
            right: 0;
            /*a{width:48px; height:45px;}*/

            span {
                margin-top: 6px;
                font-size: 12px;
            }
        }
    }
}


/*/////////////////////////////////////////////////////////////////////////////////////////// FAQ´s */
#faqs{position:relative; padding:50px 0px;
	h2{position:relative; width:100%; margin-bottom:20px; font-size:36px; padding-right:100px;
		icon{position:absolute; top:0; bottom:0; right:40px; height:22px; margin:auto;}
	}
}

/*/////////////////////////////////////////////////////////////////////////////////////////// HOME */
.home{
	#billhead{position:relative; height:500px; max-width:$maxWidth; margin:auto;
		.cell{display:table; position:relative; width:460px; height:160px; margin:auto;}
		.slider-home{height:100%; overflow:hidden;
            #slider-contests{position:relative; width:100%; height:100%;}
			.slider{
				.slider-cell{background:$bgColor;
					.descri{position:absolute; top:20px; left:10px; width:60%; text-align:left; z-index:2;
						.tipodata{float:left; width:100%; font-family:'AtlasT Regular'; font-size:18px; margin-bottom:6px;}
						.titulo{float:left; width:100%; font-family:'AtlasG Medium'; font-size:32px; line-height:34px;}
					}
				}
			}
			.btn{position:absolute; top:30px; right:5%;  z-index:2;}
		}
		.container{height:100%;
			.links{display:table; height:100%; text-align:center;
				.cell:first-child{border-bottom:3px solid $colorDefault;}
				.center{display:table-cell; vertical-align:middle;}
				h2{margin-bottom:40px; font-family:'AtlasG Black'; font-size:40px; font-weight:bold;}
				h3{margin-bottom:10px; font-family:'AtlasG Black'; font-size:30px; line-height:30px;}
				.box{display:table; padding:0px 30px; height:100%; text-align:left;
					.link{width:100%;}
				}
				.link{display:table; width:480px; padding:20px 0px; margin:auto; border-bottom:3px solid $colorDefault; text-align:left;
					icon{display:table-cell; vertical-align:middle; width:100px; font-size:62px; text-align:center;}
					p{float:left;
						small{display:block; font-size:14px;}
						span{display:block; font-size:34px; line-height:34px;}
					}
					.fa-arrowchevron{font-size:44px; margin-top:10px;}
					&:last-child{border:none;}
				}
			}
		}

		@media (max-width:$screen-sm-max) {
			.slider-home{
				.slider{
					.slider-cell{
						.descri{
							.tipodata{font-size:12px;}
							.titulo{font-size:22px; line-height:24px;}
						}
					}
				}
			}
			.container{
				.links{display:none;
					.center{
						h2{margin-bottom:20px; font-size:28px;}
						.link{width:300px; padding:10px 0px;
							icon{width:60px; font-size:44px; margin-top:4px;}
							p{
								small{font-size:12px;}
								span{font-size:26px; line-height:28px;}
							}
							.fa-chevron-right{font-size:30px; margin-top:10px;}
						}
					}
				}
			}
		}
	}
	#news{position:relative; padding:50px 0px; background:$bgColor;
		.container{overflow:hidden;}
		h2{margin-bottom:20px; font-size:36px;}
		.btn{float:right;}
		@media (max-width:$screen-sm-max) {
			.btn{font-size:20px;}
		}
	}
    #promoter{margin:-40px 0px 20px; display:none;
        .link{display:table; width:300px; padding:20px 0px; margin:auto; text-align:left;
            h3{margin-bottom:10px; font-family:'AtlasG Black'; font-size:30px; line-height:30px;}
            icon{display:table-cell; vertical-align:middle; width:100px; font-size:62px; text-align:center;}
            .fa-arrowchevron{font-size:44px; margin-top:10px;}
        }
    }
    @media (max-width:$screen-sm-max) {
         #promoter{display:block;}
    }
	#map{position:relative; height:400px; background-image:url('../../img/backgrd-map.jpg'); @extend .backgrd;  max-width:$maxWidth; margin:auto;
		.container{display:table; height:100%; text-align:center;
			.center{display:table-cell; vertical-align:middle;
				icon{font-size:130px;}
				h2{font-size:40px; font-family:'AtlasG Medium'; font-weight:bold;}
			}
		}
	}
	#contact{position:relative; padding:50px 0px;
		.container{padding:0px;}
		h2{margin-bottom:20px; font-size:36px;}
		h3{font-family:'AtlasG Medium'; font-size:16px; font-weight:bold;}
		.info{
			p{margin-bottom:80px; font-size:16px;}
		}
		.btn-send{float:right; margin:20px 0px 0px;
			&:hover{color:$colorActive;}
			icon{font-size:30px;}
		}
		@media (max-width:$screen-xs-max) {
			p{margin-bottom:20px;}
		}
	}
}

/*/////////////////////////////////////////////////////////////////////////////////////////// ABOUT */
.about{
	#billhead{position:relative; height:540px;
		.container{display:table; position:relative; height:100%; text-align:center; z-index:2;
			.center{display:table-cell; vertical-align:middle;
				h2{font-size:48px; font-weight: bold; color:#fff;}
			}
		}
	}
	.area{padding:100px 0px;
		h3{width:100%; font-family:'AtlasG Black'; font-size:32px; text-align:center;}
		p{display:block; width:100%; margin:40px auto; font-size:16px;}
	}
	.sub-menu{position:absolute; height:70px; width:100%; z-index:1; background-color:$bgColor;
		ul{display:table; margin:auto; padding-top:18px;
			li{float:left; margin:0px 10px; font-size:16px;
				a:hover{color:$colorActive;}
				a.active{color:$colorActive; font-weight:bold}
			}
		}
		&.fixed{position:fixed; top:90px;}
	}
	.gotop{float:right; margin:20px 0px -20px; font-size:30px; cursor:pointer;}
	@media (max-width:$screen-sm-max) {
		.sub-menu{overflow:auto;
			ul{box-sizing:content-box;
				li{font-size:16px;}
			}
		}
	}
	@media (max-width:$screen-xs-max) {
		#billhead{
			.container{
				.center{
					h3{font-size:40px; line-height:42px;}
					p{
						span{display:block;}
					}
				}
			}
		}
		.area{padding:60px 0px;
			h3{font-size:36px; line-height:40px;}
			p{width:100%;}
		}
		.sub-menu{display:none}
	}
}

/*/////////////////////////////////////////////////////////////////////////////////////////// NOTICIAS */
.contests,
.contests-internal,
.contests-external,
.contests-awarded,
.contests-archive,
.contests-pending-assessment,
.awards,
.news,
.partners,
.publications {

	.container {
	    margin: 50px auto 70px;
	    overflow: hidden;
	}

	h2 {
	    margin-bottom: 20px;
	    font-size: 36px;
	}

	.filtros {
	    text-align: center;
	    padding-top: 24px;
	    white-space: nowrap;

	    span {
	        display: inline-block;
	        color: $colorSecondary;
	    }

	    .filter-option {
	        display: inline-block;
	        margin: 0px 10px;

	        &:hover {
	            color: $colorActive;
	        }

	        &.active {
	            color: $colorActive;
	            font-weight: bold
	        }
	    }
	}

	.pesquisa {
	    text-align: right;
	    padding-top: 24px;

	    input {
	        display: inline-block;
	        width: 100px;
	        border: none;
	        background-color: transparent;
	        color: $colorActive;
	        font-family: 'AtlasG Regular';
	        font-size: 16px;

	        &:-webkit-autofill {
	            -webkit-box-shadow: 0 0 0px 1000px $bgColor inset;
	        }
	    }

	    button {
	        display: inline-block;
	        font-size: 16px;
	    }
	}

	@media (max-width:$screen-sm-max) {

	    h2 {
	        margin-bottom: 0px;
	    }

	    .filtros {
	        float: left;
	        text-align: left;
	        padding-top: 0px;
	        padding: 14px 5px;

	        span {
	            font-size: 14px;
	        }

	        .filter-option {
	            font-size: 14px;
	            margin: 0px 5px;
	        }
	    }

	    .pesquisa {
	        float: right;
	        width: auto;
	        text-align: right;
	        padding: 14px;
	    }
	}
}

.news-detail,
.award-detail {

    .container {
        position: relative;
        margin: 50px auto 70px;
    }

    .tipodata {
        float: left;
        width: 100%;
        font-family: 'AtlasT Regular';
        font-size: 16px;
        margin-bottom: 20px;
    }

    .titulo {
        float: left;
        width: 100%;
        font-family: 'AtlasG Medium';
        font-size: 30px;
        line-height: 32px;
        margin-bottom: 40px;
    }

    p {
        float: left;
        width: 100%;
        margin: 15px 0px;
        font-size: 16px;
        line-height: 18px;
    }

    .nav {
        position: absolute;
        top: 0;
        right: 8px;

        a {
            display: block;
            margin-bottom: 60px;
            font-size: 36px;
        }
    }

    @media (max-width:$screen-md-max) {

        .container {
            padding: 0px 80px;
        }
    }

    @media (max-width:$screen-sm-max) {

        .container {
            padding: 0px 10px 0px 10px;
        }

        .nav {

            a {
                font-size: 30px;
            }
        }
    }

    @media (max-width:$screen-xs-max) {

        .tipodata {
            margin-bottom: 10px;
        }

        .titulo {
            margin-bottom: 20px;
        }
    }
}

.resizeimg {
	height: 100%;
	width: 100%;
	position: absolute;
	overflow: hidden;
}

.thumbnail {
    float: left;
    margin-right: -30px;
    margin-left: -30px;
    font-size: 0;
    min-width: 100%;

    .thumb {
        display: inline-block;
        float: none;
        padding: 0px 30px 30px;
        vertical-align: top;
    }

    .imag {
        float: left;
        position: relative;
        width: 100%;
        height: 250px;
        margin-bottom: 20px;
        @extend .backgrd;
        background-size: cover;

        img {
            position: relative;
            width: 100%;
            margin: auto;
            @include transition($animatDefult);
        }
    }

    .tipodata {
        float: left;
        width: 100%;
        font-family: 'AtlasT Regular';
        font-size: 12px;
    }

    .titulo {
        float: left;
        width: 100%;
        font-family: 'AtlasG Medium';
        font-size: 19px;
    }
}

@media (max-width:$screen-md-max) {
	.thumbnail{
		.imag{height:200px}
	}
}

@media (max-width:$screen-sm-max) {
	.thumbnail{
		.imag{height:220px}
	}
}

@media (max-width:$screen-xs-max) {
	.thumbnail{
		.imag{height:30vw}
	}
}

@media (max-width:$screen-xs-min - 1) {
	.thumbnail{margin-right:0px; margin-left:0px;
		.imag{height:64vw}
		.thumb{width:100%; padding:0px 0px 30px;}
	}
}


/*/////////////////////////////////////////////////////////////////////////////////////////// CONCURSOS */
.contests-detail,
.contest-assessment,
.contest-generate-report,
.contest-report-success {

	#billhead{
		background: $bgColor;
		text-align: center;

		.container {
		    position: relative;
		    display: table;
		    height: 540px;
		    z-index: 2;

		    &.alert {
		        height: auto;
		    }

		    .timer{
		    	color:#fff;
		    	margin-top:20px;
		    	@include transition($animatDefult);

		    	.missing{display:inline-block; width:auto; font-family:'AtlasG Medium'; vertical-align: middle;}
		    	.countdown{display:inline-block; width:auto; margin:0px 8px; vertical-align: middle;
		    		span{float:left; margin:0px 6px;  font-size:30px; font-weight:bold;}
		    		.cell{float:left;
		    			span{display:block; margin:0px; float:none;
		    				&:nth-child(even){font-size:11px;}
		    			}
		    		}
		    	}
		    	.entrega,.limite{display:inline-block; width:160px; vertical-align: middle; text-align:left;
		    		icon{float:left; width:50px; text-align:center; font-size:36px;}
		    		span{display:block; font-size:12px;
		    			&:nth-child(even){font-family:'AtlasG Medium';}
		    		}
		    	}
                .limite{width:210px;}
		    }
		    &:hover {
		    	.timer {
		    		color: $colorActive;
		    	}
		    }
		}

        &.small {

            .container {
                height: 420px;
            }
        }

        &.title-only {

            .container {
                height: 270px;
            }
        }

		.center{display:table-cell; vertical-align:middle; margin-top:-100px;}
		h2{font-size:48px; font-weight:bold;}
		h3{font-size:36px; margin-top:-80px;}
		.allinfo{position:absolute; bottom:0; left:0px; width:100%;}
		.info{height:auto; padding:5px 15px; margin:20px 0px;
			.middle{display:table; margin:auto; text-align:left;}
			icon{display:table-cell; vertical-align:middle; width:50px; text-align:center; font-size:36px;}
			span{display:block; font-size:12px;
				&:nth-child(even){font-family:'AtlasG Medium';}
			}
		}
	}
}

#info .col-mobile {
    float: left;
}

.contests-detail {

    #info {
        background-color: $bgColor;

        .container {
            position: relative;
            margin: 50px auto 70px;
        }

        h2 {
            margin: 20px 0;
            font-size: 30px;
            font-family: 'AtlasG Medium';
        }

        p {
            display: block;
            margin-bottom: 20px;
        }

        .download-wrapper {
            margin-bottom: 25px;
        }

        .download {

            icon {
                float: left;
                width: 50px;
                margin-top: -6px;
                text-align: center;
                font-size: 36px;
            }

            span {
                display: block;
                font-size: 16px;
            }
        }
    }

    #faqs {

        .send-faq {
            float: left;
            margin: 40px 0px;

            h3 {
                font-size: 24px;
                font-weight: bold;
            }

            a {
                padding: 10px 0px;
                font-size: 36px;
                font-family: 'AtlasG Medium';
            }
        }
    }

    #submeter {
        padding: 40px 0px;

        .btn-sub {
            float: right;
            padding: 18px;
            border: 2px solid $colorDefault;
            font-size: 26px;
            font-weight: bold;
            line-height: 26px;
        }

        .btn-login-cta {
            float: right;
            width: 100%;
            padding: 10px 0px;
            text-align: right;
            font-size: 20px;
        }
    }

    .nav {
        position: absolute;
        top: 0px;
        right: 8px;

        a {
            display: block;
            margin-bottom: 60px;
            font-size: 36px;
        }
    }

    @media (max-width:$screen-md-max) {

        #billhead {

            h2 {
                font-size: 38px;
                line-height: 44px;
            }

            h3 {
                font-size: 26px;
            }
        }

        #info {

            .container {
                padding: 0px 80px 50px;
            }
        }
    }

    @media (max-width:$screen-sm-max) {

        #billhead {

            .container {
                height: 400px;
            }

            h3 {
                margin-top: 0px;
            }

            .allinfo {

                .info {
                    margin: 0px 0px;
                }
            }
        }

        #info {

            .container {
                padding: 0px 10px 0px 10px;
            }
        }

        .nav {

            a {
                font-size: 30px;
            }
        }
    }

    @media (max-width:$screen-xs-max) {

        #billhead {

            .allinfo {
                display: none;
            }
        }
    }

    @media (max-width:$screen-xs-min - 1) {

        #billhead {

            img {
                width: auto;
                height: 100%;
            }

            .timer {
                display: block;
                width: 300px;
                margin: auto;
            }

            .entrega {
                margin-top: 10px;
            }
        }
    }
}

.billhead-with-image {
	overflow: hidden;

	.billhead-bg {
		width: 100%;
		position: absolute;
		z-index: 1;
		top:-100%;
		bottom:-100%;
		left:-100%;
		right:-100%;
		margin:auto;
	}

	.billhead-heading {
	    position: relative;
	    z-index: 2;
	}


	&.submit-box {

		.billhead-heading {
		    padding-top: 30px;
		}
	}

	.allinfo {
		z-index: 2;
	}
}

/*/////////////////////////////////////////////////////////////////////////////////////////// PROMOTORES */
.promoter{
	#billhead{position:relative; height:540px;
		.container{position:relative; display:table; height:100%; text-align:center; z-index:2;
			.center{display:table-cell; vertical-align:middle;
				h2{font-size:48px; font-weight: bold; color:#fff;}
			}
		}
	}
	#area{padding:40px 0px;
		.container{height:0; @include opacity(0); @include transition($animatDefult);
			&.active{height:auto; @include opacity(1);}
		}
		h3{display:none}
		p{display:block; width:100%; margin:40px auto; font-size:16px;}
	}
	.sub-menu-tabs{position:absolute; height:70px; width:100%; z-index:1;
		ul{display:table; margin:auto; padding-top:18px;
			li{float:left; margin:0px 10px; font-size:16px;
				a:hover{color:$colorActive;}
				a.active{color:$colorActive; font-weight:bold}
			}
		}
	}
	#titulo{padding:0px 0px 20px;
		h3{width:100%; font-family:'AtlasG Black'; font-size:32px; text-align:center;}
	}
	#download{padding:20px 0px;
		a{display:table; margin:auto;
			icon{float:left; width:50px; text-align:center; font-size:36px;}
			span{float:left; font-size:26px; font-weight:bold;}
		}
	}
	#submeter{padding:40px 0px;
		.btn-sub{float:right; padding:18px; border:2px solid $colorDefault; font-size:26px; font-weight:bold; line-height:26px;}
	}
	@media (max-width:$screen-sm-max) {
		.sub-menu-tabs{overflow:auto;
			ul{box-sizing:content-box;
				li{font-size:16px;}
			}
		}
	}
	@media (max-width:$screen-xs-max) {
		#billhead{height:400px;
			.container{
				.center{
					h2{font-size:38px; line-height:44px;}
					h3{font-size:40px; line-height:42px;}
					p{
						span{display:block;}
					}
				}
			}
			img{width:auto; height:100%;}
		}
		.sub-menu-tabs{display:none}
		#area{padding:60px 10px;
			.container{height:auto; @include opacity(1);}
			h3{display:block; width:100%; color:$colorActive;; font-family:'AtlasG Black'; font-size:36px; line-height:40px; text-align:center;}
			p{width:100%;}
		}
	}
	@media (max-width:$screen-xs-min - 1) {
		#download{padding:20px 0px;
			a{
				icon{width:40px; font-size:26px;}
				span{font-size:16px;}
			}
		}
	}
}

.case-study{

    #billhead{
        background: $bgColor;
        text-align: center;

        .container {
            position: relative;
            display: table;
            height: 540px;
            z-index: 2;

            &.alert {
                height: auto;
            }

            .timer{
                color:#fff;
                margin-top:20px;
                @include transition($animatDefult);

                .missing{display:inline-block; width:auto; font-family:'AtlasG Medium'; vertical-align: middle;}
                .countdown{display:inline-block; width:auto; margin:0px 8px; vertical-align: middle;
                    span{float:left; margin:0px 6px;  font-size:30px; font-weight:bold;}
                    .cell{float:left;
                        span{display:block; margin:0px; float:none;
                            &:nth-child(even){font-size:11px;}
                        }
                    }
                }
                .entrega{display:inline-block; width:160px; vertical-align: middle; text-align:left;
                    icon{float:left; width:50px; text-align:center; font-size:36px;}
                    span{display:block; font-size:12px;
                        &:nth-child(even){font-family:'AtlasG Medium';}
                    }
                }
            }
            &:hover {
                .timer {
                    color: $colorActive;
                }
            }
        }

        .center{display:table-cell; vertical-align:middle; margin-top:-100px;}
        h2{font-size:48px; font-weight:bold;}
        h3{font-size:36px; margin-top:-80px;}
        .allinfo{position:absolute; bottom:0; left:0px; width:100%;}
        .info{height:auto; padding:5px 15px; margin:20px 0px;
            .middle{display:table; margin:auto; text-align:left;}
            icon{display:table-cell; vertical-align:middle; width:50px; text-align:center; font-size:36px;}
            span{display:block; font-size:12px;
                &:nth-child(even){font-family:'AtlasG Medium';}
            }
        }
    }

    #info{background-color:$bgColor;
        .container{position:relative; margin:50px auto 70px;}
        h2{margin-bottom:20px; font-size:16px; font-family:'AtlasG Medium';}
        p{display:block; margin-bottom:20px;}
        .download{float:left; width:33%; margin-bottom:20px;
            icon{float:left; width:50px; margin-top:-6px; text-align:center; font-size:36px;}
            span{display:block; font-size:16px;}
        }
    }
    #submeter{padding:40px 0px;
        .btn-sub{float:right; padding:18px; border:2px solid $colorDefault; font-size:26px; font-weight:bold; line-height:26px;}
    }
	@media (max-width:$screen-sm-max) {
		#billhead{
			.container{height:400px;}
			h2{font-size:38px; line-height:44px;}
			h3{font-size:26px; margin-top:0px;}
			.allinfo{
				.info{margin:0px 0px;}
			}
		}
		#info{
			.container{padding: 0px 10px 0px 10px;}
		}
		.nav{
			a{font-size:30px;}
		}
	}
	@media (max-width:$screen-xs-max) {
		#billhead{
			.allinfo{display:none;}
		}
	}
	@media (max-width:$screen-xs-min - 1) {
		#billhead{
			img{width:auto; height:100%;}
		}
	}
}

.contest-type-description-wrapper {

    h6 {
        margin-top: 15px;
    }

    #contest-type-description {
        float: none;
        color: $colorDefault;
        margin-left: 70px;
        margin-bottom: 0;
        display: block;
    }
}

/*------- simulations -------*/

.disclaimer {
    margin: 100px auto;
    width: 1040px;
    .terms {
        text-align: center;
        margin-bottom: 30px;
    }
    .message {
        margin-bottom: 50px;
    }
    .cell {
        text-align: center;
        .form-group {
            float: none;
            text-align: center;
            margin-bottom: 50px;
        }
    }
}

.info-btn {
    font-size: 18px;
    padding: 0;
    .fa-info-circle {
        padding-left: 3px;
    }
}

.budget-staff-employee-edit,
.budget-info-edit,
.budget-params-edit,
.budget-structure-edit,
.simulation-step-1,
.simulation-step-2 {
    .popover {
        min-height: 200px;
        padding: 20px;
        transition: opacity 250ms ease-in-out;
        .close {
            right: 20px;
        }
    }
    .right {
        margin-left: 20px;
        margin-top: -7px;
    }
    .left {
        margin-left: -15px;
        margin-top: -7px;
    }
    .form-group {
        overflow: initial;
        label {
            display: inline;
        }
        select {
            height: 30px;
            margin: 0;
        }
    }
    .total {
        text-align: right;
    }
}

.simulation-step-3,
.simulation-step-4 {
    .popover {
        min-height: 200px;
        padding: 20px;
        transition: opacity 250ms ease-in-out;
        .close {
            right: 20px;
        }
    }
    .right {
        margin-left: 20px;
        margin-top: -7px;
    }
    .left {
        margin-left: -15px;
        margin-top: -7px;
    }
}

.simulation-step-3 {
    .info-btn {
        margin-top: 12px;
    }
}


/*------- splash-screen -------*/

.hint {
    text-align: center;
}

/*------- end splash-screen -------*/

#phases {
    .tabs .tab div .headers {
        overflow: visible;
    }
    .section-title {
        font-size: 30px;
        margin-bottom: 30px;
    }
    .phase {
        text-align: left;
        .phase-total {
            float: right;
        }
    }
    .wrapper {
        a:after {
            content: '';
        }
        .subphase{
            margin-top: 20px;
            .subphase-title {
                height: 65px;
                padding-top: 18px;
                margin-bottom: 30px;
                .duration {
                    padding: 10px;
                    border: 2px solid #000;
                    input {
                        border: none;
                        width: 40px;
                        font-size: 16px;
                        text-align: center;
                    }
                }
                .info {
                    margin-left: 5px;
                    cursor: pointer;
                }
            }

            .headers {
                font-weight: 700;
                padding: 5px;
                overflow: visible;
            }
            .add-resource {
                margin-top: 30px;
                color: #000000;
            }
            .resource-select {
                width: 50%;
            }
            .not-editable {
                opacity: 0.3;
                height: 45px;
                .field {
                    height: 100%;
                    border: 2px solid #000;
                    padding: 10px 28px;
                }
                .no-border {
                    border: none;
                    padding: 10px 0;
                    text-align: center;
                }
            }
            .resource {
                position: relative;
                .select {
                    height: 45px;
                    select {
                        height: initial;
                        display: initial;
                    }
                }
                .form-group.select:after {
                    top: 7px;
                    padding-top: 0;
                }
                .input {
                    height: 45px;
                    padding: 0;
                    input {
                        padding: 0;
                        height: 100%;
                        margin-left: 20px;
                        width: 20px;
                        display: inline-block;
                    }
                }
                .result {
                    width: 100%;
                    padding-top: 10px;
                    text-align: center;
                    .value {
                        font-family: 'AtlasG Medium';
                        font-size: 16px;
                    }
                }
                .remove-resource {
                    font-size: 26px;
                    span {
                        cursor: pointer;
                        float: right;
                        padding-right: 25px;
                    }
                }
            }
        }

    }
    .phases-total {
        font-size: 19px;
        font-weight: 700;
        float: right;
        padding: 10px 50px;
    }
}

/*------- simulation preview screen -------*/
.project-info {
    padding-bottom: 20px;
    margin-bottom: 20px;
    .row {
        margin: 0;
    }
    .section-title {
        text-align: center;
        margin-bottom: 20px;
        font-weight: 900;
        font-size: 20px;
    }
    .field-name {
        font-weight: bold;
        float: right;
    }
    .field-info {
        color: #7f7f7f;
        float: left;
    }
    .field {
    }
}
.project-phases {
    .row {
        margin: 0;
    }
    .section-title {
        text-align: center;
        font-weight: 900;
        font-size: 20px;
    }
    .cell {
        padding: 0;
    }
    .phase {
        font-weight: 900;
        padding-top: 20px;
        .phase-fields {
            margin-top: 20px;
            .phase-field {
            }
        }
    }
    .subphase {
        margin-top: 20px;
        .subphase-name {
            margin-bottom: 10px;
        }
    }

    .resource-fields {
    }
    .resource-field {
        padding: 5px 0;
    }
    .resource {
        margin-top: 10px;
        border: 2px solid #000;
    }

    .total-row {
        font-size: 24px;
        font-weight: 700;
        margin-top: 30px;
        .total-label {
            text-align: right;
        }
    }

    .total {
        text-align: center;
    }
}
.complementary-values, .payment-schedule {
    .row {
        margin: 0;
    }
    .section-title {
        text-align: center;
        font-weight: 900;
        font-size: 20px;
        margin-top: 20px;
    }

    .subsection-title {
        margin: 20px 0;
    }
    .subsection-total {
        text-align: center;
    }
    .service {
        margin-top: 10px;
        border: 2px solid #000;
        .service-field {
            padding: 5px 0;
        }
        .description {
            padding: 15px;
        }

    }

    .total-row {
        .total-label {
            text-align: right;
        }
        font-size: 24px;
        font-weight: 700;
        margin-top: 30px;
    }

    .total {
        text-align: center;
    }
    .payment-description {
        border: 2px solid #000;
        margin: 30px 0;
        padding: 20px;
    }
}




.project-phases-descriptions {
    font-size: 14px;
    .left {
        text-align: left;
    }

    .two-columns {
        width: 25%;
    }

    .six-columns {
        width: 75%;
    }

    .right {
        text-align: right;
    }

    .top {
        vertical-align: top;
    }

    .sub {
        margin-left: 15px;
    }

    .sub-sub {
        margin-left: 30px;
    }

    .subphase-title {
        padding-left: 20px;
    }

    .resource-field-label {
        font-size: 16px;
        font-weight: bolder;
        padding: 20px 0;
        font-weight: bold;
    }
    p {
        margin-bottom: 10px;
    }

    .section-title {
        text-align: center;
        font-weight: 900;
        font-size: 20px;
    }
}

.back-button {
    margin-top: 20px;
    width: 100%;
    text-align: center;
    .back-link {
        font-size: 40px;
        margin: 0 20px;
    }
}

/*-------- project-diagram -------*/
.diagram-wrapper {
    margin: 50px 0;
    min-width: 940px;
    .section-title {
        text-align: center;
        margin-bottom: 20px;
        font-weight: 900;
        font-size: 20px;
    }
    #diagram {
        position: relative;
        .row.phase {
            .row.phase-content {
                margin-bottom: 2px;
                .phase-name {

                }
                .phase-duration-gr {
                    height: 20px;
                    padding: 0;
                    .before {
                        border-top: 2px solid #000;
                        float: left;
                        height: 10px;
                        margin-top: 10px;
                    }
                    .duration {
                        position: relative;
                        float: left;
                        //background-color: #7300fa;
                        //border-right: 2px solid #000;
                        //border-left: 2px solid #000;
                        border: 2px solid #000;
                        height: 100%;
                        .line {
                            position: absolute;
                            width: 100%;
                            height: calc(50% + 2px);
                            border-bottom: 2px solid #000;
                        }
                    }
                }
            }
            .subphase-content {
                .subphase-name {
                    padding-left: 20px;
                }
                .subphase-duration-gr {
                    height: 20px;
                    padding: 0;
                    .before {
                        float: left;
                        height: 10px;
                    }
                    .duration {
                        float: left;
                        background-color: #000;
                        height: 100%;
                    }
                }
            }
        }
    }
    .scale {
        margin-bottom: 0;
        .week-scale {
            position: initial;
            border-bottom: 2px solid black;
            //border-left: 1px solid black;
            .week {
                display: inline-block;
                vertical-align: bottom;
                margin-bottom: -2px;
//                border-left: 1px solid black;
                height: 20px;
                &:before {
                    content: '';
                    position: absolute;
                    height: calc(100% - 20px);
                    width: 2px;
                    bottom: 20px;
                    background-color: #7300fa;
                    visibility: hidden;
                }
                &:after {
                    content: '';
                    width: 2px;
                    height: 7px;
                    background-color: #000;
                    display: inline-block;
                    margin-bottom: -1px;
                }
                &:hover {
                   &:before {
                        visibility: visible;
                   }
                }
            }
            .month {
                &:after {
                    height: 10px;

                }
            }
        }
        .col {
            position: initial;
        }
    }
    .scale-legend {
        .legend-name {
            text-align: right;
            font-size: 10px;
            padding-right: 15px;
        }
        .week-scale-legend {
            font-size: 8px;
            margin-top: 3px;
            margin-left: -3px;
            white-space: nowrap;
            .week-legend {
                display: inline-block;
                //border: 1px solid red;
            }
        }
    }
}

/*------- raw row -------*/

.raw {
    margin: 0;
    padding: 0;
}
.row .raw .scale {
    padding: 0;
}

.submit-box .raw {
    margin: 0;
    padding: 0;
}

.submit-box .raw .week-scale {
    margin-top: 3px;
}

.submit-box .raw .legend-name {
    text-align: right;
    font-size: 10px;
    padding-right: 15px;
}

.personal-box .row, .submit-box .row {
    margin-bottom: 2px;
}

/*------- dashboard -------*/

.budget-simulations,
.budget-params-edit,
.budget-info-edit,
.budget-structure-edit,
.budget-staff-edit,
.budget-staff-employee-edit,
.budget-simulations-simulation-success {
    .personal-box {
        .container {
            width: 1140px;
            height: 300px;
            overflow: hidden;
            h2 {
                position: relative;
                z-index: 2;
                margin-top: 100px;
                color: #fff;
            }
        }
        .selected-profile {
            position: relative;
            font-weight: 900;
            padding: 10px;
            margin-bottom: 30px;
            .avatar {
                position: relative;
                z-index: 2;
                width: 120px;
                height: 120px;
                margin: 10px auto 15px auto;
                border-radius: 60px;
                background-size: cover;
                background-position: 50% 50%;
            }
            .button {
                font-size: 22px;
            }
        }
    }
}
.budget-simulations {
    .personal-box {
        .container {
            width: 1140px;
            height: 300px;
            overflow: hidden;
            h2 {
                position: relative;
                z-index: 2;
                margin-top: 100px;
                color: #fff;
            }
        }
    }
    #dashboard {
        .terms {
            margin-top: 50px;
            cursor: pointer;
        }

        .selected-profile {
            position: relative;
            font-weight: 900;
            padding: 10px;
            margin-bottom: 30px;
            .avatar {
                position: relative;
                z-index: 2;
                width: 120px;
                height: 120px;
                margin: 10px auto 15px auto;
                border-radius: 60px;
                background-size: cover;
                background-position: 50% 50%;
            }
            .button {
                font-size: 22px;
                cursor: pointer;
                &:hover {
                    &:after {
                        border-color: $colorActive;
                    }
                }
            }
            .button:after {
                content: '';
                position: absolute;
                width: 10px;
                height: 10px;
                margin: 7px 16px;
                border-bottom: 2px solid #000;
                border-left: 2px solid #000;
                transform: rotate(-45deg);
            }
            .active {
                color: $colorActive;
            }
            .active:after {
                border-color: $colorActive;
                margin: 12px 16px;
                transform: rotate(-225deg);
            }
            .hint {
                margin-top: 0;
            }
        }
        .profile-grid {
            margin: 0 auto 20px auto;
            width: 400px;
            border: 2px solid #000;
            .profile {
                display: inline-block;
                margin: 15px;
                cursor: pointer;
            }
            .profile:hover {
                color: $colorActive;
            }
        }

        .simulations {
            .add-new {
                margin: 0 0 30px 0;
            }
            .simulation-header {
                margin-bottom: 15px;
            }
            .year {
                text-align: center;
                font-weight: bold;
                margin-bottom: 20px;
                cursor: pointer;
            }
            .year:hover {
                color: $colorActive;
            }
        }
        .sections {
            margin-top: 50px;
            display: inline-block;
            .section {
                box-sizing: content-box;
                border: 2px solid #000;
                width: 266px;
                height: 266px;
                float: left;
                margin-right: 20px;
                margin-top: 20px;
                .icon {
                    margin-top: 30px;
                    margin-bottom: 30px;
                    font-size: 50px;
                }
                .title {
                    display: block;
                    font-size: 20px;
                    font-weight: bold;
                    margin-bottom: 15px;
                }
                .impediment {
                    .icon {
                        font-size: 16px;
                    }
                }
            }
            .staff {
                margin-right: 0;
            }
        }

        .expand-transition {
            transition: all .3s ease;
            .profile {
                opacity: 1;
            }
        }

        .expand-enter, .expand-leave {
            height: 0;
            opacity: 0;
            margin-bottom: 0;
            .profile {
                opacity: 0;
            }
        }
    }
}

/*------- block screen -------*/
.error-message {
    text-align: center;
    padding: 10px;
    border: 2px solid #000;
    width: 1040px;
    margin: 30px auto;
}

.no-hover:hover {
    color: #000;
}

/*------- other services -------*/

@import "budget-services";

/*------- vue css part -------*/

@import "vue-modal";

[v-cloak] {
  display: none;
}

.assessment-popover {
    min-height: 200px;
    padding: 20px;
    margin-left: 25px;
    margin-top: -7px;
    transition: opacity 250ms ease-in-out;
    .close {
        right: 20px;
    }
}




