/*/////////////////////////////////////////////////////////////////////////////////////////// FONTS */
@font-face {
    font-family:'AtlasG Black';
    src:url('../../fonts/atlasgrotblack.eot');
    src:url('../../fonts/atlasgrotblack.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/atlasgrotblack.woff') format('woff'),
        url('../../fonts/atlasgrotblack.ttf') format('truetype'),
        url('../../fonts/atlasgrotblack.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family:'AtlasG Medium';
    src:url('../../fonts/atlasgrotmedium.eot');
    src:url('../../fonts/atlasgrotmedium.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/atlasgrotmedium.woff') format('woff'),
        url('../../fonts/atlasgrotmedium.ttf') format('truetype'),
        url('../../fonts/atlasgrotmedium.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family:'AtlasG Regular';
    src:url('../../fonts/atlasgrotregular.eot');
    src:url('../../fonts/atlasgrotregular.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/atlasgrotregular.woff') format('woff'),
        url('../../fonts/atlasgrotregular.ttf') format('truetype'),
        url('../../fonts/atlasgrotregular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family:'AtlasT Medium';
    src:url('../../fonts/atlastypemedium.eot');
    src:url('../../fonts/atlastypemedium.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/atlastypemedium.woff') format('woff'),
        url('../../fonts/atlastypemedium.ttf') format('truetype'),
        url('../../fonts/atlastypemedium.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family:'AtlasT Regular';
    src:url('../../fonts/atlastyperegular.eot');
    src:url('../../fonts/atlastyperegular.eot?#iefix') format('embedded-opentype'),
        url('../../fonts/atlastyperegular.woff') format('woff'),
        url('../../fonts/atlastyperegular.ttf') format('truetype'),
        url('../../fonts/atlastyperegular.svg') format('svg');
    font-weight: normal;
    font-style: normal;
}