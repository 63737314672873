.contest-title {
    margin: 40px 0 0;
}

.phase-title {
    margin: 20px 0 30px;

    h3 {
        font-size: 30px;
    }
}

.category-editing {
    margin: 63px 0 0;
    text-align: right;
}

.toggle-submission-comments {

    .toggle-comments-show {
        display: inline-block;
    }

    .toggle-comments-hide {
        display: none;
    }

    &.showing {

        .toggle-comments-show {
            display: none;
        }

        .toggle-comments-hide {
            display: inline-block;
        }
    }
}

.caption{margin-bottom:20px;
    .titulo{font-size:30px;font-family:'AtlasG Black';}
    .fase{font-size:14px;}
}

.div-tabela{display:block; width:100%; overflow:auto; margin:20px 0;
    table{width:100%; margin-bottom:15px;
        tr{
            position:relative;

            &:first-child {
                td {
                    padding-top: 15px;
                }
            }
        }
        th{min-width:80px; height:60px; font-size:14px; padding: 20px 10px 0 0; border-bottom:2px solid #cdcdcd;
            &:last-child{min-width:80px;}
            &.first-row{width:320px; min-width:320px; max-width:320px;}
            .titulo{display:block; font-size:26px; text-align:left; font-family:'AtlasG Black';}
            small{display:block; font-size:12px; font-weight:normal; margin: 2px 0 6px;}
        }
        td{min-width:80px; padding-right:10px;
            &:last-child{min-width:80px;}
            &.first-row{width:320px; min-width:320px; max-width:320px;  padding:2px 10px;}
            .titulo{display:inline-block; width:200px; vertical-align:middle; font-size:16px; font-family:'AtlasG Black';}
            .important {
                font-weight:bold;
                color:#000;
            }
            .faded {
                color: #ccc;
            }
            &.center {
                text-align: center;
            }
        }
        .criterion{position:relative; cursor:pointer;}
        .popover {top:inherit !important; margin-top:-80px;
            &.right{left:inherit !important; margin-left:80px;}
            h5{color:#000; font-family:'AtlasG Black'; font-size:28px; margin-bottom:20px;}
            h6{color:#000; font-family:'AtlasG Black'; font-size:16px; margin-bottom:10px;}
            &.right:after, &.right:before, &.left:after, &.left:before{top:40px;}
        }
        .info-box{display:none;}
        .imag{display:inline-block; width:55px; height:55px; margin-right:10px; border-radius:3px; background-color:#d7d7d7; background-size:cover; vertical-align:middle;}
        .valor{font-size:14px; color:#7e7e7e;}
        input{
            &.valor{color:#000; border:none;width:100%;padding:5px 0;}
            &.error{color:red;}
        }
    }
}

.div-agenda{margin-bottom:60px;
    .data{position:relative; margin:5px 0px; font-size:16px;  text-align:center;
        .item{width:100%; padding:10px; border:2px solid #000; cursor:pointer; @include transition($animatDefult);
            icon{width:20px; margin:0px 5px; font-size:20px;
                &:before{content: "\f00d";}
            }
            &.active{
                icon{
                    &:before{content: "\f00c";}
                }
            }
            &:hover{color:$colorActive;};
        }
    }
}

.div-equipa{margin-bottom:60px;
    .membro{position:relative; float:left; margin:10px 25px 10px 5px;
        .type{color:#7f7f7f; font-size:12px;}
        .name{color:#000000; font-size:18px; font-weight:bold;}
        .phone{color:#7f7f7f; font-size:14px;}
        .mail{color:#7f7f7f; font-size:14px;}
        span{display:block; word-wrap:inherit;}
    }
}

.div-obs {
    margin-bottom: 25px;
    overflow: hidden;
}

.div-action{padding:40px 0px;
    .btn-action{display:table; padding:10px 0px;; font-size:40px; line-height:26px; cursor:pointer;}
}

.div-avaliar {
    float:right;
}

.div-cancel {
    float: left;
}

.div-projecto{
    .imag{
        img{font-size:0; width:100%;}
    }
    .info{position:relative; padding:0px 40px 60px;
        p{float:left; width:100%; margin:0px 0px 30px; font-size:14px; line-height:24px;}
    }
    .download{position:absolute; width:50%; right:10px; bottom:42px; margin-bottom:0; padding:0px 40px;
        icon{float:left; width:50px; margin-top:6px; text-align:center; font-size:36px;}
        span{ display:block; font-size:16px;}
        .doc-name{font-size:12px;}
    }

    &.simple {
        .download{
            left: 0;
            padding: 0;
        }
    }
}

.submission-detail {
    margin-bottom: 40px;
}

.distinctions {
    margin-bottom: 15px;
}

.disqualified-td {
    padding-bottom: 15px;
}

.contest-finish-report {

    .finish-report-title {
        text-align: center;

        h2 {
            font-size: 56px;
            margin-bottom: 0;
        }

        p {
            font-size:30px;
            margin-bottom: 40px;
        }
    }

    h3 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    .form-group {
        margin-bottom: 30px;
    }

    .download-report {
        margin-bottom: 35px;
    }

    .fileupload-group {
        margin-bottom: 10px;
    }

    .report-alert {
        margin-bottom: 15px;
    }
}

.toggable-comments {
    display: none;
    border-bottom: 1px solid #cdcdcd;

    td {
        padding: 10px 0;
    }
}

.with-comments {
    .toggable-comments {
        display: table-row;
    }
}

.juri-override {

    td {
        padding-top: 10px;
    }
}

.meeting-dates-wrapper {

    .new-date-wrapper {

        .plus {
            position: absolute;
            right: 30px;
            top: 26px;
        }
    }

    .fixed-date {
        margin: 15px 0 40px;

        .meeting-date-text {
            font-size: 24px;
        }

        icon {
            font-size: 36px;
        }
    }

    .reset-date {
        display: block;
        margin: 10px 0 0;
    }

    .meeting-time {
        display: none;
    }

    .scheduling-cta,
    .dates-box {
        display: block;
    }

    &.scheduled {

        .meeting-time {
            display: block;
        }

        .scheduling-cta,
        .dates-box {
            display: none;
        }
    }
}
