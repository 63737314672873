.contests-map {

    #map {
        position: relative;
        height: 640px;
        background: #c6c6c6;
        padding: 0px;
        .area-info,
        .area-map {
            height: 100%;
            padding: 0px;
        }

        .area-info {
            padding: 80px 40px;

            h2 {
                color: #666666;
                font-size: 20px;
                font-family: 'AtlasG Black';
            }

            h4 {
                color: #666666;
                font-size: 13px;
                font-weight: bold;
                margin-bottom: 20px;
            }

            .btn {
                position: relative;
                padding: 6px 0px 6px 44px;
                margin-bottom: 15px;
                color: $bgColor;
                font-size: 16px;
                cursor: pointer;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 0;
                    width: 36px;
                    height: 36px;
                    margin: auto;
                    border-radius: 100%;
                    z-index: 1;
                }

                &:after {
                    content: '';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: 12px;
                    width: 12px;
                    height: 12px;
                    margin: auto;
                    border-radius: 100%;
                    z-index: 1;
                    background-color: #c6c6c6;
                    display: none;
                }

                &.all {

                    &:before {
                        background-color: $colorDefault
                    }

                    &.active {
                        color: $colorDefault;

                        &:after {
                            display: block;
                        }
                    }
                }

                &.open {

                    &:before {
                        background-color: #7300fa
                    }

                    &.active {
                        color: #7300fa;

                        &:after {
                            display: block;
                        }
                    }
                }

                &.awarded {

                    &:before {
                        background-color: #550085
                    }

                    &.active {
                        color: #550085;

                        &:after {
                            display: block;
                        }
                    }
                }

                &.other {

                    &:before {
                        background-color: #39085a
                    }

                    &.active {
                        color: #39085a;

                        &:after {
                            display: block;
                        }
                    }
                }
            }
        }

        .area-infowindow {
            .ballon-close {
                width: 16px !important;
                height: 16px !important;
                opacity: 1 !important;

                img {
                    display: none;
                }

                &:before {
                    content: '\f00d';
                    color: $colorDefault;
                    font-family: 'FontAwesome';
                    font-size: 18px;
                    text-align: center;
                }
            }

            .ballon-conten-info {
                width: 360px;

                h4 {
                    float: left;
                    width: 100%;
                    font-family: 'AtlasT Regular';
                    font-size: 12px;
                }

                h2 {
                    float: left;
                    width: 100%;
                    font-family: 'AtlasG Medium';
                    font-size: 18px;
                    line-height: 20px;
                }

                p {
                    float: left;
                    width: 100%;
                    font-family: 'AtlasT Regular';
                    font-size: 12px;
                    padding: 5px 0px;
                    span {
                        font-family: 'AtlasG Regular';
                        font-weight: bold;
                    }
                }

                ul {
                    float: left;
                    width: 100%;
                    margin: 10px 0px;

                    li {
                        float: left;
                        width: 100%;
                        padding: 2px 0px;
                        font-size: 13px;
                        font-family: 'AtlasG Regular';

                        span {
                            float: left;
                            width: 80px;
                            font-weight: bold;
                        }
                    }
                }

                a {
                    float: right;
                    font-family: 'AtlasG Regular';
                    font-size: 16px;
                }
            }

            &.open {

                .ballon-close:hover {

                    &:before {
                        color: #7300fa;
                    }
                }

                .ballon-conten-info {

                    h2,
                    h4,
                    p,
                    a:hover {
                        color: #7300fa;
                    }
                }
            }

            &.awarded {

                .ballon-close:hover {

                    &:before {
                        color: #550085;
                    }
                }

                .ballon-conten-info {

                    h2,
                    h4,
                    p,
                    a:hover {
                        color: #550085;
                    }
                }
            }

            &.other {

                .ballon-close:hover {

                    &:before {
                        color: #39085a;
                    }
                }

                .ballon-conten-info {

                    h2,
                    h4,
                    p,
                    a:hover {
                        color: #39085a;
                    }
                }
            }
        }
    }

    #mapoasrs {
        float: left;
        width: 100%;
        height: 100%;
    }

    @media (max-width:$screen-md-max) {

        #map {

            .area-info {
                padding: 80px 20px;
            }
        }
    }

    @media (max-width:$screen-xs-max) {

        #map {
            height: auto;

            .area-info {
                padding: 20px 10px;
            }

            .area-map {
                height: 380px;
            }
        }
    }
}
