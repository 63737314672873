@import "variables";

.notitication-item {
    position: relative;
    float: left;
    width: 100%;
    height: auto;
    padding: 20px 40px 20px 40px;
    border-bottom: 2px solid $colorFade;
    @include box-sizing(border-box);
    text-align: left;

    .name {
        display: block;
        font-family: 'AtlasG Medium';
        font-size: 16px;
    }

    .msg {
        display: block;
        font-size: 12px;
    }

    .type {
        display: block;
        font-family: 'AtlasG Medium';
        font-size: 12px;
    }

    &.read {

        a,
        p,
        span {
            color: $colorSecondary;
        }
    }
}

header {

    .box-alerta {

        section {
            height: 100%;
        }

        h2 {
            width: 100%;
            padding-top: 26px;
            font-size: 40px;
            text-align: center;
        }

        .notification {
            position: absolute;
            bottom: 0px;
            width: 100%;
            @include calc(height, '100% - 110px');
            overflow: auto;

            li {
                @extend .notitication-item;
            }
        }
    }

    &.fixed {

        .box-alerta {

            h2 {
                padding-top: 14px;
            }
        }
    }
}

.toggle-notification-status {
    width: 10px;
    height: 10px;
    background-color: $colorDefault;
    border: 2px solid #000;
    border-radius: 10px;
    display: block;
    position: absolute;
    top: 28px;
    left: 15px;
    cursor: pointer;

    &:hover {
        background-color: $colorActive;
        border-color: $colorActive;
    }
}

.read {

    .toggle-notification-status {
        background-color: transparent;
        border-color: $colorSecondary;
    }
}

.notification-list {
    margin-bottom: 30px;

    li {
        @extend .notitication-item;
    }
}
